import { useQuery } from '@tanstack/react-query';

import { VehiclesDataLanes } from '../../../../../components/InfoPanel/SystemsInfo/PopupDetector/PopupDetector.model';
import { normilizeData } from '../../../../../components/TrafficLightDetailed/TlDetectors/helpers/normilizeData';
import {
  DEFAULT_STALE_TIME,
  DEFAULT_UPDATE_TIME,
  QUERY_KEY,
} from '../../../../../constants/queryConstants';
import formattedFloatData from '../helpers/formattedFloatData';
import {
  DetectorsDataReturn,
  IGetDetectorsListProps,
  UseDetectorsQueryOptions,
} from '../model/detectors.model';

const { DETECTORS_DATA } = QUERY_KEY;

interface useDetectorsDataQueryProps<T extends U<true> = undefined>
  extends UseDetectorsQueryOptions<T> {
  fetchDetectorsData: (
    props: IGetDetectorsListProps
  ) => Promise<U<VehiclesDataLanes>>;
}

interface UseDetectorsQueryReturn<T extends U<true> = undefined> {
  data: DetectorsDataReturn<T>;
  isLoading: boolean;
  isError: boolean;
  isFetching: boolean;
  refetch: () => void;
}

const useDetectorsDataQuery = <T extends U<true> = undefined>({
  fetchDetectorsData,
  appPath,
  ids,
  interval,
  selectedDates,
  timeZone,
  directions,
  isNeedUtcFormatted = true,
  lanes,
  vehicleTypes,
  zones,
  specialKeys,
  isNeedTransform,
  isMiniGraph = false,
  graphTitle,
  enabled = true,
}: useDetectorsDataQueryProps<T>): UseDetectorsQueryReturn<T> => {
  const { data, isLoading, isError, isFetching, refetch } = useQuery({
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: [
      DETECTORS_DATA,
      ids,
      interval,
      selectedDates,
      isNeedUtcFormatted,
      timeZone,
      directions,
      zones,
      lanes,
      vehicleTypes,
      isNeedTransform,
      isMiniGraph,
      ...(specialKeys ?? []),
    ],
    queryFn: async () => {
      if (!ids || !interval || !selectedDates) return;

      const dtData = await fetchDetectorsData({
        ids,
        appPath,
        interval,
        selectedDates,
        isNeedUtcFormatted,
        timeZone,
        directions,
        zones,
        lanes,
        vehicleTypes,
      });

      if (isNeedTransform && graphTitle && dtData) {
        const res = normilizeData(graphTitle, dtData, isMiniGraph);

        return {
          ...res,
          data: formattedFloatData(graphTitle, res.data),
        };
      }

      return dtData;
    },
    refetchInterval: DEFAULT_UPDATE_TIME,
    staleTime: DEFAULT_STALE_TIME,
    enabled: enabled,
  });

  return {
    data: data as DetectorsDataReturn<T>,
    isLoading,
    isError,
    isFetching,
    refetch,
  };
};

export default useDetectorsDataQuery;
