import { type DefaultOptionType } from 'antd/es/select';
import { SelectProps } from 'antd/lib';
import { isString } from 'lodash';

import { Device } from '../../../../../../api/devices/model/devices.model';
import {
  DEVICE_TYPES_ID,
  DEVICES_TYPES,
  TDevicesIds,
} from '../../../../../../constants/constants';
import { getObjectEntries } from '../../../../../../helpers/getObjectEntries';
import sortByStr from '../../../../../../helpers/sortByStr';
import rootStore from '../../../../../../stores/rootStore/rootStore';
import { isSystem } from '../../../../../../ts/models/mapObject.model';
import { SEPARATE } from '../../../../../Panel/ScriptsControl/SelectModule/DispatchGroupControl/SelectItem/helpers/helpers';
import SelectItem from '../SelectItem/SelectItem';

type SelectionProps = NonNullable<SelectProps['options']>;

type SelectedOptions = Record<TDevicesIds, SelectionProps>;

const getFormattedKey = (
  id: number,
  caption: string,
  deviceTypeId: TDevicesIds
) => {
  return `${id}${SEPARATE}${caption}${SEPARATE}${deviceTypeId}`;
};

const getDefaultSystems = () =>
  DEVICES_TYPES.reduce((acc: SelectedOptions, id) => {
    acc[id] = [];

    return acc;
  }, {} as SelectedOptions);

export const getOptionsSelect = (devices: U<Device[]>) => {
  const defaultSystems = getDefaultSystems();

  if (!devices) return null;

  const res = devices.reduce((acc: SelectedOptions, item) => {
    const {
      device_type_id: deviceTypeId,
      sname,
      id,
      device_status_id: deviceStatusId,
    } = item;

    const typeName = DEVICE_TYPES_ID[deviceTypeId];

    const system = isSystem(typeName) ? typeName : null;

    system &&
      acc[deviceTypeId].push({
        value: getFormattedKey(id, sname, deviceTypeId),
        label: (
          <SelectItem
            key={id}
            system={system}
            id={id}
            caption={sname}
            deviceStatusId={deviceStatusId}
          />
        ),
        id: `${id}${SEPARATE}${deviceTypeId}`,
      });

    return acc;
  }, defaultSystems);

  const items = getObjectEntries(res).reduce(
    (acc: SelectionProps, [deviceTypeId, data]) => {
      if (data.length) {
        const typeName = DEVICE_TYPES_ID[deviceTypeId];

        typeName &&
          acc.push({
            label: <span>{typeName}</span>,
            title: typeName,
            options: sortByStr(data, 'id'),
          });
      }

      return acc;
    },
    []
  );

  return items;
};

export const getOptionValue = (optionsValue: Nullish<DefaultOptionType[]>) => {
  const { selectedDevice } = rootStore.adminPanelStore;

  if (!selectedDevice || !optionsValue) return null;

  let option: N<string> = null;

  optionsValue.forEach(({ options }) => {
    if (!option) {
      options.forEach(({ id: itemInfo, value }: DefaultOptionType) => {
        if (isString(value) && !option) {
          const [id] = itemInfo.split(SEPARATE);

          const idNum = Number(id);

          idNum === selectedDevice.id && (option = value);
        }
      });
    }
  });

  return option;
};
