import { Dropdown } from 'antd';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { CSSProperties, FC, RefObject, useRef } from 'react';
import { BiFullscreen } from 'react-icons/bi';
import { FiInfo, FiMapPin } from 'react-icons/fi';
import { GiHamburgerMenu } from 'react-icons/gi';
import { RxUpdate } from 'react-icons/rx';

import rootStore from '../../../stores/rootStore/rootStore';
import ButtonOnlyIcon from '../../ui-kit/ButtonOnlyIcon/ButtonOnlyIcon';
import useBreakpoint, {
  BreakPoints,
} from '../../ui-kit/Camera/hooks/useBreakpoint';
import { getPopupContainer } from '../../ui-kit/Popover/helpers/getPopupContainer/getPopupContainer';
import Popover, { PopoverProps } from '../../ui-kit/Popover/Popover';
import { getMenuItems } from '../helpers/getMenuItems';
import { HandleSetStream, VideoSourceItem } from '../models/VideoCamera.model';

import DtLanesButton, { DtLanesProps } from './DtLanesButton/DtLanesButton';
import StreamSelect from './StreamSelect/StreamSelect';

import styles from './ActionBlock.module.scss';

type Click = () => void;

interface PartialActions {
  onUpdateClick: N<Click>;
  onPinClick: N<Click>;
  onInfoClick: N<Click>;
  onFullScreen: N<Click>;
  isMenuBtn: boolean;
  isSmallSize: boolean;
  dtLanesProps: N<DtLanesProps>;
  isDisabledPin: boolean;
}

export type ActionProps = Partial<PartialActions> & {
  activeStream: N<VideoSourceItem>;
  videoSources: U<VideoSourceItem[]>;
  cameraWrapperRef: RefObject<HTMLDivElement>;
  statusFullScreen: boolean;
  setStream: N<HandleSetStream>;
};

const ICON_SIZES: Record<BreakPoints, number> = {
  XXS: 6,
  XS: 8,
  S: 10,
  M: 12,
  L: 15,
};

const MENU_ICON_SIZE = 20;

const ActionBlock: FC<ActionProps> = ({
  onUpdateClick,
  onPinClick,
  onInfoClick,
  onFullScreen,
  isDisabledPin = false,
  isSmallSize = true,
  isMenuBtn = false,
  dtLanesProps,
  activeStream,
  setStream,
  cameraWrapperRef,
  videoSources,
  statusFullScreen,
}) => {
  const { interfaceTipsDelay, isPanel } = rootStore.uiStore;

  const containerRef = useRef<HTMLDivElement>(null);

  const { breakPoint } = useBreakpoint({
    cameraWrapRef: cameraWrapperRef,
    isPanel,
  });

  if (!breakPoint) return null;

  const iconsStyles = classNames({
    [styles.icon]: true,
    [styles.smallSize]: isSmallSize,
  });

  const size = `${ICON_SIZES[breakPoint]}px`;

  const iconStyle: CSSProperties = { width: size, height: size };

  const basicProps = {
    className: iconsStyles,
    style: iconStyle,
  };

  const contRef = getPopupContainer(containerRef, statusFullScreen);
  const popoverProps: Omit<PopoverProps, 'children' | 'content'> = {
    placement: 'top',
    tipsDelay: interfaceTipsDelay,
    containerRef: contRef?.ref,
  };

  const items = getMenuItems(MENU_ICON_SIZE);

  return (
    <>
      <div
        className={styles.wrapper}
        ref={containerRef}
        data-testid="action-block"
      >
        {dtLanesProps ? (
          <DtLanesButton
            {...dtLanesProps}
            popoverProps={popoverProps}
            basicProps={basicProps}
          />
        ) : null}
        {onUpdateClick ? (
          <Popover content="Обновить" {...popoverProps}>
            <RxUpdate
              {...basicProps}
              onClick={onUpdateClick}
              data-testid="update"
            />
          </Popover>
        ) : null}
        {setStream ? (
          <StreamSelect
            activeStream={activeStream}
            setStream={setStream}
            videoSources={videoSources}
            containerRef={contRef?.ref}
            popoverProps={popoverProps}
            basicProps={basicProps}
          />
        ) : null}
        {onPinClick ? (
          <ButtonOnlyIcon
            onClick={onPinClick}
            tip={
              !isDisabledPin
                ? 'Показать на карте'
                : 'Камера находится за пределами региона'
            }
            className={iconsStyles}
            isDisabled={isDisabledPin}
          >
            <FiMapPin {...basicProps} onClick={onPinClick} data-testid="pin" />
          </ButtonOnlyIcon>
        ) : null}
        {onInfoClick ? (
          <Popover content="Подробная Информация" {...popoverProps}>
            <FiInfo
              onClick={onInfoClick}
              className={classNames(iconsStyles, {
                [styles.error]: !activeStream?.url,
              })}
              data-testid="info"
              style={iconStyle}
            />
          </Popover>
        ) : null}
        {isMenuBtn ? (
          <Dropdown
            menu={{ items }}
            placement="top"
            getPopupContainer={contRef?.func}
          >
            <GiHamburgerMenu {...basicProps} data-testid="menu" />
          </Dropdown>
        ) : null}
        {onFullScreen ? (
          <Popover content="Полный экран" {...popoverProps}>
            <BiFullscreen
              {...basicProps}
              onClick={onFullScreen}
              data-testid="fullscreen"
            />
          </Popover>
        ) : null}
      </div>
    </>
  );
};

export default observer(ActionBlock);
