import { useEffect } from 'react';

import api from '../api';
import { refreshTokensRequest } from '../api/helpers/refreshTokensRequest';
import {
  LoginErrorStatus,
  SERVER_ERROR_STATUS,
} from '../components/Login/constants/loginConstants';
import notification from '../components/ui-kit/Notification/Notification';
import eventBus from '../eventBus';
import rootStore from '../stores/rootStore/rootStore';
import { IGroup } from '../stores/userDataStore/userDataStore';
import { UserRoleScales } from '../ts/enums/userData';

import useRemoteSettings from './useRemoteSettings';

const useAuth = () => {
  const { userDataStore, uiStore } = rootStore;
  const { user } = userDataStore;
  const { applySettings } = useRemoteSettings();

  useEffect(() => {
    checkAuthHandler();
  }, []); // eslint-disable-line

  useEffect(() => {
    const unsubscribe = eventBus.userDataUpdate.subscribe(async () => {
      if (!userDataStore.isAuth) return;

      userDataStore.setKeyValue('isNeedPageReload', true);
      const currentRegionId = uiStore.regionData?.id;

      await checkAuthHandler();
      if (
        !currentRegionId ||
        !userDataStore.user?.regions_id.includes(currentRegionId)
      ) {
        uiStore.setKeyValue('regionData', null);
      }
    });

    return () => {
      unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (user) {
      applySettings(user.jprofile);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const checkAuthHandler = async (): Promise<boolean> => {
    try {
      userDataStore.setKeyValue('isAppReady', false);

      const accessToken = userDataStore.accessToken;
      const isRemember = userDataStore.isRemember;

      if (!accessToken && !isRemember) {
        throw new Error('user access error');
      }

      if (!accessToken) {
        await refreshTokensRequest('init checkAuthHandler');
      }

      const response = await api.user.infoBySelf('useAuth.checkAuthHandler');
      const { user } = response.data;
      const accessEls = user?.jsonb_info?.accesswebelements;

      if (!accessEls || !accessEls.length) {
        notification.error('USER_ACCESS_ERROR');

        throw new Error('user access error');
      }

      const groupsData = await api.server.fetchGroups(
        'useAuth.checkAuthHandler'
      );

      const groups = await api.adminObjects.groups.fetchGroups(
        'admin.getAllGroupsObj'
      );

      if (!groupsData || !groupsData.groups.length) {
        notification.error('SYSTEM_GROUPS_ERROR');

        throw new Error('system groups error');
      }

      const webGroups = groupsData.groups;
      let roleValue;
      let scaleValue = 0;

      user?.groups.forEach((userGroup: number) => {
        webGroups.forEach((webGroup: IGroup) => {
          if (userGroup === Number(webGroup.id)) {
            if (scaleValue < UserRoleScales[webGroup.code])
              scaleValue = UserRoleScales[webGroup.code];

            roleValue = UserRoleScales[scaleValue];
          }
        });
      });

      if (!roleValue) {
        notification.error('USER_ACCESS_ERROR');
        throw new Error(
          'User groups do not match the available application groups'
        );
      }

      const urlConfigurations = await api.server.web.getUrlConfigurations(
        'getUrlConfigurations'
      );

      userDataStore.setKeysValues({
        webSystemId: groupsData.systemId,
        serverSystemId: groupsData.serverSystemId,
        groups: groups ?? [],
        role: roleValue,
        urlConfigurations: urlConfigurations,
      });

      userDataStore.setUserData(user);

      return true;
    } catch (e: any) {
      if (e?.response?.status === 403) {
        notification.error('BLOCKED_ACCESS', {
          dsc: e?.response?.data?.additional?.reason,
        });
      }

      userDataStore.resetUserData();

      return false;
    } finally {
      userDataStore.setKeyValue('isAppReady', true);
    }
  };

  const loginHandler = async (
    login: string,
    password: string,
    isRemember: boolean
  ): Promise<N<LoginErrorStatus>> => {
    try {
      const request = await api.user.auth.authorization(
        login,
        password,
        'login'
      );
      const response = request.data;

      userDataStore.setTokens(response.tokens, isRemember);

      return null;
    } catch (e: any) {
      userDataStore.resetUserData();

      if (SERVER_ERROR_STATUS.some((el) => el === e?.response?.status))
        return LoginErrorStatus.ServerError;

      if (e?.response?.status === 403) {
        notification.error('BLOCKED_ACCESS', {
          dsc: e?.response?.data?.additional?.reason,
        });

        return LoginErrorStatus.AccessBlocked;
      }

      return LoginErrorStatus.AuthError;
    }
  };

  return { loginHandler, checkAuthHandler };
};

export default useAuth;
