import { isNull } from 'lodash';

import { findBy, findById } from '../../../../../../../../helpers/findBy';
import { Detector } from '../../../../../../../../ts/models/mapObject.model';
import { Scene } from '../../../../../../../../ts/models/MapObjects/detectors.model';
import { SceneErrorsType } from '../../../../../../../VideoCamera/constants/constants';

type Lanes = NonNullable<Scene>['lanes'];

const { NotLinkedAllZones, NotLinkedSomeZones } = SceneErrorsType;

interface FilteredScene {
  scene: Scene;
  sceneErrorType?: N<SceneErrorsType>;
}

interface FilteredProps {
  scene: U<Scene>;
  detectors: Detector[];
  id: N<number>;
  isShowOnlyZones: boolean;
}

export const getFilteredScene = ({
  detectors,
  id,
  isShowOnlyZones,
  scene,
}: FilteredProps): FilteredScene => {
  const activeDt = findById(detectors, id);

  const profile = activeDt?.finalFromProfiles;

  if (!scene?.trafficLines.length || isShowOnlyZones)
    return {
      scene,
      sceneErrorType: null,
    };

  const fromCamera = profile?.fromCamera?.laneParams;
  const toCamera = profile?.toCamera?.laneParams;

  const isAllLanesNotLinkedFromCamera = fromCamera?.every(({ number }) =>
    isNull(number)
  );
  const isAllLanesNotLinkedToCamera = toCamera?.every(({ number }) =>
    isNull(number)
  );

  if (
    (!fromCamera?.length && !toCamera?.length) ||
    (isAllLanesNotLinkedFromCamera && isAllLanesNotLinkedToCamera)
  )
    return {
      scene,
      sceneErrorType: NotLinkedAllZones,
    };

  const filteredScene = scene.lanes.reduce((acc: Lanes, lane) => {
    const laneParamFrom =
      lane.number && fromCamera
        ? findBy(fromCamera, lane.number, 'number')
        : null;
    const laneParamTo =
      lane.number && toCamera ? findBy(toCamera, lane.number, 'number') : null;

    if (!laneParamFrom && !laneParamTo) return acc;

    if (laneParamFrom || laneParamTo) {
      acc.push({
        ...lane,
        number: laneParamFrom?.id ?? laneParamTo?.id,
      });
    }

    return acc;
  }, []);

  const isSomeLanesNotLinked = [
    ...(fromCamera ?? []),
    ...(toCamera ?? []),
  ].some(({ number }) => isNull(number));

  if (isSomeLanesNotLinked) {
    return {
      sceneErrorType: NotLinkedSomeZones,
      scene: {
        lanes: filteredScene,
        trafficLines: scene.trafficLines,
      },
    };
  }

  return {
    sceneErrorType: null,
    scene: {
      lanes: filteredScene,
      trafficLines: scene.trafficLines,
    },
  };
};
