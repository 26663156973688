import Feature from 'ol/Feature';
import { Geometry, LineString, Point, Polygon } from 'ol/geom';
import { Draw } from 'ol/interaction';
import { Vector as VectorLayer } from 'ol/layer';
import BaseLayer from 'ol/layer/Base';
import { toLonLat } from 'ol/proj';
import { Vector as VectorSource } from 'ol/source';
import { getArea, getLength } from 'ol/sphere';

import { CURSOR_MAP } from '../../../constants/mapConstants';
import {
  STYLES,
  MEASURE_ACTIONS,
  NAMING,
  MEASURE_LIMITS,
} from '../../../constants/measurementsConstants';
import { TMap } from '../../../stores/mapStore/mapStore.model';
import rootStore from '../../../stores/rootStore/rootStore';
import { IMeasureAction } from '../Map.model';

const { CROSS, GRAB } = CURSOR_MAP;

const {
  SEGMENT_STYLE,
  POLYGON_STYLE,
  MODIFY_STYLE,
  TIP_STYLE,
  LABEL_STYLE,
  LABEL_UNDER_POINT_STYLE,
} = STYLES;

const {
  MEASURE_XY,
  MEASURE_DISTANCE,
  MEASURE_AREA,
  MEASURE_HIDE,
  MEASURE_SHOW,
  MEASURE_CLEAR,
} = MEASURE_ACTIONS;

const segmentStyles = [SEGMENT_STYLE];

export const formatLength = (geometry: LineString) => {
  const value = getLength(geometry);

  return `${(value / 1000).toFixed(MEASURE_LIMITS.TOLERANCE)} км`;
};

export const formatArea = (geometry: Polygon) => {
  const value = getArea(geometry);

  return `${(value / 1000000).toFixed(MEASURE_LIMITS.TOLERANCE)} км2`;
};

export const formatPoint = (geometry: Point) => {
  const coordinates = toLonLat(geometry.getCoordinates())
    .map((value) => {
      return value.toFixed(MEASURE_LIMITS.COORDINATES);
    })
    .reverse();

  return coordinates.join(`, `);
};

const source = new VectorSource();

let vertexPoint: Point;

const styleFunction = (
  feature: Feature,
  segments: boolean,
  drawType?: string,
  tip?: string
) => {
  const styles = [];
  const geometry = feature.getGeometry() as Geometry;
  const type = geometry.getType();
  let point, label, line;

  if (!drawType || drawType === type || type === MEASURE_XY.GEOMETRY_TYPE) {
    styles.push(POLYGON_STYLE);
    if (type === MEASURE_AREA.GEOMETRY_TYPE && geometry instanceof Polygon) {
      point = geometry.getInteriorPoint();
      label = formatArea(geometry);
      line = new LineString(geometry.getCoordinates()[0]);
    }

    if (
      type === MEASURE_DISTANCE.GEOMETRY_TYPE &&
      geometry instanceof LineString
    ) {
      point = new Point(geometry.getLastCoordinate());
      label = formatLength(geometry);
      line = geometry;
    }
  }

  if (type === MEASURE_XY.GEOMETRY_TYPE && geometry instanceof Point) {
    point = geometry;
    label = formatPoint(geometry);
  }

  if (
    segments &&
    line &&
    (geometry instanceof LineString || geometry instanceof Polygon)
  ) {
    let count = 0;

    line.forEachSegment((a: any, b: any) => {
      const segment = new LineString([a, b]);
      const label = formatLength(segment);

      if (segmentStyles.length - 1 < count) {
        segmentStyles.push(SEGMENT_STYLE.clone());
      }

      const segmentPoint = new Point(segment.getCoordinateAt(0.5));

      segmentStyles[count].setGeometry(segmentPoint);
      segmentStyles[count].getText()?.setText(label);
      styles.push(segmentStyles[count]);

      count++;
    });
  }

  if (label && point) {
    let labelStyle;

    if (drawType === MEASURE_DISTANCE.GEOMETRY_TYPE && type === 'Point') {
      labelStyle = LABEL_UNDER_POINT_STYLE;
    } else {
      labelStyle = LABEL_STYLE;
    }

    labelStyle.setGeometry(point);
    labelStyle.getText()?.setText(label);
    styles.push(labelStyle);
  }

  if (tip && type === MEASURE_XY.GEOMETRY_TYPE && geometry instanceof Point) {
    vertexPoint = geometry;
    TIP_STYLE.getText()?.setText(tip);
    styles.push(TIP_STYLE);
  }

  return styles;
};

export const setMapCursor = (map: TMap, type: string) => {
  if (!map) {
    return;
  }

  const style = map.getViewport().style;

  if (
    type === MEASURE_XY.TYPE ||
    type === MEASURE_DISTANCE.TYPE ||
    type === MEASURE_AREA.TYPE
  ) {
    style.setProperty('cursor', CROSS, 'important');
  }

  if (type === MEASURE_CLEAR.TYPE) {
    style.setProperty('cursor', GRAB, 'important');
  }
};

const getMeasurementsLayer = (map?: TMap): N<BaseLayer> => {
  if (!map) {
    return null;
  }

  const layers = map.getLayers().getArray();
  const measuringLayer = layers.find((element) => {
    return (
      element.get(NAMING.LAYER_PROPERTY_NAME) ===
      NAMING.DEFAULT_MEASURING_LAYER_NAME
    );
  });

  return measuringLayer || null;
};

const createMeasurementsLayer = (map?: TMap) => {
  if (!map) {
    return;
  }

  const layer = new VectorLayer({
    source,
    style: (feature: any) => styleFunction(feature, true),
  });

  layer.set(NAMING.LAYER_PROPERTY_NAME, NAMING.DEFAULT_MEASURING_LAYER_NAME);

  const measuringLayer = getMeasurementsLayer(map);

  if (!measuringLayer) {
    map.addLayer(layer);
  }
};

const removeDrawingInteractions = (map: TMap, type?: string) => {
  if (!map || !type) {
    return;
  }

  const interactions = map.getInteractions().getArray();

  const drawInteractions = interactions.filter((interaction) => {
    return interaction instanceof Draw;
  });

  drawInteractions.forEach((interaction) => map.removeInteraction(interaction));
};

const updateMeasurementsState = () => {
  const { setGisValue } = rootStore.gisDataStore;

  setGisValue('measurementsChange', Math.random());
};

const startMeasurement = (map: TMap, type: string) => {
  if (!map) {
    return;
  }

  setMapCursor(map, type);
  removeDrawingInteractions(map, type);
  createMeasurementsLayer(map);

  const drawTypes: any = {};

  drawTypes[MEASURE_XY.TYPE] = MEASURE_XY.GEOMETRY_TYPE;

  drawTypes[MEASURE_DISTANCE.TYPE] = MEASURE_DISTANCE.GEOMETRY_TYPE;

  drawTypes[MEASURE_AREA.TYPE] = MEASURE_AREA.GEOMETRY_TYPE;

  const drawType = drawTypes[type] || drawTypes[MEASURE_DISTANCE.TYPE];

  const activeTip = ``;
  const idleTip = ``;

  let tip = ``;

  const draw = new Draw({
    source,
    type: drawType,
    style: (feature: any) => {
      return styleFunction(feature, true, drawType, tip);
    },
  });

  draw.on('drawstart', function () {
    tip = activeTip;
  });

  draw.on('drawend', () => {
    MODIFY_STYLE.setGeometry(vertexPoint);

    updateMeasurementsState();

    map.once('pointermove', () => {
      MODIFY_STYLE.setGeometry(vertexPoint);
    });

    tip = idleTip;
  });

  map.addInteraction(draw);
};

const removeMeasurementsLayer = (map?: TMap) => {
  if (!map) {
    return;
  }

  const measuringLayer = getMeasurementsLayer(map);

  if (measuringLayer && measuringLayer instanceof VectorLayer) {
    measuringLayer.getSource().clear();
    map.removeLayer(measuringLayer);
  }

  updateMeasurementsState();
};

const stopMeasurement = (map: TMap, type: string) => {
  setMapCursor(map, type);
  removeMeasurementsLayer(map);
  removeDrawingInteractions(map, type);
  updateMeasurementsState();
};

const hideMeasurement = (map: TMap, type: string) => {
  const measuringLayer = getMeasurementsLayer(map);

  if (measuringLayer && measuringLayer instanceof VectorLayer) {
    measuringLayer.setVisible(false);
  }

  removeDrawingInteractions(map, type);

  updateMeasurementsState();
};

const showMeasurement = (map: TMap) => {
  const measuringLayer = getMeasurementsLayer(map);

  if (measuringLayer && measuringLayer instanceof VectorLayer) {
    measuringLayer.setVisible(true);
  }

  updateMeasurementsState();
};

export const handleMeasuring = (map?: TMap, action?: IMeasureAction) => {
  if (!(action && map)) {
    return;
  }

  const { type } = action;

  if (!type) {
    return;
  }

  const executorsDict: any = {};

  executorsDict[MEASURE_CLEAR.TYPE] = stopMeasurement;
  executorsDict[MEASURE_XY.TYPE] = startMeasurement;
  executorsDict[MEASURE_DISTANCE.TYPE] = startMeasurement;
  executorsDict[MEASURE_AREA.TYPE] = startMeasurement;
  executorsDict[MEASURE_HIDE.TYPE] = hideMeasurement;
  executorsDict[MEASURE_SHOW.TYPE] = showMeasurement;

  const executor = executorsDict[type || MEASURE_CLEAR.TYPE] || null;

  executor && executor(map, type);
};
