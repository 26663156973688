import {
  AdapterInfo,
  AdditionalAdapterKeys,
  MainAdapterKeys,
  TerminationKeys,
} from '../../../../../components/Admin/AdminSystemObject/ASOMonitoring/models/monitoring.models';
import { Path } from '../../../../api.model';

export type SystemType = number[] | number;

export interface StatisticProps {
  appPath: Path;
  systemIds?: number[];
  systemTypes?: number[];
  regionIds?: number[];
  subSystemTypes?: number[];
  isBlockedNotify?: boolean;
}

export interface useStatisticQueryOptions {
  appPath: string;
  systemIds?: SystemType;
  systemTypes?: SystemType;
  subSystemTypes?: SystemType;
  regionIds?: SystemType;
  specialKeys?: unknown[];
  isEnabled?: boolean;
}

export enum AdapterTypes {
  Adapter = 'adapter',
  AdapterXeoma = 'adapterXeoma',
  AdapterGis = 'adapterGis',
  AdapterMicroService = 'adapterMicroService',
}

export enum EnabledStatus {
  Off,
  On,
}

interface AdapterGoInfo {
  host_id: string;
  uptime_sec: number;
  session_uuid: string;
  status: string;
  lastTimeTZ: string;
  statistics: N<
    Partial<{
      received_passes_from_start: N<number>;
      received_passes_from_last_10min: N<number>;
      events_from_start: N<number>;
      events_from_last_10min: N<number>;
      latest_passage_timeTZ: N<string>;
      latest_event_timeTZ: N<string>;
      started_jobs_from_start: N<number>;
      started_jobs_from_last_10min: N<number>;
      latest_started_job_timeTZ: N<string>;
    }>
  >;
  error: N<{
    jerror: OWKS<any>;
    timeTZ: string;
  }>;
  sc_system_id: number;
}

interface BaseAdapterData {
  sc_system_id: number;
  sc_system_regions_id: N<number[]>;
  sub_systems_id: N<number[]>;
  sc_system_type_id: number;
}

export interface TerminationItem {
  session_status: string;
  old_session_uuid: N<string>;
  new_session_uuid: string;
  timeNewSessionTZ: string;
  old_adapter: N<AdapterGoInfo>;
}

export interface AdapterData extends BaseAdapterData {
  add_info: {
    adapter: N<AdapterGoInfo>;
    terminations: N<TerminationItem[]>;
  };
  is_enabled: EnabledStatus;
}

interface ExternalAdapter extends BaseAdapterData {
  error: N<Record<string, any>>;
  status: string;
  uptime_sec: number;
  statistic: unknown;
}

export interface GoAdapter extends BaseAdapterData {
  error: N<Record<string, any>>;
  status: N<string>;
  uptime_sec: N<number>;
  statistic: unknown;
  is_enabled?: EnabledStatus;
  isValidationSuccess: boolean;
  terminations?: N<TerminationItem[]>;
}

export interface InvalidAdapter extends Partial<BaseAdapterData> {
  status: N<string>;
  errorInvalid: GeneralAdapterStatistic['error'];
  isValidationSuccess: boolean;
}

export type MainAdapter = GoAdapter | InvalidAdapter;

export interface GeneralAdapterStatistic {
  data?: AdapterData | ExternalAdapter;
  data_type: AdapterTypes;
  isValidationSuccess: boolean;
  error?: {
    issues: Record<string, any>[];
    data: AdapterData | ExternalAdapter;
  };
}

export type StatisticByType = Partial<Record<AdapterTypes, MainAdapter[]>>;

export type FormattedStatistic = {
  systemName: N<string>;
  regions: {
    id: number;
    name: string;
  }[];
  adapterType: AdapterTypes;
} & MainAdapter;

export type FormattedAdapter = Record<MainAdapterKeys, AdapterInfo> &
  Partial<Record<AdditionalAdapterKeys, AdapterInfo>>;

export type FormattedTerminations = Record<TerminationKeys, AdapterInfo> & {
  oldAdapter: N<FormattedStatistic>;
};

export const isAdapterGo = (
  value: unknown,
  type: AdapterTypes
): value is AdapterData => type === AdapterTypes.Adapter;

export const isInvalidAdapter = (value: unknown): value is InvalidAdapter =>
  !!value && Object.hasOwn(value, 'errorInvalid');

export const isStandardEnabled = (value: MainAdapter): value is GoAdapter =>
  Object.hasOwn(value, 'is_enabled');

export const isTerminations = (value: unknown): value is GoAdapter =>
  Boolean(value && Object.hasOwn(value, 'terminations'));
