import { Slider } from 'antd';
import type { CanvasFilterOptions } from 'ol-ext/filter/CanvasFilter';

import {
  BASEMAP_SETTINGS_ITEMS,
  BASEMAP_SETTINGS_PREDEFINED_ITEMS,
} from './constants';
import { getInitialBasemapSettings } from './helpers';

import styles from './BasemapsButton.module.scss';

const BaseMapSettings = (props: {
  onSettingsChange: (evt: any) => void;
  settings?: CanvasFilterOptions;
}) => {
  const { onSettingsChange } = props;
  const settings = props.settings || getInitialBasemapSettings();

  const updateSettings = (value: number, property: string) => {
    const data: CanvasFilterOptions = {
      ...settings,
      [property]: value,
    };

    onSettingsChange(data);
  };

  const getCurrentValue = (item: { property: string }): number => {
    const property = item.property || 'contrast';

    // @ts-ignore
    return Number(settings[property]) || 0;
  };

  return (
    <div>
      <div className={styles.baseMapSettingsContainer}>
        <div className={styles.baseMapSettingsTitle}>Готовые фильтры</div>
        <div className={styles.baseMapSettingsPredefinedContainer}>
          {BASEMAP_SETTINGS_PREDEFINED_ITEMS.map((item) => {
            const { value, text } = item;

            return (
              <div
                key={item.text}
                className={styles.baseMapSettingsPredefinedButton}
                role={'presentation'}
                onClick={() => {
                  onSettingsChange(value);
                }}
              >
                {text}
              </div>
            );
          })}
        </div>

        <div className={styles.baseMapSettingsTitle}>Подстройка</div>
        {BASEMAP_SETTINGS_ITEMS.map((item) => {
          const value = getCurrentValue(item);

          return (
            <div key={item.property}>
              <div className={styles.basemapSettingsPropertyTitle}>
                {item.text}
              </div>
              <Slider
                value={value}
                onChange={(value) => {
                  updateSettings(value, item.property);
                }}
                min={item.min}
                max={item.max}
                step={item.step}
                className={styles.basemapSettingsSlider}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default BaseMapSettings;
