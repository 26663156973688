import { Form, Select } from 'antd';
import { type Rule } from 'antd/es/form';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import { HandleChangeSelect } from '../../../models/DeviceAdmin.model';
import { FormSourceKeys } from '../constants/constants';

import useSystemTypes from './hooks/useSystemTypes';

const { SystemTypeId } = FormSourceKeys;

interface SystemTypesSelectProps {
  getSourceRules: (value: FormSourceKeys) => U<Rule[]>;
  handleChangeSelect: HandleChangeSelect;
  isShow: boolean;
}

const SystemTypesSelect: FC<SystemTypesSelectProps> = ({
  getSourceRules,
  handleChangeSelect,
  isShow,
}) => {
  const { options, isLoading } = useSystemTypes();

  if (!isShow) return null;

  return (
    <Form.Item
      label="Тип системы"
      name={SystemTypeId}
      rules={getSourceRules(SystemTypeId)}
    >
      <Select
        placeholder="Выберите тип системы"
        loading={isLoading}
        options={options ?? undefined}
        onChange={(value) => handleChangeSelect(SystemTypeId, value)}
        allowClear
      />
    </Form.Item>
  );
};

export default observer(SystemTypesSelect);
