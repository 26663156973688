import { FC } from 'react';

import DeviceAddCard from '../DeviceAddCard';

import styles from './DeviceAddContainer.module.scss';

const DeviceAddContainer: FC = () => {
  return (
    <div className={styles.wrapper}>
      <DeviceAddCard />
    </div>
  );
};

export default DeviceAddContainer;
