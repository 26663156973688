import { observer } from 'mobx-react-lite';

import Header from '../Header/Header';

import DeviceFormContent from './DeviceFormContent/DeviceFormContent';
import useDeviceAdminCard from './hooks/useDeviceAdminCard';

import styles from './DeviceAdminCard.module.scss';

const DeviceAdminCard = () => {
  const { isShowArrow, handleClick } = useDeviceAdminCard();

  return (
    <div className={styles.block}>
      <Header
        title="Устройство"
        handleClick={handleClick}
        isShowArrow={isShowArrow}
      />
      <DeviceFormContent />
    </div>
  );
};

export default observer(DeviceAdminCard);
