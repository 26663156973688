import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import { TMap } from '../../../../../../stores/mapStore/mapStore.model';
import { DeviceInfo } from '../../../../../../stores/monitoringPanelStore/monitoringPanelStore.model';
import { System } from '../../../../../../ts/enums/enums';
import useCrossroadOverlay from '../../../../../TrafficLightDetailed/TlDetectors/GeneralDtTabs/CrossroadSchemaWrapper/hooks/useCrossroadOverlay';

import CrossroadTL from './CrossroadTL/CrossroadTL';
import CrossroadUds from './CrossroadUds/CrossroadUds';

import styles from './CrossroadSchema.module.scss';

const { Lights } = System;

interface CrossroadSchemaProps {
  tlId: U<number>;
  map: TMap;
  deviceInfo: DeviceInfo;
}

const CrossroadSchema: FC<CrossroadSchemaProps> = ({
  deviceInfo,
  map,
  tlId,
}) => {
  const { mainProps, crossroadDetailedRef } = useCrossroadOverlay({
    map,
  });

  const isTLCrossroad = tlId && deviceInfo.system === Lights;

  return (
    <div className={styles.container} ref={crossroadDetailedRef}>
      {isTLCrossroad ? (
        <CrossroadTL {...mainProps} tlId={tlId} deviceInfo={deviceInfo} />
      ) : (
        <CrossroadUds {...mainProps} {...deviceInfo} />
      )}
    </div>
  );
};

export default observer(CrossroadSchema);
