import { fromLonLat } from 'ol/proj';
import { FC } from 'react';
import { FiMapPin } from 'react-icons/fi';

import handleCenterZoom from '../../../Map/helpers/zoomHandlers/handleCenterZoom';
import ButtonOnlyIcon from '../../../ui-kit/ButtonOnlyIcon/ButtonOnlyIcon';
import { PointUdsNameProps } from '../../SystemsInfo.model';

const BTN_STYLE = {
  width: '25px',
  height: '15px',
  marginBottom: '-1px',
};

const PointUdsName: FC<PointUdsNameProps> = ({ point, pointUdsName }) => {
  if (!point) {
    return <>{`${pointUdsName ?? 'Без имени'} - точка не существует!`}</>;
  }

  const pointCenter = point.basicProfileInfo.centerPoint;

  const toPointCenter = () => handleCenterZoom(fromLonLat(pointCenter));

  return (
    <>
      {pointUdsName}

      <ButtonOnlyIcon
        onClick={toPointCenter}
        popoverProps={{ placement: 'top' }}
        tip={'Перейти к точке на карте'}
        style={BTN_STYLE}
      >
        <FiMapPin />
      </ButtonOnlyIcon>
    </>
  );
};

export default PointUdsName;
