import { Button, InputNumber, Switch } from 'antd';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { FiInfo } from 'react-icons/fi';

import { TL_CIRCLE_ZOOM } from '../../../../constants/constants';
import { ZOOM } from '../../../../constants/mapConstants';
import rootStore from '../../../../stores/rootStore/rootStore';
import { handleZoom as setZoom } from '../../../Map/helpers/zoomHandlers/handleZoom';
import ButtonIco from '../../../ui-kit/ButtonIco/ButtonIco';
import Popover from '../../../ui-kit/Popover/Popover';

import styles from './MapInfo.module.scss';

const HINT =
  'Пожалуйста, сначала установите координаты центра круга и значение масштаба.';

const formatZoom = (val: number) => Math.round(val * 100) / 100;

const MapInfo = () => {
  const { isOffsetRightPanel, interfaceTipsDelay } = rootStore.uiStore;
  const { currentZoom, setMapData, coordinates } = rootStore.mapDataStore;
  const {
    setConstructorData,
    setIsNotConstructor,
    isWheelZoom,
    importedZoom,
    zoomRangeEnd,
    circleCenter,
    isConstructor,
  } = rootStore.constructorStore;

  const [isInfo, setIsInfo] = useState(true);
  const [zoomStart, setZoomStart] = useState(16);

  useEffect(() => {
    importedZoom && setZoomStart(importedZoom);
  }, [importedZoom]);

  const handleIsInfo = () => setIsInfo(!isInfo);

  const handleZoomStart = (val: N<number>) =>
    val !== null && setZoomStart(formatZoom(val));

  const handleZoomEnd = (val: N<number>) =>
    val !== null && setConstructorData('zoomRangeEnd', val);

  const handleZoom = (zoom: number) => {
    setConstructorData('isApplyCenter', true);
    setMapData('coordinates', circleCenter);
    setZoom(zoom);
  };

  const handleZoomFrom = () => handleZoom(zoomStart);
  const handleZoomTo = () => handleZoom(zoomRangeTo);
  const handleWheelZoom = () => setIsNotConstructor('isWheelZoom');

  const zoomRangeTo = zoomRangeEnd > ZOOM.MAX ? ZOOM.MAX : zoomRangeEnd;
  const coord = isConstructor ? circleCenter : coordinates;
  const longitude = coord[0] ? coord[0].toFixed(10) : 'не установлено';
  const latitude = coord[1] ? coord[1].toFixed(10) : 'не установлено';

  const getOffsetStyle = (
    mainStyle: 'mapInfoBtn' | 'mapInfo' | 'zoomContainer'
  ) =>
    classNames({
      [styles[mainStyle]]: true,
      [styles.zoomWithWarning]:
        mainStyle === 'zoomContainer' && !circleCenter[0],
      [mainStyle === 'mapInfoBtn' ? styles.offsetBtn : styles.offset]:
        isOffsetRightPanel,
    });

  const tooltipText = `${!isInfo ? 'Показать' : 'Скрыть'} аннотацию карты?`;

  const popOverProps = {
    tipsDelay: interfaceTipsDelay,
    content: tooltipText,
    placement: 'left',
  } as const;

  return (
    <>
      <Popover {...popOverProps}>
        <div className={getOffsetStyle('mapInfoBtn')}>
          <ButtonIco isRound onClick={handleIsInfo}>
            <FiInfo />
          </ButtonIco>
        </div>
      </Popover>

      {isInfo && (
        <>
          {isConstructor && (
            <div className={getOffsetStyle('zoomContainer')}>
              <div>Сравнение масштаба</div>

              {!circleCenter[0] ? <p className={styles.hint}>{HINT}</p> : null}

              <div className={styles.zoomWrapper}>
                <div>
                  <span className={styles.inputLabelFrom}> От:</span>
                  <InputNumber
                    min={TL_CIRCLE_ZOOM.START}
                    max={ZOOM.MAX}
                    step={TL_CIRCLE_ZOOM.STEP}
                    className={styles.zoomInput}
                    onChange={handleZoomStart}
                    onStep={handleZoomStart}
                    value={zoomStart}
                  />

                  <span className={styles.inputLabelTo}> До:</span>
                  <InputNumber
                    min={zoomStart}
                    max={ZOOM.MAX}
                    step={TL_CIRCLE_ZOOM.STEP}
                    className={styles.zoomInput}
                    onChange={handleZoomEnd}
                    onStep={handleZoomEnd}
                    value={zoomRangeTo}
                  />
                </div>

                <div>
                  <Button
                    type="primary"
                    onClick={handleZoomFrom}
                    className={styles.compareBtn}
                    disabled={!circleCenter[0] || !zoomStart}
                  >
                    От
                  </Button>

                  <Button
                    danger={Boolean(circleCenter[0])}
                    type="primary"
                    onClick={handleZoomTo}
                    className={styles.compareBtn}
                    disabled={!circleCenter[0] || !zoomRangeTo}
                  >
                    До
                  </Button>
                </div>
              </div>

              <div className={styles.zoomWrapper}>
                <div className={styles.wheelZoom}>
                  Масштабирование колесом мыши:
                </div>
                <div className={styles.wheelZoomSwitch}>
                  <Switch
                    size="small"
                    checked={isWheelZoom}
                    onClick={handleWheelZoom}
                  />
                </div>
              </div>
            </div>
          )}

          <div className={getOffsetStyle('mapInfo')}>
            <div>{`Масштаб: ${currentZoom.toFixed(2)}`}</div>

            <div>{`Широта: ${latitude}`}</div>

            <div>{`Долгота: ${longitude}`}</div>
          </div>
        </>
      )}
    </>
  );
};

export default observer(MapInfo);
