import { FormInstance } from 'antd/lib';
import { Rule } from 'antd/lib/form';

import { IPvalid } from '../../../../../../ts/models/table.model';

type IPvalidItem = ValueOf<IPvalid>;

const DEFAULT_ERROR = 'Некорректно заполненноe поле';

export const getValidationFnc = (regExp: string, message: string) => {
  return () => ({
    validator(_: any, value: string) {
      const newReg = new RegExp(regExp);

      if (!value || newReg.test(value)) {
        return Promise.resolve();
      }

      return Promise.reject(message);
    },
  });
};

export const getValidationRules = (param: IPvalidItem) => {
  const rules: Rule[] = [];

  if (param) {
    const keys = Object.keys(param);

    keys.forEach((key) => {
      if (key === 'regExp') {
        const value = param[key];

        if (value) {
          const rule = getValidationFnc(value, param.message ?? DEFAULT_ERROR);

          rules.push(rule);
        }
      }

      if (key === 'required') {
        const rule = { required: !!param[key], message: 'Обязательное поле' };

        rules.push(rule);
      }
    });
  }

  return rules.length ? rules : undefined;
};

const getCurrentParam = (pvalid: IPvalid, name: string) => pvalid[name];

const getLabel = (param: IPvalidItem, name: string) => {
  let label = name;

  if (param) {
    label = param.translate;
  }

  return label;
};

export const getParams = (validationParams: N<IPvalid>, name: string) => {
  if (!validationParams) return null;

  const param = getCurrentParam(validationParams, name);

  const rules = getValidationRules(param);
  const label = getLabel(param, name);

  return {
    rules,
    label,
    readOnly: param?.readOnly,
    inputType: param?.input_type,
    type: param?.type,
  };
};

export const setFormValues = (
  form: FormInstance,
  setValues: SetState<string[]>,
  values: Record<string, unknown>,
  key: string
) => {
  const newValues = Object.keys(values);

  setValues(newValues);
  form.setFieldsValue(values);
};

export const checkFormReadOnlyAll = (
  values: string[],
  validationParams: IPvalid
) => {
  let isAllReadOnly = true;

  values.forEach((item) => {
    if (!validationParams[item]?.readOnly) {
      isAllReadOnly = false;
    }
  });

  return isAllReadOnly;
};
