import { Select } from 'antd';
import { observer } from 'mobx-react-lite';

import { INPUT_MAX_LEN } from '../../../../../../constants/constants';
import NOTICE from '../../../../../../constants/notificationConstants';
import { getIsFilterOption } from '../../../../../Panel/ScriptsControl/SelectModule/DispatchGroupControl/SelectItem/helpers/helpers';
import { getOptionValue } from '../helpers/optionHelpers';

import useSelectComponent from './hooks/useSelectComponent';

import styles from './SelectComponent.module.scss';

const { LIST_DEVICE_ERROR } = NOTICE;

const SelectComponent = () => {
  const { options, isLoading, isError, onChange } = useSelectComponent();

  const placeholder = isError ? LIST_DEVICE_ERROR : 'Выберите устройство';

  return (
    <Select
      className={styles.select}
      showSearch
      allowClear
      disabled={isError}
      placeholder={placeholder}
      optionFilterProp="children"
      filterOption={(input, option) => getIsFilterOption(input, option?.value)}
      value={getOptionValue(options)}
      loading={isLoading}
      maxLength={INPUT_MAX_LEN}
      menuItemSelectedIcon={null}
      options={options ?? []}
      onChange={onChange}
    />
  );
};

export default observer(SelectComponent);
