import { Form } from 'antd';
import { FormInstance } from 'antd/lib';
import { isEqual } from 'lodash';
import { useEffect, useState } from 'react';

import api from '../../../../../../../../api';
import eventBus from '../../../../../../../../eventBus';
import rootStore from '../../../../../../../../stores/rootStore/rootStore';
import notification from '../../../../../../../ui-kit/Notification/Notification';
import { AddFormFields } from '../../../../../models/DeviceAdmin.model';
import transformValues from '../helpers/transformValues';

const { createDevice, updateDevice } = api.devices.manage.manageDeviceApi;

const APP_PATH =
  'Admin.DeviceAdminCard.DeviceFormContent.DeviceAddCard.Buttons.hooks.useButtons';

const useButtons = (form: FormInstance, initialProps: U<AddFormFields>) => {
  const { isEditForm, selectedDevice, setAdminsData } =
    rootStore.adminPanelStore;

  const [isLoading, setIsLoading] = useState(false);
  const [isReadySubmit, setIsReadySubmit] = useState(false);
  const [isChangeForm, setIsChangeForm] = useState(false);

  const values = Form.useWatch([], form);

  const handleSubmit = () => {
    form
      .validateFields()
      .then(async (values) => {
        setIsLoading(true);

        const isCreate = !selectedDevice;

        const data = transformValues(values);

        const res = isCreate
          ? await createDevice(data, APP_PATH)
          : await updateDevice(selectedDevice.id, data, APP_PATH);

        if (!res) return;

        notification.success(isCreate ? 'ADD_DEVICE' : 'UP_DEVICE');
        form.resetFields();
        setIsChangeForm(false);

        if (!isCreate) {
          eventBus.updateFormDevice.broadcast();
          eventBus.updateDeviceItem.broadcast();

          return;
        }

        setAdminsData('selectedDevice', {
          id: res.data.id,
          caption: res.data.sname,
          deviceTypeId: res.data.device_type_id,
        });

        setTimeout(() => {
          eventBus.updateFormDevice.broadcast();
        }, 100);
      })
      .catch(() => {
        notification.error('ERROR');
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    form.validateFields({ validateOnly: true }).then(
      () => {
        setIsReadySubmit(true);
      },
      () => {
        setIsReadySubmit(false);
      }
    );
  }, [form, values]);

  useEffect(() => {
    values && setIsChangeForm(!isEqual(initialProps, transformValues(values)));
  }, [values, initialProps]);

  const isNoChanges = isEditForm && !isChangeForm;

  const isDisabledConfirm = !isReadySubmit || isNoChanges;

  return { isLoading, isDisabledConfirm, handleSubmit };
};

export default useButtons;
