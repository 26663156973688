import rootStore from '../../../../../../stores/rootStore/rootStore';

const getWarningNotify = () => {
  const { regionData } = rootStore.uiStore;
  const { isEditForm } = rootStore.adminPanelStore;

  if (!regionData || isEditForm) return null;

  return `Устройство будет добавлено в текущий регион: ${regionData.name}. Если вам необходимо добавить устройство в другой город, то смените регион.`;
};

export default getWarningNotify;
