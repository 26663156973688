import { type SelectProps } from 'antd/lib';
import { isString } from 'lodash';

import sortByStr from '../../../../../../../helpers/sortByStr';
import { TableData } from '../../../../../../../ts/models/table.model';
import { SEPARATE } from '../../../../../../Panel/ScriptsControl/SelectModule/DispatchGroupControl/SelectItem/helpers/helpers';
import { isDeviceCorrect } from '../../../../models/DeviceAdmin.model';

type SelectionProps = NonNullable<SelectProps['options']>;

const getFormattedKey = (extId: string, name: string) =>
  `${extId}${SEPARATE}${name}`;

const transformDevicesToOptions = (devicesList: N<TableData>) => {
  const dataRows = devicesList?.dataRows;

  if (!dataRows?.length) return null;

  const options = dataRows.reduce((acc: SelectionProps, item) => {
    if (!isDeviceCorrect(item)) return acc;

    const { eputsDeviceId, ext_id, ext_name } = item;

    if (!eputsDeviceId && isString(ext_id) && isString(ext_name)) {
      acc.push({
        value: getFormattedKey(ext_id, ext_name),
        label: <p style={{ margin: 0 }}>{ext_name}</p>,
      });
    }

    return acc;
  }, []);

  return sortByStr(options, 'label');
};

export default transformDevicesToOptions;
