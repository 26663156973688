import { Device } from '../../../../../../../api/devices/model/devices.model';
import { AddFormFields } from '../../../../models/DeviceAdmin.model';

const transformToFormData = (data: U<Device>): N<AddFormFields> => {
  if (!data) return null;

  const { device_type_id, dsc, latitude, longitude, sname } = data;

  return {
    device_type_id,
    dsc,
    latitude,
    longitude,
    sname,
  };
};

export default transformToFormData;
