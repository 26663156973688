import dayjs, { type Dayjs } from 'dayjs';

import { setRange } from '../../../TrafficLightDetailed/TlManagement/PhaseHold/helpers/setRange';

const DISABLED_TIME = 2;

const getDisableTime = (date: N<Dayjs>, disabledStart?: N<Dayjs>) => {
  const dateNow = disabledStart ?? dayjs().locale('ru');

  const hourNow = dateNow.get('hour');
  const minutesNow = dateNow.get('minute');

  const disabledDifference = minutesNow + DISABLED_TIME;
  const isNextHour = date && Math.abs(date.diff(dateNow, 'hours')) === 1;

  const disabledNextHour =
    disabledDifference < 60 ? 0 : disabledDifference - 60;

  const isToday = date?.diff(dateNow, 'days') === 0;

  if (!isToday) return {};

  const startOfDay = dateNow.startOf('day');
  const isHourNow = startOfDay.from(date) > startOfDay.from(dateNow);

  return {
    disabledHours: () => setRange(0, 24).splice(0, hourNow),
    disabledMinutes: () => {
      return !isHourNow
        ? setRange(0, 60).splice(
            0,
            !isNextHour ? minutesNow + DISABLED_TIME : disabledNextHour
          )
        : [];
    },
  };
};

export default getDisableTime;
