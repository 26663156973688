import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import { TMap } from '../../../../../../stores/mapStore/mapStore.model';
import { DeviceInfo } from '../../../../../../stores/monitoringPanelStore/monitoringPanelStore.model';
import InfoBlock from '../InfoBlock/InfoBlock';

import useFeatures from './hooks/useFeatures';
import useFeatureStyles from './hooks/useFetureStyles';
import useVectorLayer from './hooks/useVectorLayer';

interface VectorLayerProps {
  map: TMap;
  deviceInfo: DeviceInfo;
}

const VectorLayer: FC<VectorLayerProps> = ({ map, deviceInfo }) => {
  const features = useFeatures(deviceInfo);
  const clusterSource = useVectorLayer({ map, features });

  useFeatureStyles({ map, activeId: deviceInfo.id, clusterSource, features });

  return <InfoBlock map={map} features={features} />;
};

export default observer(VectorLayer);
