import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import rootStore from '../../../../../stores/rootStore/rootStore';

import DtVideoPreview from './DtVideoPreview/DtVideoPreview';

import styles from './DetectorsWrapper.module.scss';

interface IDetectorsWrapperProps {
  isTl: boolean;
  idObj: number;
  isNoImageDt: boolean;
}

const DetectorsWrapper: FC<IDetectorsWrapperProps> = ({
  isTl,
  idObj,
  isNoImageDt,
}) => {
  const { activeDtId } = rootStore.detailedStore;

  const dtId = isTl ? activeDtId : idObj;

  return (
    <div className={styles.content}>
      <DtVideoPreview id={dtId} isNoImageDt={isNoImageDt} />
    </div>
  );
};

export default observer(DetectorsWrapper);
