import { isNumber } from 'lodash';
import { FC } from 'react';

import TitleBtns from '../../../TrafficLightDetailed/TlDetectors/IntancyGraph/TitleBtns/TitleBtns';
import { IDateInfo } from '../../../TrafficLightDetailed/TlDetectors/model/graph.model';
import { IDomain } from '../constants/constants';
import {
  CalcData,
  ConfigureButtons,
  RadioProps,
  UpdateInfo,
} from '../models/chart.model';

import ExportButton from './ExportButton/ExportButton';
import GraphTypesBtns from './GraphTypesBtns/GraphTypesBtns';
import InfoButton from './InfoButton/InfoButton';
import UpdateButton from './UpdateButton/UpdateButton';
import ZoomOutButton from './ZoomOutButton/ZoomOutButton';

import styles from './ButtonsPanel.module.scss';

export interface IButtonsPanelProps {
  id: U<number>;
  domain: IDomain;
  timeUpdate: UpdateInfo;
  isLoading: boolean;
  isError: boolean;
  graphInfo: IDateInfo;
  data: CalcData[];
  radioProps: RadioProps;
  title?: string;
  handleZoomOut: () => void;
  handleUpdateGraph: () => void;
  buttonsConfigure: ConfigureButtons;
}

const ButtonsPanel: FC<IButtonsPanelProps> = ({
  id,
  timeUpdate,
  domain,
  isLoading,
  isError,
  graphInfo,
  data,
  title = 'Отчет',
  radioProps,
  handleZoomOut,
  handleUpdateGraph,
  buttonsConfigure,
}) => {
  const { isExport, isScale } = buttonsConfigure;

  return (
    <div className={styles.container}>
      {isNumber(id) ? <GraphTypesBtns id={id} /> : null}
      <TitleBtns {...radioProps} isError={isError} />
      <div className={styles.buttons}>
        <ZoomOutButton
          domain={domain}
          handleZoomOut={handleZoomOut}
          isShow={isScale}
        />
        <UpdateButton
          isLoading={isLoading}
          handleUpdateGraph={handleUpdateGraph}
        />
        <ExportButton data={data} title={title} isShow={isExport} />
        <InfoButton
          timeUpdate={timeUpdate}
          graphInfo={graphInfo}
          chartType={radioProps.graphValues.graphTitle}
        />
      </div>
    </div>
  );
};

export default ButtonsPanel;
