import { Button } from 'antd';
import { FormInstance } from 'antd/lib';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import rootStore from '../../../../../../stores/rootStore/rootStore';
import { SourceFormFields } from '../../../models/DeviceAdmin.model';
import { BUTTON_TEXT } from '../constants/constants';

import useConfirm from './hooks/useConfirm';

import styles from './ConfirmButtons.module.scss';

interface ConfirmButtonsProps {
  form: FormInstance;
  initialProps: U<SourceFormFields>;
}

const ConfirmButtons: FC<ConfirmButtonsProps> = ({ form, initialProps }) => {
  const { sourceDisplayType } = rootStore.adminPanelStore;

  const { handleSubmit, isDisabledConfirm, isLoading } = useConfirm(
    form,
    initialProps
  );

  if (!sourceDisplayType) return null;

  return (
    <div className={styles.btns}>
      <Button
        type="primary"
        onClick={handleSubmit}
        disabled={isDisabledConfirm}
        loading={isLoading}
      >
        {BUTTON_TEXT[sourceDisplayType]}
      </Button>
    </div>
  );
};

export default observer(ConfirmButtons);
