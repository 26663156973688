import { useQuery } from '@tanstack/react-query';

import api from '../../../../../../../api';
import {
  DEFAULT_STALE_TIME,
  DEFAULT_UPDATE_DEVICE_ADMIN,
  QUERY_KEY,
} from '../../../../../../../constants/queryConstants';
import rootStore from '../../../../../../../stores/rootStore/rootStore';
import getSystemTypesOptions from '../helpers/getSystemTypesOptions';

const APP_PATH =
  'Admin.DeviceAdmin.SourceEdit.SourceForm.SystemTypesSelect.hooks.useSystemTypes';

const { SOURCE_TYPES } = QUERY_KEY;
const { fetchSupportedSystemTypesLibrary } = api.devices.dict.devicesDictApi;

const useSystemTypes = () => {
  const { selectedDevice } = rootStore.adminPanelStore;

  const { data: options, isFetching } = useQuery({
    queryKey: [SOURCE_TYPES, selectedDevice],
    queryFn: async () => {
      if (!selectedDevice) return null;

      const supportedSystems =
        (await fetchSupportedSystemTypesLibrary(
          selectedDevice.deviceTypeId,
          APP_PATH
        )) ?? null;

      return getSystemTypesOptions(supportedSystems);
    },
    refetchInterval: DEFAULT_UPDATE_DEVICE_ADMIN,
    staleTime: DEFAULT_STALE_TIME,
    enabled: !!selectedDevice,
  });

  return { options, isLoading: isFetching };
};

export default useSystemTypes;
