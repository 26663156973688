import { useState } from 'react';

import BackArrow from '../../ui-kit/BackArrow/BackArrow';
import { AuthMethod, LOGIN_FORM_CONTENT } from '../constants/loginConstants';
import LoginChoice from '../LoginChoice/LoginChoice';

import styles from './LoginBlock.module.scss';

const LoginBlock = () => {
  const [authMethod, setAuthMethod] = useState<N<AuthMethod>>(null);

  if (!authMethod) return <LoginChoice setAuthMethod={setAuthMethod} />;

  const LoginForm = LOGIN_FORM_CONTENT[authMethod];

  return (
    <div className={styles.container}>
      <div className={styles.block}>
        <BackArrow handleClick={() => setAuthMethod(null)} />
        <LoginForm />
      </div>
    </div>
  );
};

export default LoginBlock;
