export enum EventBusEvents {
  VideoListUpdate = 'videoListUpdate',
  TablePaginationChange = 'tablePaginationChange',
  TokensUpdateComplete = 'tokensUpdateComplete',
  UserUpdate = 'userUpdate',
  IncorrectTimeTrigger = 'incorrectTimeTrigger',
  PhaseHoldChange = 'phaseHoldChange',
  UpdateFeatures = 'updateFeatures',
  ClickedControlBtn = 'clickedControlBtn',
  UpdateFormDevice = 'updateFormDevice',
  UpdateSourcesList = 'updateSourcesList',
  UpdateSourceItem = 'updateSourceItem',
  UpdateDeviceItem = 'updateDeviceItem',
}
