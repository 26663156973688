import { isNumber, isString } from 'lodash';

import { DictDeviceKeys } from '../../../../../../../../api/devices/constants/constants';
import { DEVICE_TYPE_IDS } from '../../../../../../../../constants/deviceConstants';
import sortByStr from '../../../../../../../../helpers/sortByStr';
import rootStore from '../../../../../../../../stores/rootStore/rootStore';

const { TypesList } = DictDeviceKeys;

const getTypesOptions = () => {
  const typesListDict = rootStore.uiStore.dictionaries[TypesList];

  const options = typesListDict
    .map(({ id, dsc }) => ({
      value: id,
      label: dsc,
      disabled: DEVICE_TYPE_IDS.every((el) => el !== id),
    }))
    .filter((el) => isNumber(el.value) && isString(el.label));

  return sortByStr(options, 'value');
};

export default getTypesOptions;
