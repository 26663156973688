import { Tag } from 'antd';
import { FC } from 'react';

import { TDeviceStatus } from '../../../../../../../constants/devicesConstants';
import { getDeviceColor } from '../../../../../../MapBtns/MapInfoWrapper/InfoBtn/ContentInfo/helpers/getMapInfo';
import { getStatusDevice } from '../../../../../../Mapper/TooltipContent/MapIconContent/ModeInfo/helpers/getStatusInfo';

import styles from './PopoverContent.module.scss';

interface PopoverContentProps {
  statusDevice: TDeviceStatus;
  caption: string;
}

const PopoverContent: FC<PopoverContentProps> = ({ statusDevice, caption }) => {
  const statusName = getStatusDevice(statusDevice).dsc;
  const color = getDeviceColor(statusDevice);

  return (
    <div className={styles.container}>
      <h4>{caption}</h4>

      {statusName && color ? (
        <div className={styles.status}>
          <p>Статус: </p>
          <Tag color={color}>{statusName}</Tag>
        </div>
      ) : null}
    </div>
  );
};

export default PopoverContent;
