import { getInitialBasemapSettings } from './helpers';

export const PLACEMENT_ITEM = 'bottom';

export const LOCAL_STYLES = {
  panelBodyStyle: {
    margin: 0,
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 5,
    paddingBottom: 5,
  },
  borderRadius: 6,
  emptyCard: { width: 96, height: 96 },
};

export const BASEMAPS_CONTENT = {
  content: 'Картографические подложки',
  contentDisabled:
    'Для выбранного региона картографические подложки временно недоступны',
  title: 'Подложки',
  loading: 'загрузка ...',
  loadingPopover: 'Загрузка подложек по региону',
};

export const MAX_SUBTITLE_LENGTH = 10;

export const BASEMAP_SETTINGS_ITEMS = [
  { text: 'Контраст', property: 'contrast', min: 0, max: 300, step: 1 },
  { text: 'Яркость', property: 'brightness', min: 0, max: 300, step: 1 },
  { text: 'Насыщенность', property: 'saturate', min: 0, max: 200, step: 1 },
  { text: 'Цветовой сдвиг', property: 'hueRotate', min: 0, max: 360, step: 1 },
  { text: 'Размытие', property: 'blur', min: 0, max: 50, step: 1 },
  { text: 'Инверсия', property: 'invert', min: 0, max: 100, step: 1 },
  { text: 'Сепия', property: 'sepia', min: 0, max: 100, step: 1 },
];

const BASEMAP_SETTINGS_DEFAULT = getInitialBasemapSettings();
const BASEMAP_SETTINGS_GREY = {
  ...BASEMAP_SETTINGS_DEFAULT,
  grayscale: 100,
  saturate: 0,
};
const BASEMAP_SETTINGS_CONTRAST = {
  ...BASEMAP_SETTINGS_DEFAULT,
  contrast: 150,
};
const BASEMAP_SETTINGS_BACKGROUND = {
  ...BASEMAP_SETTINGS_DEFAULT,
  contrast: 60,
  brightness: 110,
  saturate: 25,
  sepia: 5,
};
const BASEMAP_SETTINGS_SATURATE = {
  ...BASEMAP_SETTINGS_DEFAULT,
  contrast: 100,
  brightness: 100,
  saturate: 120,
};
const BASEMAP_SETTINGS_BLUR = {
  ...BASEMAP_SETTINGS_DEFAULT,
  blur: 3,
};

export const BASEMAP_SETTINGS_PREDEFINED_ITEMS = [
  { text: 'сброс', value: BASEMAP_SETTINGS_DEFAULT },
  { text: 'серый', value: BASEMAP_SETTINGS_GREY },
  { text: 'контраст', value: BASEMAP_SETTINGS_CONTRAST },
  { text: 'фон', value: BASEMAP_SETTINGS_BACKGROUND },
  { text: 'сочный', value: BASEMAP_SETTINGS_SATURATE },
  { text: 'размытие', value: BASEMAP_SETTINGS_BLUR },
];
