export type NotifyTextKeys = keyof typeof NOTICE;

export const isNoticeKey = (key: unknown): key is NotifyTextKeys =>
  Object.keys(NOTICE).some((el) => el === key);

export const INCORRECT_DATA_TYPE =
  'Получен некорректный тип данных. Возможно, функционал будет доступен не полностью. Мы уже работаем над решением проблемы.';

class Notice {
  COMPLETE = 'Выполнено';
  ERROR = 'Ошибка';
  ATTENTION = 'Внимание!';
  SESSION_DESTROY = 'Сессия прекращена';
  BLOCKED_ACCESS = 'Доступ заблокирован';
  SERVER_ERROR = 'Ошибка сервера';
  DELETED_SUCCESSFULLY = 'Удаление прошло успешно';
  DELETED_PARTLY = 'Удаление выполнено частично';
  SUCCESS_CONFIG_UPDATE = 'Доступ успешно изменен';
  INCORRECT_DATA_TYPE = INCORRECT_DATA_TYPE;
  ERROR_TLS_FETCHING = 'Не удалось получить полные данные светофорных объектов';
  UP_PROFILE = 'Профиль успешно обновлен';
  NOT_SAVE = 'Нельзя сохранить пустой профиль';
  ADD_PROFILE = 'Профиль успешно сохранен';
  JSON_INVALID = 'JSON строка не валидна';
  TEXT_COPY = 'Текст скопирован';
  FILE_UPLOAD_SUCCESS = 'Файл успешно загружен';
  FILE_DUPLICATE = 'Файл с таким именем уже существует';
  FILE_NOT_SELECTED = 'Не выбран файл для загрузки';
  FILE_WITH_INCORRECT_FORMAT = 'Некорректный формат файла';
  FILE_TOО_BIG = 'Размер файла превышает допустимое значение';
  FILE_IS_NOT_CORRECT_JSON =
    'Выбран некорректный формат файла. К загрузке принимается только json c определенной структурой.';
  DIVISION_DATA_UPDATE = 'Данные подразделения обновлены';
  DIVISION_DATA_ADD = 'Подразделение успешно добавлено';
  UNCORRECT_FORM = 'Проверьте правильность заполнения формы';
  ORGANIZATION_ADD = 'Организация добавлена';
  ORGANIZATION_UPDATE = 'Организация обновлена';
  UP_DEVICE = 'Данные устройства обновлены';
  ADD_DEVICE = 'Устройство успешно добавлено в систему ЕПУТС';
  DELETE_DEVICE = 'Устройство успешно удалено';
  DELETE_DEVICE_ERROR = 'Ошибка удаления устройства';
  DEVICE_INFO_ERROR = 'Ошибка получения информации по устройству';
  LIST_DEVICE_ERROR = 'Ошибка получения списка устройств';
  EXTERNAL_SOURCE_ADD = 'Источник успешно добавлен';
  EXTERNAL_SOURCE_UPDATE = 'Источник успешно обновлен';
  EXTERNAL_SOURCE_DELETE = 'Источник успешно удален';
  EXTERNAL_SOURCE_DELETE_ERROR = 'Ошибка удаления источника';
  FORM_ERROR = 'Ошибка обработки полей формы';
  EXTERNAL_LINKS_ERROR = 'Ошибка получения источников устройства';
  EXTERNAL_LINK_ERROR = 'Ошибка получения информации по источнику';
  SYSTEMS_LIST_ERROR = 'Ошибка получения списка систем';
  SUBSYSTEMS_LIST_ERROR = 'Ошибка получения списка типов систем';
  SEARCH_DEVICE_ERROR =
    'Для поиска обьекта по карте вы должны находиться в том же регионе';
  FILL_DELETE_REASON = 'Заполните причину удаления';
  PLANNED_CMD_ERROR = 'Ошибка получения запланированных команд';
  CREATE_GROUP_ERROR = 'Ошибка создания группы координации';
  GET_COORD_GROUP_ERROR =
    'Ошибка получения доступных СО для создания групп координации';
  ERROR_GROUPS_LIST = 'Ошибка получения групп координации';
  SUCCESS_GROUPS_LIST = 'Список групп успешно получен';
  SUCCESS_TLS_GROUPS_LIST = 'Список СО доступных для создания групп КУ получен';
  SUCCESS_GROUP_CREATE = 'Группа КУ успешно создана';
  SUCCESS_START_GROUP = 'Группа КУ успешно запущена';
  SUCCESS_STOP_GROUP = 'Группа КУ успешно остановлена';
  VALIDATION_ERROR_ADAPTERS = 'Некоторые адаптеры содержат ошибку валидации!';
  GIS_LAYERS_ERROR = `${this.ERROR} получения слоев ГИС`;
  PREDICT_GRAPH_DATA = `${this.ERROR} получения прогнозируемых данных с ДТ`;
  SUCCESS_GROUP_UPDATE = 'Группа успешно изменена';

  AUTH_DEADLINE = {
    message: 'Пожалуйста войдите в аккаунт',
    description: 'Срок действия авторизации истёк',
  };
  IMPORT_ERROR = {
    message: 'Ошибка импорта',
    description: 'Неверный формат файла',
  };
  EXPORT_ERROR = {
    message: 'Ошибка экспорта',
    description: 'Нельзя экспортировать пустую таблицу',
  };
  EXPORT_SUCCESS = {
    message: 'Экспорт выполнен',
    description: 'Данные из таблицы успешно экспортированы в файл',
  };
  LOGIN_SUCCESSFUL = {
    message: 'Вход выполнен',
    description: 'Вы успешно вошли в аккаунт',
  };
  SETTINGS_SAVED = {
    message: 'Настройки успешно сохранены.',
    description: 'Они вступят в силу после перезагрузки страницы.',
  };
  SETTINGS_ERROR = {
    message: 'Ошибка сохранения настройки',
    description: 'Настройка не была сохранена удалённо',
  };
  LOAD_CONSTRUCTOR_PROFILE = {
    message: 'Удаление не произведено',
    description:
      'Необходимо выбрать набор данных в выпадающем списке вверху панели.',
  };
  NOTHING_TO_SAVE = {
    message: 'Не сохранено',
    description: 'Вы не внесли никаких изменений для сохранения.',
  };
  JSON_ERROR = {
    message: 'Ошибка в JSON файле',
    description: 'Неправильный JSON файл',
  };
  CREATE_USER = {
    message: this.COMPLETE,
    description: 'Пользователь успешно создан',
  };
  DELETE_USER = {
    message: 'Успешно',
    description: 'Пользователь успешно удален',
  };
  CREATE_USER_ERROR = {
    message: 'Ошибка создания пользователя',
    description: 'Проверьте правильность заполнения полей формы.',
  };
  USER_UPDATE = {
    message: 'Успешно',
    description: 'Данные пользователя успешно обновлены',
  };
  DATE_ERROR_PLAN = {
    message: 'Некорректная дата',
    description:
      'Включение режима выбора плана доступно не раньше, чем через 2 минуты',
    duration: 5,
  };
  DETECTORS_DATA = {
    message: 'Некорректные данные',
    description:
      'Данные по детекторам некорректные, возможно неправильное отображение графика',
  };
  SYSTEM_OBJECT_UPDATE = {
    message: this.COMPLETE,
    description: 'Настройки системы успешно сохранены',
  };
  SYSTEM_DATA_UPDATE = {
    message: this.COMPLETE,
    description: 'Данные системы успешно обновлены',
  };
  USER_ACCESS_ERROR = {
    message: 'Отсутствует доступ',
    description:
      'Для данного пользователя отсутствуют разрешения на доступ к элементам интерфейса. Пожалуйста, обратитесь к администратору.',
  };
  SYSTEM_GROUPS_ERROR = {
    message: 'Ошибка сервера',
    description: 'Группы доступа системы недоступны.',
  };
  DEL_PROFILE = {
    message: this.COMPLETE,
    description: 'Профиль успешно удален',
  };
  DEL_PROFILE_ER = {
    message: 'В доступе отказано',
    description: 'Общедоступные профили может удалять только администратор',
  };
  ERR_ADD_DEVICE = {
    message: this.ERROR,
    description: 'Ошибка добавления в систему ЕПУТС',
  };
  INCORRECT_DATA_TYPES = {
    message: this.ERROR,
    description: 'Получен некорректный тип данных. Данные не будут отображены.',
  };
  INCORRECT_TYPES_FLEX = {
    message: this.ERROR,
    description: INCORRECT_DATA_TYPE,
  };
  INCORRECT_COMMAND = {
    message: this.ERROR,
    description: 'Отправленная команда не найдена.',
  };
  INCORRECT_DATE = {
    message: this.ERROR,
    description: 'Выбранное время не может быть позже текущего времени',
  };
  NEW_KEY = {
    message: 'Предупреждение',
    description: 'Получен новый ключ, неописанный на WEB',
  };
  INCORRECT_COMPLETED_FORMS = {
    message: this.ERROR,
    description: 'Неправильно заполненные поля формы',
  };
  CONSTRUCTOR_PHASE_ERROR = {
    message: 'Ошибка изменения профиля',
    description: 'Попробуйте выбрать корректную фазу.',
  };
  USER_AVALIBLE_REGIONS_UNDEFINED = {
    message: 'Ошибка получения списка доступных регионов',
    description: 'У пользователя нет доступа к регионам',
  };
  REGIONS_LOADING_ERROR = {
    message: 'Ошибка загрузки списка доступных регионов',
    description:
      'При загрузки списка регионов один или несколько были загружены с ошибкой',
  };
  OUTDATED_PROFILE = {
    message: 'Ошибка загрузки профиля',
    description:
      'Профиль использует устаревшую версию данных и не может быть загружен.',
  };
  DELETE_SYSTEM = {
    message: this.COMPLETE,
    description: 'Система удалена',
  };
  INCORRECT_POINT_UDS_LIST = {
    message: 'Ошибка в списке точек УДС',
    description: INCORRECT_DATA_TYPE,
  };
  REMOVE_POINT_UDS = {
    message: this.COMPLETE,
    description: 'Точка УДС удалена, т.к. в ней не осталось профилей',
  };
  INCORRECT_COORD_GROUPS_LIST = {
    message: 'Ошибка в списке групп координированного управления',
    description: INCORRECT_DATA_TYPE,
  };
  INCORRECT_PROFILES_LIST = {
    message: 'Некорректный список профилей',
    description: INCORRECT_DATA_TYPE,
  };
  INCORRECT_PROFILE_DATA = {
    message: 'Некорректные данные профиля',
    description: `Получены некорректный формат профиля. Возможно профиль устарел или поврежден, 
      что приведет к ошибкам в работе. Попробуйте нажать кнопку обновить профиль 
      до последней версии и потом сохранить.`,
  };
  PROFILE_WITHOUT_DEVICE = {
    message: 'Сохранение невозможно',
    description: 'К профилю необходимо добавить хотя бы одно устройство.',
  };
  INCORRECT_REPORTS_TREE = {
    message: 'Некорректный список отчётов',
    description: INCORRECT_DATA_TYPE,
  };
  INCORRECT_DEVICE_DATA = {
    message: this.ERROR,
    description:
      'Получены некорректные данные устройства. Перенос не осуществлен.',
  };
}

const NOTICE = new Notice();

export default NOTICE;
