import dayjs from 'dayjs';

import { getTimeWithTimeZone } from '../../components/TrafficLightDetailed/TlDetectors/IntancyGraph/TitleBtns/TimeLocalCard/helpers/getTimeWithTimeZone';

export const setDisableByDate = (
  startDate: N<dayjs.Dayjs>,
  isNeedNowDisabled = true
) => {
  return (currentDate: dayjs.Dayjs) => {
    const dateDiff = currentDate.diff(startDate, 'days') < 0;
    const dateNowDiff =
      currentDate.diff(
        getTimeWithTimeZone().dateFormattedJS.add(-1, 'day'),
        'days'
      ) < 0;

    return dateDiff || (isNeedNowDisabled && dateNowDiff);
  };
};
