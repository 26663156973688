import { getAppPathHeaders } from '../../helpers/getAppPathHeaders';
import http from '../../http';
import tryCatchWrapper from '../../tryCatchWrapper';
import { SYSTEMS } from '../constants/constants';

import { adapter } from './adapter/adapter';
import cameras from './cameras';
import devices from './devices';
import { SystemsList } from './models/systems.model';
import settings from './settings';

export interface SystemData {
  id: number;
  host: N<string>;
  name: string;
  regionsIds: number[];
  description: string;
  scSystemTypeId: number;
  scSystemStatusId: number;
}

export interface AddSystemBody {
  name: string;
  scSystemTypeId: number;
  dsc: string;
  regionsId: number[];
}

interface SystemUpdateBody {
  name: string;
  description: string;
  scSystemStatusId: number;
}

const systemsCrud = {
  cameras,
  devices,
  settings,
  adapter,
  getSystemsTree: (appPath: string) =>
    tryCatchWrapper(async () => {
      const res = await http.get(SYSTEMS, {
        headers: getAppPathHeaders(appPath),
      });

      return res;
    }),
  getItem: (systemId: number, appPath: string) =>
    tryCatchWrapper(async () => {
      const res = await http.get(SYSTEMS + '/' + systemId, {
        headers: getAppPathHeaders(appPath),
      });

      const data: SystemData = res.data;

      return data;
    }),
  fetchSystemsList: (systemTypeId: number, regionId: number, appPath: string) =>
    tryCatchWrapper(
      async () => {
        const res = await http.get<SystemsList[]>(`${SYSTEMS}/list`, {
          headers: getAppPathHeaders(appPath),
          params: {
            systemTypeId,
            regionId,
          },
        });

        return res.data;
      },
      {
        errorMessage: 'SYSTEMS_LIST_ERROR',
      }
    ),
  add: (body: AddSystemBody, appPath: string) =>
    tryCatchWrapper(async () => {
      const res = await http.post(SYSTEMS, body, {
        headers: getAppPathHeaders(appPath),
      });

      return res;
    }),
  update: (systemId: number, body: SystemUpdateBody, appPath: string) =>
    tryCatchWrapper(async () => {
      const res = await http.put(SYSTEMS + '/' + systemId, body, {
        headers: getAppPathHeaders(appPath),
      });

      return res;
    }),
  deleteSystem: (id: number, appPath: string) =>
    tryCatchWrapper(async () => {
      const res = await http.delete(SYSTEMS + `/${id}`, {
        headers: getAppPathHeaders(appPath),
      });

      return res;
    }),
};

export default systemsCrud;
