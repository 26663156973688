import { useEffect } from 'react';

import rootStore from '../../../../../../stores/rootStore/rootStore';
import { DEFAULT_FILTERED } from '../../../../../TrafficLightDetailed/TlDetectors/constants/constants';
import { FilterInfo } from '../../../models/chart.model';

interface UseFilterButtonsProps {
  filterInfo: FilterInfo[];
}

const useFilterButtons = ({ filterInfo }: UseFilterButtonsProps) => {
  const { filterDtData, setFilteredDtData } = rootStore.detailedStore;

  //change filter type if disabled
  useEffect(() => {
    filterInfo.forEach(({ filterType, data }) =>
      data.forEach(({ type, disabled }) => {
        disabled &&
          filterDtData[filterType]?.some((el) => el === type) &&
          setFilteredDtData({ filteredType: filterType, value: type });
      })
    );
  }, [filterDtData, filterInfo, setFilteredDtData]);

  //change filter to default if its not found
  useEffect(() => {
    filterInfo.forEach(({ filterType, data }) => {
      const isNeedChange =
        !!data.length &&
        data.every(
          ({ type }) =>
            filterDtData[filterType] &&
            !filterDtData[filterType]?.includes(type)
        );

      isNeedChange &&
        setFilteredDtData({
          filteredType: filterType,
          value: DEFAULT_FILTERED[filterType],
        });
    });
  }, [filterDtData, filterInfo, setFilteredDtData]);

  return null;
};

export default useFilterButtons;
