import { Form, Select } from 'antd';
import { type Rule } from 'antd/es/form';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import { FormSourceKeys } from '../constants/constants';

import getStatusOptions from './helpers/getStatusOptions';

const { StatusId } = FormSourceKeys;

interface StatusSelectProps {
  getSourceRules: (key: FormSourceKeys) => U<Rule[]>;
}

const StatusSelect: FC<StatusSelectProps> = ({ getSourceRules }) => {
  const options = getStatusOptions();

  return (
    <Form.Item label="Статус" name={StatusId} rules={getSourceRules(StatusId)}>
      <Select
        placeholder="Выберите статус источника"
        options={options ?? undefined}
        allowClear
      />
    </Form.Item>
  );
};

export default observer(StatusSelect);
