import { Form } from 'antd';
import { type Rule } from 'antd/es/form';
import { FC } from 'react';

import DatePicker from '../../../../../ui-kit/Picker/DatePicker/DatePicker';
import { HandleChangeDate } from '../../../models/DeviceAdmin.model';
import { FormSourceKeys } from '../constants/constants';

const { LifetimeFrom } = FormSourceKeys;

interface LifeTimeFromPickerProps {
  getSourceRules: (key: FormSourceKeys) => U<Rule[]>;
  handleChangeDate: HandleChangeDate;
}

const LifeTimeFromPicker: FC<LifeTimeFromPickerProps> = ({
  getSourceRules,
  handleChangeDate,
}) => {
  return (
    <Form.Item
      label={'Время жизни с'}
      name={LifetimeFrom}
      rules={getSourceRules(LifetimeFrom)}
    >
      <DatePicker
        disabledDateOptions={{ countFeature: null }}
        isTime
        showNow={false}
        allowClear
        onChangeDate={(value) => handleChangeDate(value, LifetimeFrom)}
      />
    </Form.Item>
  );
};

export default LifeTimeFromPicker;
