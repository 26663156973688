import { FC } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import DtVideoNoData from './Fallback/DtVideoNoData';
import VideoPreview from './VideoPreview/VideoPreview';

import styles from './DtVideoPreview.module.scss';

interface DtVideoPreviewProps {
  id: N<number>;
  isNoImageDt: boolean;
}

const DtVideoPreview: FC<DtVideoPreviewProps> = ({ id, isNoImageDt }) => {
  return (
    <div className={styles.container}>
      <ErrorBoundary FallbackComponent={DtVideoNoData} resetKeys={[id]}>
        <VideoPreview id={id} isNoImageDt={isNoImageDt} />
      </ErrorBoundary>
    </div>
  );
};

export default DtVideoPreview;
