import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import { FilterInfo } from '../../models/chart.model';

import FilterGroup from './FilterGroup/FilterGroup';
import useFilterButtons from './hooks/useFilterButtons';

import styles from './FilterButtons.module.scss';

interface FilterButtonsProps {
  filterInfo: FilterInfo[];
  isLoading: boolean;
}

const FilterButtons: FC<FilterButtonsProps> = ({ filterInfo, isLoading }) => {
  useFilterButtons({ filterInfo });

  return (
    <div className={styles.container}>
      {filterInfo.map((el) => {
        return (
          <FilterGroup key={el.filterType} {...el} isLoading={isLoading} />
        );
      })}
    </div>
  );
};

export default observer(FilterButtons);
