import { Form } from 'antd';
import { type Rule } from 'antd/es/form';
import { FormInstance } from 'antd/lib';
import { FC } from 'react';

import { setDisableByDate } from '../../../../../../helpers/dateHelpers/setDisableByDate';
import getDisableTime from '../../../../../RegisterUserForm/SettingUpAccessDate/helpers/getDisableTime';
import DatePicker from '../../../../../ui-kit/Picker/DatePicker/DatePicker';
import {
  HandleChangeDate,
  SourceFormFields,
} from '../../../models/DeviceAdmin.model';
import { FormSourceKeys } from '../constants/constants';

const { LifetimeTo } = FormSourceKeys;

interface LifeTimeToPickerProps {
  form: FormInstance<SourceFormFields>;
  getSourceRules: (key: FormSourceKeys) => U<Rule[]>;
  handleChangeDate: HandleChangeDate;
}

const LifeTimeToPicker: FC<LifeTimeToPickerProps> = ({
  form,
  getSourceRules,
  handleChangeDate,
}) => {
  const values = Form.useWatch([], form);

  return (
    <Form.Item
      label={'Время жизни до'}
      name={LifetimeTo}
      rules={getSourceRules(LifetimeTo)}
    >
      <DatePicker
        isTime
        showNow={false}
        allowClear
        handleDisableDate={setDisableByDate(
          values?.lifetime_from ?? null,
          false
        )}
        handleDisableTime={(value) =>
          getDisableTime(value, values?.lifetime_from)
        }
        disabled={!values?.lifetime_from}
        onChangeDate={(value) => handleChangeDate(value, LifetimeTo)}
      />
    </Form.Item>
  );
};

export default LifeTimeToPicker;
