import dayjs from 'dayjs';

import { ExternalLink } from '../../../../../../api/devices/model/devices.model';
import { DB_DATE_FORMAT } from '../../../../../../constants/constants';
import { SourceFormFields } from '../../../models/DeviceAdmin.model';

interface SourceInfo {
  sourceId: number;
  source: SourceFormFields;
}

const transformDateToDayjs = (date: N<string>) => {
  if (!date) return null;

  const [newDate] = date.split('.');

  return dayjs(newDate, DB_DATE_FORMAT);
};

const transformSourceData = (source: U<ExternalLink>): N<SourceInfo> => {
  if (!source) return null;

  const { sname, dsc, status_id, lifetime_to, lifetime_from, id } = source;

  return {
    source: {
      sname,
      dsc,
      status_id,
      lifetime_from: transformDateToDayjs(lifetime_from),
      lifetime_to: transformDateToDayjs(lifetime_to),
      deviceInfo: undefined,
      systemId: undefined,
      systemTypeId: undefined,
    },
    sourceId: id,
  };
};

export default transformSourceData;
