import { isNumber } from 'lodash';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';

import checkIncorrectCoordinates from '../../../../../helpers/mapHelpers/checkIncorrectCoordinates';
import { TMap } from '../../../../../stores/mapStore/mapStore.model';
import rootStore from '../../../../../stores/rootStore/rootStore';
import InfoBtn from '../../../../TrafficLightDetailed/TlDetectors/GeneralDtTabs/CrossroadWrapper/InfoBtn/InfoBtn';
import MapWrapper from '../../../../TrafficLightDetailed/TlDetectors/GeneralDtTabs/CrossroadWrapper/MapWrapper/MapWrapper';

import CrossroadSchema from './CrossroadSchema/CrossroadSchema';
import getDeviceInfo from './helpers/getDeviceInfo';
import useMapHandlers from './hooks/useMapHandlers';
import VectorLayer from './VectorLayer/VectorLayer';

import styles from './Mapper.module.scss';

const Mapper = () => {
  const { deviceInfo, setMapPropsDetailed } = rootStore.monitoringPanelStore;

  const [map, setMap] = useState<TMap>(null);

  const handleMapClick = useMapHandlers(map);

  const info = getDeviceInfo(deviceInfo?.id, deviceInfo?.system);

  const id = deviceInfo?.id;

  const isIncorrectCoordinates = isNumber(id) && checkIncorrectCoordinates(id);

  if (!deviceInfo || !info) return null;

  const { coord, tlId, udsInfo } = info;

  return (
    <div className={styles.container}>
      <MapWrapper
        map={map}
        setMap={setMap}
        setMapProps={setMapPropsDetailed}
        centerCoord={coord}
        handleMapClick={handleMapClick}
        isEmptyData={isIncorrectCoordinates}
      />
      <CrossroadSchema map={map} tlId={tlId} deviceInfo={deviceInfo} />
      <VectorLayer map={map} deviceInfo={deviceInfo} />
      <InfoBtn pointUdsInfo={udsInfo} />
    </div>
  );
};

export default observer(Mapper);
