import { Card } from 'antd';
import { TooltipPlacement } from 'antd/lib/tooltip';

import Popover from '../../ui-kit/Popover/Popover';

import { BASEMAPS_CONTENT, LOCAL_STYLES } from './constants';

import styles from './BasemapsButton.module.scss';

const LoadingBaseMapButton = (props: {
  popOverItemProps: {
    readonly tipsDelay: any;
    readonly placement: TooltipPlacement | undefined;
  };
}) => (
  <div className={styles.baseMapItemContainer}>
    <Popover
      content={BASEMAPS_CONTENT.loadingPopover}
      {...props.popOverItemProps}
    >
      <Card
        loading={true}
        hoverable
        className={styles.baseMapItemCard || styles.baseMapItemImgSelected}
        style={LOCAL_STYLES.emptyCard}
      ></Card>
      <div style={{ textAlign: 'center' }}>{BASEMAPS_CONTENT.loading}</div>
    </Popover>
  </div>
);

export default LoadingBaseMapButton;
