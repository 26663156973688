import { Button } from 'antd';
import { observer } from 'mobx-react-lite';

import { DeviceFormType } from '../../../../../stores/adminPanelStore/constants/constants';
import rootStore from '../../../../../stores/rootStore/rootStore';

import SelectComponent from './SelectComponent/SelectComponent';

import styles from './SelectDevice.module.scss';

const { AddForm } = DeviceFormType;

const SelectDevice = () => {
  const { setAdminsData } = rootStore.adminPanelStore;

  const handleClick = () => setAdminsData('deviceFormType', AddForm);

  return (
    <div className={styles.container}>
      <div className={styles.block}>
        <div className={styles.select}>
          <SelectComponent />
        </div>
        <p className={styles.text}>или</p>
        <Button onClick={handleClick} type="primary">
          Добавить устройство
        </Button>
      </div>
    </div>
  );
};

export default observer(SelectDevice);
