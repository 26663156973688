/* eslint-disable camelcase */
import { z } from 'zod';

import { NNInt } from '../../zod/utils.zod';
import { BasicMapDataSchema } from '../mapObjects.zod';

export const FinalFromProfilesSchema = z.object({
  id: NNInt.optional(),
  angle: NNInt,
  offsetX: z.number(),
  offsetY: z.number(),
  size: NNInt,
  color: z.string(),
  distance: NNInt,
  widthRate: z.number(),
  arcHeight: NNInt,
  preDistanceRate: z.number(),
  opacity: z.number(),
  linkedDetectorId: NNInt.nullable(),
  linkColor: z.string().nullable().optional(),
});

export const CmDaiJdt83Schema = FinalFromProfilesSchema.extend({});

export const CameraSchema = BasicMapDataSchema.extend({
  regionId: z.number(),
  srcId: z.string(),
  finalFromProfiles: FinalFromProfilesSchema.nullable().optional(),
  latitude: z.number(),
  longitude: z.number(),
  dai_jdt83: CmDaiJdt83Schema.nullable(),
});
