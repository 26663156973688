/* eslint-disable jsx-a11y/no-autofocus */
import { Form, Input } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { FC } from 'react';

import { SourceFormFields } from '../../models/DeviceAdmin.model';

import ConfirmButtons from './ConfirmButtons/ConfirmButtons';
import { FormSourceKeys } from './constants/constants';
import ExternalDevicesSelect from './ExternalDevicesSelect/ExternalDevicesSelect';
import useSourceForm from './hooks/useSourceForm';
import LifeTimeFromPicker from './LifeTimeFromPicker/LifeTimeFromPicker';
import LifeTimeToPicker from './LifeTimeToPicker/LifeTimeToPicker';
import StatusSelect from './StatusSelect/StatusSelect';
import SystemsSelect from './SystemsSelect/SystemsSelect';
import SystemTypesSelect from './SystemTypesSelect/SystemTypesSelect';

import styles from './SourceForm.module.scss';

const { Sname, Dsc } = FormSourceKeys;

interface SourceFormProps {
  initialProps?: SourceFormFields;
}

const SourceForm: FC<SourceFormProps> = ({ initialProps }) => {
  const {
    form,
    isEditForm,
    getSourceRules,
    handleChangeDate,
    handleChangeSelect,
  } = useSourceForm(initialProps);

  const selectProps = {
    form,
    isShow: !isEditForm,
    getSourceRules,
    handleChangeSelect,
  };

  return (
    <div className={styles.wrapper}>
      <Form form={form} layout="vertical" initialValues={initialProps}>
        <SystemTypesSelect {...selectProps} />
        <SystemsSelect {...selectProps} />
        <ExternalDevicesSelect {...selectProps} />
        {isEditForm ? (
          <Form.Item label="Имя" name={Sname} rules={getSourceRules(Sname)}>
            <Input autoFocus placeholder="Введите имя источника" allowClear />
          </Form.Item>
        ) : null}
        <Form.Item label="Описание" name={Dsc} rules={getSourceRules(Dsc)}>
          <TextArea
            rows={4}
            placeholder="Введите описание источника"
            allowClear
          />
        </Form.Item>
        <StatusSelect getSourceRules={getSourceRules} />
        <LifeTimeFromPicker
          getSourceRules={getSourceRules}
          handleChangeDate={handleChangeDate}
        />
        <LifeTimeToPicker
          form={form}
          getSourceRules={getSourceRules}
          handleChangeDate={handleChangeDate}
        />
        <ConfirmButtons form={form} initialProps={initialProps} />
      </Form>
    </div>
  );
};

export default SourceForm;
