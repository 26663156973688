import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';

import api from '../../../../../../../api';
import {
  DEFAULT_STALE_TIME,
  DEFAULT_UPDATE_DEVICE_ADMIN,
  QUERY_KEY,
} from '../../../../../../../constants/queryConstants';
import eventBus from '../../../../../../../eventBus';
import rootStore from '../../../../../../../stores/rootStore/rootStore';
import transformToFormData from '../helpers/transformToFormData';

const { DEVICE_FORM_INFO } = QUERY_KEY;

const { fetchDeviceInfoById } = api.devices.info.deviceInfoApi;

const APP_PATH =
  'Admin.DeviceAdmin.DeviceAdminCard.DeviceFormContent.DeviceEditCard.hooks.useDeviceInfo';

const useDeviceInfo = () => {
  const { selectedDevice } = rootStore.adminPanelStore;

  const {
    data: device,
    isLoading,
    isError,
    refetch,
  } = useQuery({
    queryKey: [DEVICE_FORM_INFO, selectedDevice],
    queryFn: async () => {
      if (!selectedDevice) return null;

      const res = await fetchDeviceInfoById(selectedDevice.id, APP_PATH);

      return transformToFormData(res);
    },
    refetchInterval: DEFAULT_UPDATE_DEVICE_ADMIN,
    staleTime: DEFAULT_STALE_TIME,
    enabled: !!selectedDevice,
  });

  useEffect(() => {
    const unsubscribe = eventBus.updateDeviceItem.subscribe(refetch);

    return () => {
      unsubscribe();
    };
  }, [refetch]);

  return { device, isLoading, isError: isError || !device };
};

export default useDeviceInfo;
