import { DeviceFormType } from '../../../../../../stores/adminPanelStore/constants/constants';
import { RulesItems } from '../../../models/DeviceAdmin.model';

export enum FormKeys {
  SName = 'sname',
  Dsc = 'dsc',
  DeviceTypeId = 'device_type_id',
  Longitude = 'longitude',
  Latitude = 'latitude',
}

export const FORM_RULES: Record<FormKeys, RulesItems> = {
  sname: {
    regExp: '^.{0,100}$',
    required: true,
  },
  dsc: {
    regExp: '^(?:.{0,500}(?:\r?\n|$)){1,4}$',
    required: true,
  },
  latitude: {
    regExp: '-?\\d{1,3}\\.\\d+',
    required: true,
  },
  longitude: {
    regExp: '-?\\d{1,3}\\.\\d+',
    required: true,
  },
  device_type_id: {
    regExp: null,
    required: true,
  },
};

export const BUTTON_TEXT: Record<DeviceFormType, string> = {
  addForm: 'Добавить',
  editForm: 'Обновить данные',
};
