import { Card } from 'antd';
import { observer } from 'mobx-react-lite';
import { FC, useEffect } from 'react';

import { SYSTEM } from '../../../constants/constants';
import { findById } from '../../../helpers/findBy';
import rootStore from '../../../stores/rootStore/rootStore';
import { DetailedItemProps } from '../../DetailedMain/DetailedWrapper/constants/constants';

import DetectorsListCard from './DetectorsListCard/DetectorsListCard';
import GeneralDtTabs from './GeneralDtTabs/GeneralDtTabs';
import GraphCard from './GraphCard/GraphCard';

import styles from './TlDetectors.module.scss';

const TlDetectors: FC<DetailedItemProps> = ({ id }) => {
  const { LIGHTS } = SYSTEM;
  const { infoData } = rootStore.uiStore;
  const { tls } = rootStore.mapDataStore;
  const { setActiveDtId } = rootStore.detailedStore;

  const isTl = infoData?.system === LIGHTS;

  const activeTrafficLight = findById(tls, id);

  useEffect(() => {
    const links = activeTrafficLight?.linkedDeviceIds?.detectors ?? [];

    setActiveDtId(links[0] ?? null);
  }, [activeTrafficLight?.linkedDeviceIds?.detectors, setActiveDtId]);

  useEffect(() => {
    !isTl && setActiveDtId(id);
  }, [isTl, id, setActiveDtId]);

  useEffect(() => {
    return () => {
      setActiveDtId(null);
    };
  }, [setActiveDtId]);

  if (isTl && !activeTrafficLight?.linkedDeviceIds?.detectors?.length) {
    return (
      <div className={styles.containerNotDetectors}>
        <Card className={styles.mainGraphCardNotDetectors}>
          <h3>Нет привязанных детекторов</h3>
        </Card>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <GraphCard id={id} isTl={isTl} />
      <div className={styles.subwrap}>
        <GeneralDtTabs />
        <DetectorsListCard idObj={id} isTl={isTl} />
      </div>
    </div>
  );
};

export default observer(TlDetectors);
