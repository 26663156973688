import { AiOutlineLineChart } from 'react-icons/ai';
import { FaCarSide, FaRoad, FaTrafficLight } from 'react-icons/fa';
import { FiCalendar } from 'react-icons/fi';
import { GiSplitCross } from 'react-icons/gi';
import { TbChartCandle } from 'react-icons/tb';

import { ReactComponent as DirFromCamera } from '../../../../assets/icons/cameras_dir/dir_from_camera.svg';
import { ReactComponent as DirOnCamera } from '../../../../assets/icons/cameras_dir/dir_on_camera.svg';
import { ReactComponent as AutoIco } from '../../../../assets/icons/vehicle_types/ic_auto.svg';
import { ReactComponent as BusIco } from '../../../../assets/icons/vehicle_types/ic_bus.svg';
import { ReactComponent as MotoIco } from '../../../../assets/icons/vehicle_types/ic_moto.svg';
import { ReactComponent as TruckIco } from '../../../../assets/icons/vehicle_types/ic_truck.svg';
import { ReactComponent as UnknownIco } from '../../../../assets/icons/vehicle_types/ic_unknown.svg';
import { HOUR } from '../../../../constants/constants';
import { getFormattedTimeToDayJS } from '../../../../helpers/formatDate';
import { LaneDirectionKey } from '../../../Constructor/TrafficLaneConstructor/TLaneConstructor.model';
import {
  ChartBDKeys,
  ChartKeys,
} from '../../../InfoPanel/SystemsInfo/PopupDetector/Chart/constants/constants';
import {
  DirectionsTypes,
  VehicleTypes,
} from '../../../InfoPanel/SystemsInfo/PopupDetector/PopupDetector.model';
import {
  FilterDataValues,
  FilterInfo,
} from '../../../ui-kit/Chart/models/chart.model';
import { getSliceDates } from '../IntancyGraph/helpers/getSliceDates';
import { TDtInfo } from '../IntancyGraph/TitleBtns/helpers/getTitleDetectors';
import {
  DatesKeys,
  IDateInfo,
  IPickerValue,
  PeriodKeys,
  PeriodType,
  TSelectDates,
} from '../model/graph.model';

export interface IValuesDatesArr {
  value: string;
  min: string | JSX.Element;
}

export enum GraphTypes {
  LineChart = 'LineChart',
  CandlesChart = 'CandlesChart',
}

export const DATES = {
  DAY: { value: 'Сутки', min: '1Д' },
  THREE_DAYS: { value: 'Три дня', min: '3Д' },
  WEEK: { value: 'Неделя', min: 'Н' },
  MONTH: { value: 'Месяц', min: 'М' },
  SELECT_TIME: { value: 'Выбрать период', min: <FiCalendar /> },
} as const;

export type DatesType = typeof DATES;

export type TDatesValues = ValueOf<DatesType>['value'];
export type TDatesMinValues = ValueOf<DatesType>['min'];

export const PREDICT_PERIOD = {
  HOUR: { value: 'Час', min: '1Ч' },
  HOUR_2: { value: '2 часа', min: '2Ч' },
  HOUR_3: { value: '3 часа', min: '3Ч' },
  HOUR_4: { value: '4 часа', min: '4Ч' },
} as const;

export const PREDICT_PERIOD_VALUES: Record<PeriodKeys, number> = {
  HOUR: 1 * HOUR,
  HOUR_2: 2 * HOUR,
  HOUR_3: 3 * HOUR,
  HOUR_4: 4 * HOUR,
};

export const DEFAULT_PERIOD_VALUES: Record<GraphTypes, DatesKeys | PeriodKeys> =
  {
    LineChart: 'DAY',
    CandlesChart: 'HOUR',
  };

export const PERIOD_DICT: Record<GraphTypes, PeriodType | DatesType> = {
  LineChart: DATES,
  CandlesChart: PREDICT_PERIOD,
};

export const GRAPHS_TITLE: Record<ChartKeys, IValuesDatesArr> = {
  vehicles: { value: 'Интенсивность', min: 'Инт' },
  avSpeed: { value: 'Ср. скорость', min: 'Ср. скор.' },
  density: { value: 'Ср. плотность', min: 'Ср. плотн.' },
};

export const AVG_LANE_DICT: Record<ChartBDKeys, boolean> = {
  passage_count: false,
  density: false,
  speed_avg: true,
};

export const GRAPHS_KEYS_VALUES = Object.entries(GRAPHS_TITLE);

export enum INTERVAL_DATES {
  HOUR = 3600,
  DAY = 86400,
  HALF_HOUR = 1800,
  FIFTEEN_MIN = 900,
  TEN_MIN = 600,
}

export enum INTERVALS_NAMES {
  DAY = 'СУТКИ',
  HOUR = '1 ч',
  HALF = '30 м',
  FIFTEEN = '15 м',
  TEN = '10 м',
}

export const GRAPH_TYPE_TITLE: Record<GraphTypes, string> = {
  CandlesChart: 'График прогнозируемых данных с ДТ',
  LineChart: 'График фактических данных с ДТ',
};

export const GRAPH_TYPES_VALUES: TDtInfo[] = [
  {
    caption: <AiOutlineLineChart />,
    dsc: GRAPH_TYPE_TITLE.LineChart,
    valueDt: GraphTypes.LineChart,
  },
  {
    caption: <TbChartCandle />,
    dsc: GRAPH_TYPE_TITLE.CandlesChart,
    valueDt: GraphTypes.CandlesChart,
  },
];

export interface IIntervals {
  label: INTERVALS_NAMES;
  duration: INTERVAL_DATES;
}

export const INTERVALS: IIntervals[] = [
  { label: INTERVALS_NAMES.DAY, duration: INTERVAL_DATES.DAY },
  { label: INTERVALS_NAMES.HOUR, duration: INTERVAL_DATES.HOUR },
  { label: INTERVALS_NAMES.HALF, duration: INTERVAL_DATES.HALF_HOUR },
  { label: INTERVALS_NAMES.FIFTEEN, duration: INTERVAL_DATES.FIFTEEN_MIN },
  { label: INTERVALS_NAMES.TEN, duration: INTERVAL_DATES.TEN_MIN },
];

const selectDate: TSelectDates = [...getSliceDates(1)];
const initInterval = INTERVAL_DATES.HOUR;

export const INITIAL_DATE_INFO: IDateInfo = {
  selectedDate: selectDate,
  interval: initInterval,
  radioValueDate: DEFAULT_PERIOD_VALUES.LineChart,
  isNeedUtcFormatted: true,
};

export const INITIAL_RANGE_VALUE: IPickerValue = {
  rangePicker: selectDate,
  timePicker: getFormattedTimeToDayJS({ s: initInterval }),
  status: undefined,
};

export enum ModalsNames {
  Settings = 'Settings',
  Period = 'Period',
}

const { OnCamera, OutCamera } = DirectionsTypes;

interface ColorsInfo<T extends number> {
  TYPE: T;
  COLOR: string;
}

export const COLORS_GRAPH_DT: ColorsInfo<DirectionsTypes>[] = [
  { TYPE: OnCamera, COLOR: 'var(--green)' },
  { TYPE: OutCamera, COLOR: 'var(--orange)' },
];

export const DIRECTIONS_ICONS = [
  { type: OnCamera, value: <DirOnCamera />, name: 'На камеру' },
  { type: OutCamera, value: <DirFromCamera />, name: 'От камеры' },
];

const { Auto, Bus, Moto, Truck, Unknown } = VehicleTypes;

export const VEHICLES_ICONS = [
  { type: Moto, value: <MotoIco />, name: 'Мотоцикл' },
  { type: Auto, value: <AutoIco />, name: 'Легковой автомобиль' },
  { type: Truck, value: <TruckIco />, name: 'Грузовой автомобиль' },
  { type: Bus, value: <BusIco />, name: 'Автобус' },
  { type: Unknown, value: <UnknownIco />, name: 'Не определен' },
];

export const COLORS_VEHICLES_DT: ColorsInfo<VehicleTypes>[] = [
  { TYPE: Moto, COLOR: 'var(--green)' },
  { TYPE: Auto, COLOR: 'var(--orange)' },
  { TYPE: Truck, COLOR: 'var(--purple)' },
  { TYPE: Bus, COLOR: 'var(--turquoise)' },
  { TYPE: Unknown, COLOR: 'var(--danger)' },
];

export enum FilteredTypes {
  Directions = 'directions',
  Lanes = 'lanes',
  VehicleTypes = 'vehicleTypes',
  Normative = 'normative',
}

export enum NormativeIds {
  Sp34G1 = 1,
  Sp34G2,
  Trans479,
  Standard,
}

export const NORMATIVE_VALUES = [
  {
    id: NormativeIds.Sp34G1,
    name: 'sp34_13330_2021_g1',
    icon: <FaTrafficLight />,
  },
  {
    id: NormativeIds.Sp34G2,
    name: 'sp34_13330_2021_g2',
    icon: <GiSplitCross />,
  },
  { id: NormativeIds.Trans479, name: 'trans_479', icon: <FaRoad /> },
  {
    id: NormativeIds.Standard,
    name: [ChartBDKeys.PassageCount, ChartBDKeys.Density],
    icon: <FaCarSide />,
  },
];

export const FilterValues: Record<LaneDirectionKey, DirectionsTypes> = {
  toCamera: OnCamera,
  fromCamera: OutCamera,
};

export const FILTERED_INFO: Omit<FilterInfo, 'data'>[] = [
  { filterType: FilteredTypes.Directions, title: 'Полосы' },
  { filterType: FilteredTypes.VehicleTypes, title: 'Типы ТС' },
  { filterType: FilteredTypes.Normative, title: 'Нормативный документ' },
];

export const DEFAULT_FILTERED: FilterDataValues = {
  directions: [OnCamera, OutCamera],
  lanes: null,
  vehicleTypes: null,
  normative: [1],
};

export const DEFAULT_PREDICT_FILTERED: FilterDataValues = {
  directions: [OnCamera],
  lanes: null,
  vehicleTypes: null,
  normative: [1],
};

export const ALL = 'all';
export const DEFAULT = 'default';

export enum GeneralDtKeys {
  Name = 'name',
  Dsc = 'dsc',
  DtType = 'dtType',
  SystemId = 'systemId',
  Adapter = 'adapter',
  StatusDeviceId = 'statusDeviceId',
  DeviceUid = 'deviceUid',
  CameraId = 'cameraId',
  SystemName = 'systemName',
}
