import { ICamera } from '../../../../ts/models/camera.model';
import { VideoCameraProps } from '../../models/VideoCamera.model';

type ReturnValue<T extends U<ICamera>> = T extends undefined
  ? undefined
  : VideoCameraProps;

export const getCameraInfo = <T extends U<ICamera>>(
  camera: T,
  isMapAction = true
): ReturnValue<T> => {
  if (!camera) return undefined as ReturnValue<T>;

  const { deviceInfo, longitude, latitude } = camera;

  const { dsc, caption, deviceId } = deviceInfo;

  const videoProps: VideoCameraProps = {
    dsc,
    caption,
    deviceId,
    longitude,
    latitude,
    isMapAction,
  };

  return videoProps as ReturnValue<T>;
};
