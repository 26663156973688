import { FC, ReactNode } from 'react';
import { FaRegSmile } from 'react-icons/fa';
import { GiUsbKey } from 'react-icons/gi';
import { HiOutlineKey } from 'react-icons/hi';
import { SiMonkeytie } from 'react-icons/si';

import LoginForm from '../LoginForm/LoginForm';

export enum LoginErrorStatus {
  AuthError = 'authError',
  ServerError = 'serverError',
  AccessBlocked = 'accessBlocked',
}

export const SERVER_ERROR_STATUS = [0, 502];

export enum AuthMethod {
  Face = 'face',
  Fingerprint = 'fingerprint',
  Key = 'key',
  Password = 'password',
}

export const DISABLED_AUTH_METHODS: AuthMethod[] = [
  AuthMethod.Face,
  AuthMethod.Fingerprint,
  AuthMethod.Key,
];

export const LOGIN_FORM_CONTENT: Record<AuthMethod, FC> = {
  password: LoginForm,
  face: () => null,
  fingerprint: () => null,
  key: () => null,
};

export const LOGIN_METHOD_ICONS: Record<AuthMethod, ReactNode> = {
  face: <FaRegSmile />,
  fingerprint: <SiMonkeytie />,
  key: <GiUsbKey />,
  password: <HiOutlineKey />,
};

interface LoginMethods {
  type: AuthMethod;
  isActive: boolean;
  title: string;
  info: string;
}

export const LOGIN_METHODS: LoginMethods[] = [
  {
    type: AuthMethod.Face,
    isActive: false,
    title: 'Распознавание лиц',
    info: 'Вход с помощью камеры',
  },
  {
    type: AuthMethod.Fingerprint,
    isActive: false,
    title: 'Распознавание отпечатков пальцев',
    info: 'Вход с помощью сканера отпечатков пальцев',
  },
  {
    type: AuthMethod.Key,
    isActive: false,
    title: 'Ключ безопасности',
    info: 'Вход с помощью физического ключа безопасности',
  },
  {
    type: AuthMethod.Password,
    isActive: true,
    title: 'Пароль',
    info: 'Вход с помощью пароля учетной записи',
  },
];
