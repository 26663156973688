import classNames from 'classnames';
import { CSSProperties, FC } from 'react';
import { LuWifi, LuWifiOff } from 'react-icons/lu';

import Popover, { PopoverProps } from '../../../ui-kit/Popover/Popover';
import {
  SCENE_ERRORS_CONTENT,
  SceneErrorsType,
} from '../../constants/constants';

import PopoverContent from './PopoverContent/PopoverContent';

import styles from './DtLanesButton.module.scss';

const EMPTY_DATA = 'Недостаточно данных для отображения полос';

export interface DtLanesProps {
  onDtLanesClick: () => void;
  isDtLanes: boolean;
  isDtLanesValid: boolean;
  sceneErrorType: N<SceneErrorsType>;
  lanesBtnTitle: string;
}

export interface DtLanesButtonProps extends DtLanesProps {
  basicProps?: {
    className: string;
    style: CSSProperties;
  };
  popoverProps: Omit<PopoverProps, 'children' | 'content'>;
}

const DtLanesButton: FC<DtLanesButtonProps> = ({
  isDtLanes,
  basicProps,
  popoverProps,
  isDtLanesValid,
  sceneErrorType,
  lanesBtnTitle,
  onDtLanesClick,
}) => {
  const isDisabled = !isDtLanesValid;

  const errorText = sceneErrorType
    ? SCENE_ERRORS_CONTENT[sceneErrorType]
    : null;

  const errorContent = isDisabled ? EMPTY_DATA : errorText;

  const icoStyle = classNames(basicProps?.className, {
    [styles.active]: isDtLanes,
    [styles.disabled]: isDisabled,
    [styles.warning]: errorContent,
  });

  const icoProps = {
    onClick: !isDisabled ? onDtLanesClick : undefined,
    className: icoStyle,
    style: basicProps?.style,
  };

  return (
    <Popover
      {...popoverProps}
      content={
        <PopoverContent title={lanesBtnTitle} errorText={errorContent} />
      }
    >
      {!isDtLanes ? (
        <LuWifi {...icoProps} data-testid="on" />
      ) : (
        <LuWifiOff {...icoProps} data-testid="off" />
      )}
    </Popover>
  );
};

export default DtLanesButton;
