import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import checkIsTLValid from '../../../../helpers/checkIsTLValid';
import { findPointUds } from '../../../../helpers/findPointUds';
import rootStore from '../../../../stores/rootStore/rootStore';
import PhaseCircle from '../PhaseCircle/PhaseCircle';
import PhaseCircleCrossroad from '../PhaseCircleCrossroad/PhaseCircleCrossroad';
import PhaseCircleHeader from '../PhaseCircleHeader/PhaseCircleHeader';
import PhaseCircleLayout from '../PhaseCircleLayout/PhaseCircleLayout';
import { PhaseCircleEmptyProps } from '../PhaseCircleOverlay.model';

import styles from './PhaseCircleEmpty.module.scss';

const PhaseCircleEmpty: FC<PhaseCircleEmptyProps> = ({
  selectedTL,
  color,
  isNoData = false,
}) => {
  const { isCrossroadBorder } = rootStore.mapDataStore;

  const {
    tlStatus,
    deviceInfo: { externalDeviceId, pointUdsUid },
  } = selectedTL;

  const point = findPointUds(pointUdsUid);
  const schemaODD = point?.basicProfileInfo?.schemaODD;
  const data = [{ name: 'onePhase', time: 100, color }];
  const cmd = tlStatus?.command?.full;
  const isTLValid = checkIsTLValid(selectedTL);

  return (
    <PhaseCircleLayout id={externalDeviceId} isTLValid={isTLValid}>
      <PhaseCircle timer={100} len={100} color={color} data={data} />

      {schemaODD?.id && isCrossroadBorder && (
        <PhaseCircleCrossroad
          tlCrossroad={schemaODD}
          phaseIdx={-1}
          id={schemaODD.id}
        />
      )}

      {isNoData && !isCrossroadBorder && (
        <p className={styles.noData}>
          Недостаточно данных для отображения светофорных фаз
        </p>
      )}

      <PhaseCircleHeader color={color} cmd={cmd} />
    </PhaseCircleLayout>
  );
};

export default observer(PhaseCircleEmpty);
