import dayjs from 'dayjs';

import { getTimeWithTimeZone } from '../../components/TrafficLightDetailed/TlDetectors/IntancyGraph/TitleBtns/TimeLocalCard/helpers/getTimeWithTimeZone';
import { getTimeZones } from '../../components/TrafficLightDetailed/TlDetectors/IntancyGraph/TitleBtns/TimeLocalCard/helpers/getTimeZone';
import {
  DATE_FORMAT_TO_SHOW,
  DB_STATISTIC_DATE_FORMAT,
} from '../../constants/constants';

const convertDBdate = (date: Nullish<string>) => {
  if (!date) return null;

  const timeZone = getTimeZones().timeZoneRegion;
  const dateTimeZone = getTimeWithTimeZone({
    dateJS: dayjs(date, DB_STATISTIC_DATE_FORMAT).utc(false),
    isUTCZero: true,
  }).dateFormattedJS;

  const timeZoneStr = timeZone > 0 ? `+${timeZone}` : timeZone;

  return `${dateTimeZone.format(DATE_FORMAT_TO_SHOW)} (GMT ${timeZoneStr})`;
};

export default convertDBdate;
