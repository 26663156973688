import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import { DTS_NO_IMAGE } from '../../../../constants/devicesConstants';
import { findById } from '../../../../helpers/findBy';
import rootStore from '../../../../stores/rootStore/rootStore';

import DetectorsWrapper from './DetectorsWrapper/DetectorsWrapper';

import styles from './DetectorsListCard.module.scss';

interface IDetectorsListCard {
  idObj: number;
  isTl: boolean;
}

const DetectorsListCard: FC<IDetectorsListCard> = ({ idObj, isTl }) => {
  const { detectors } = rootStore.mapDataStore;
  const { activeDtId } = rootStore.detailedStore;

  const dtId = isTl ? activeDtId : idObj;
  const dt = findById(detectors, dtId);

  const isNoImageDt =
    !!dt?.eputsAdapter?.scSystemTypeId &&
    DTS_NO_IMAGE.includes(dt.eputsAdapter.scSystemTypeId);

  return (
    <div className={styles.tlDetectorsList}>
      <h3>Изображение с детектора</h3>
      <DetectorsWrapper idObj={idObj} isTl={isTl} isNoImageDt={isNoImageDt} />
    </div>
  );
};

export default observer(DetectorsListCard);
