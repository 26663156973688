// eslint-disable-next-line import/no-unresolved
import { D } from '@tanstack/react-query-devtools/build/modern/devtools-9h89nHJX';
import type { Coordinate } from 'ol/coordinate';
import Feature from 'ol/Feature';
import { Geometry } from 'ol/geom';

import { DictDeviceKeys } from '../../api/devices/constants/constants';
import { LibraryKeys } from '../../api/libraries/enums/enums';
import { LibraryKeysZod } from '../../api/libraries/libraries.zod';
import { DictPredictKeys } from '../../api/services/dtStatistics/constants/constants';
import { CLUSTERS } from '../../constants/mapConstants';
import { System } from '../../ts/enums/enums';
import { TInfoDataSystem, TObjMap } from '../mapDataStore/mapDataStore.model';

export interface ISecTls {
  id: number;
  sec: number;
}

export enum Tooltip {
  Fast = 0.2,
  One = 1,
  Two = 2,
}

export enum MarkersKeys {
  IsLights = 'isLights',
  IsDetectors = 'isDetectors',
  IsCameras = 'isCameras',
  IsSpecialTransport = 'isSpecialTransport',
  IsPublicTransport = 'isPublicTransport',
  IsMeteo = 'isMeteo',
  IsTraffic = 'isTraffic',
  IsRta = 'isRta',
  IsRoadWorks = 'isRoadWorks',
  IsActivities = 'isActivities',
  IsTrafficRestrictions = 'isTrafficRestrictions',
}

export type Markers = Record<MarkersKeys, boolean>;
export type TipType = N<Tooltip>;

export interface TipsValues {
  interfaceTipsDelay: TipType;
  mapTipsDelay: TipType;
  mapButtonsTipsDelay: TipType;
  profileTipsDelay: TipType;
  gisTooltipsDelay: TipType;
}

export interface IInfoPanelData {
  system: TInfoDataSystem;
  coordinate: Coordinate;
  id: number;
  mapAction?: MapActions;
  coordFeatureOnCluster?: N<Coordinate>;
}

export interface InfoData extends IInfoPanelData {
  device: N<TObjMap>;
}

export enum MapActions {
  Zoom = 'zoom',
  NoActions = 'noActions',
  Center = 'center',
  CenterZoom = 'centerZoom',
}

export interface ITooltipData {
  id: number;
  system: System | typeof CLUSTERS;
  clustersFeatures: Feature<Geometry>[];
}

export interface LayerTooltipData {
  id: U<number | string>;
  coordinates: Coordinate;
  fields: { key: string; value: SN }[];
  displayField: string;
  isWeather?: boolean;
  title?: string;
  text?: string;
}

export interface IClickedCartographyObj {
  id: number;
  system: TInfoDataSystem;
}

export enum ManagementModules {
  CoordControl = 'coordControl',
  GroupDispatchManagement = 'groupDispatchManagement',
}

export type TQueryToolsBtnPosition = D['buttonPosition'];

export type LibraryValues = Omit<LibraryKeysZod, 'id'> & {
  id?: number;
};

export type DictKeys = LibraryKeys | DictPredictKeys | DictDeviceKeys;

export type Dictionaries = Record<DictKeys, LibraryValues[]>;
