import { Tag } from 'antd';
import { DescriptionsItemType } from 'antd/lib/descriptions';

import { DEVICE_TYPES_ID } from '../../../../../constants/constants';
import { findById } from '../../../../../helpers/findBy';
import { LibraryValues } from '../../../../../stores/uiStore/uiStore.model';
import { Detector } from '../../../../../ts/models/mapObject.model';
import { getStatusName } from '../../../../DetailedStatistics/Monitoring/DeviceCard/MainContent/GeneralInfo/helpers/getGeneralItems';
import SkeletonInput from '../../../../DetailedStatistics/Monitoring/SkeletonInput/SceletonInput';
import { GeneralDtKeys } from '../../constants/constants';

export const getGeneralDtInfo = (
  dt: Detector,
  systemTypeDict: LibraryValues[]
): DescriptionsItemType[] => {
  const { caption, deviceStatusId, deviceTypeId, dsc, uid } = dt.deviceInfo;
  const { scSystemId } = dt.eputsAdapter;

  const systemTypeId = dt.eputsAdapter.scSystemTypeId;
  const extOptions = dt.dai_jdt80.source_info?.ext_options;
  const uidCamera =
    extOptions?.camera_jsetting2?.dt_ext_jsetting?.camera_uid ??
    extOptions?.camera_jsetting?.uid;
  const correctUid = uidCamera ?? null;

  const { statusColor, statusName } = getStatusName(deviceStatusId);

  const systemType = findById(systemTypeDict, systemTypeId)?.name || null;

  const dtInfoArr = [
    {
      key: GeneralDtKeys.Name,
      label: 'Название детектора',
      children: caption,
      span: 2,
    },
    { key: GeneralDtKeys.Dsc, label: 'Описание', children: dsc, span: 2 },
    {
      key: GeneralDtKeys.DtType,
      label: 'Тип детектора',
      children: DEVICE_TYPES_ID[deviceTypeId],
    },
    {
      key: GeneralDtKeys.SystemId,
      label: 'Id Системы',
      children: scSystemId.toString(),
    },
    {
      key: GeneralDtKeys.SystemName,
      label: 'Название системы',
      children: <SkeletonInput loading value={null} />,
    },
    { key: GeneralDtKeys.Adapter, label: 'Адаптер', children: systemType },
    {
      key: GeneralDtKeys.StatusDeviceId,
      label: 'Статус устройства',
      children: <Tag color={statusColor}>{statusName}</Tag>,
    },
    {
      key: GeneralDtKeys.DeviceUid,
      label: 'id устройства',
      children: uid,
      span: 2,
    },
    {
      key: GeneralDtKeys.CameraId,
      label: 'id камеры',
      children: correctUid,
      span: 2,
    },
  ].map((el) => ({ ...el, children: el.children || 'Неизвестно' }));

  return dtInfoArr;
};
