import { MdSettings } from 'react-icons/md';

import { BASEMAPS_CONTENT } from './constants';

import styles from './BasemapsButton.module.scss';

const BasemapPanelTitle = (props: { mode: boolean; onClick: () => void }) => {
  return (
    <div className={styles.baseMapTitleContainer}>
      <div className={styles.baseMapsPanelTitle}>{BASEMAPS_CONTENT.title}</div>
      <div>
        {
          <MdSettings
            className={
              props.mode
                ? styles.baseMapSettingsButton
                : styles.baseMapSettingsButtonActive
            }
            onClick={props.onClick}
          />
        }
      </div>
    </div>
  );
};

export default BasemapPanelTitle;
