import { Button } from 'antd';
import { FormInstance } from 'antd/lib';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import rootStore from '../../../../../../../stores/rootStore/rootStore';
import { AddFormFields } from '../../../../models/DeviceAdmin.model';
import { BUTTON_TEXT } from '../../constants/constants';

import useButtons from './hooks/useButtons';

import styles from './Buttons.module.scss';

interface ButtonsProps {
  form: FormInstance;
  initialProps: U<AddFormFields>;
}

const Buttons: FC<ButtonsProps> = ({ form, initialProps }) => {
  const { deviceFormType } = rootStore.adminPanelStore;

  const { isDisabledConfirm, isLoading, handleSubmit } = useButtons(
    form,
    initialProps
  );

  if (!deviceFormType) return null;

  return (
    <div className={styles.btns}>
      <Button
        type="primary"
        onClick={handleSubmit}
        disabled={isDisabledConfirm}
        loading={isLoading}
      >
        {BUTTON_TEXT[deviceFormType]}
      </Button>
    </div>
  );
};

export default observer(Buttons);
