import { useQuery } from '@tanstack/react-query';

import api from '../../../../../../../api';
import {
  DEFAULT_STALE_TIME,
  DEFAULT_UPDATE_DEVICE_ADMIN,
  QUERY_KEY,
} from '../../../../../../../constants/queryConstants';
import rootStore from '../../../../../../../stores/rootStore/rootStore';
import { SourceFormFields } from '../../../../models/DeviceAdmin.model';
import transformSystemsToOptions from '../helpers/transformSystemsToOptions';

const { SOURCE_SYSTEMS } = QUERY_KEY;

const { fetchSystemsList } = api.adminObjects.systems;

const APP_PATH =
  'Admin.DeviceAdmin.SourceEdit.SourceEdit.SourceForm.SystemsSelect.hooks.useSystemsSelect';

const useSystemsSelect = (systemTypeId: SourceFormFields['systemTypeId']) => {
  const { regionData } = rootStore.uiStore;
  const { selectedDevice } = rootStore.adminPanelStore;

  const { data: options, isFetching: isLoading } = useQuery({
    queryKey: [SOURCE_SYSTEMS, regionData, systemTypeId, selectedDevice],
    queryFn: async () => {
      if (!systemTypeId || !regionData || !selectedDevice) return null;

      const systemsList =
        (await fetchSystemsList(systemTypeId, regionData.id, APP_PATH)) ?? null;

      return transformSystemsToOptions(systemsList, selectedDevice);
    },
    refetchInterval: DEFAULT_UPDATE_DEVICE_ADMIN,
    staleTime: DEFAULT_STALE_TIME,
    enabled: !!(systemTypeId && regionData && selectedDevice),
  });

  return { options, isLoading };
};

export default useSystemsSelect;
