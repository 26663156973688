import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import { TMap } from '../../../../../stores/mapStore/mapStore.model';

import CrossroadSchemaUds from './CrossroadSchemaUds/CrossroadSchemaUds';
import CrossroadTLWrapper from './CrossroadTLWrapper/CrossroadTLWrapper';
import useCrossroadOverlay from './hooks/useCrossroadOverlay';

import styles from './CrossroadSchemaWrapper.module.scss';

interface CrossroadSchemaWrapperProps {
  tlId: U<number>;
  map: TMap;
  dtId: number;
}

const CrossroadSchemaWrapper: FC<CrossroadSchemaWrapperProps> = ({
  tlId,
  map,
  dtId,
}) => {
  const { mainProps, crossroadDetailedRef } = useCrossroadOverlay({
    map,
  });

  return (
    <div className={styles.container} ref={crossroadDetailedRef}>
      {tlId ? (
        <CrossroadTLWrapper {...mainProps} tlId={tlId} />
      ) : (
        <CrossroadSchemaUds {...mainProps} dtId={dtId} />
      )}
    </div>
  );
};

export default observer(CrossroadSchemaWrapper);
