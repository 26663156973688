export enum LibraryKeys {
  SystemId = 'sc_system_id',
  SystemStatusId = 'sc_system_status_id',
  RegionId = 'region_id',
  SubSystemId = 'sub_system_id',
  AdapterStatus = 'adapter_status_mnemocode',
  SystemTypeId = 'sc_system_type_id',
  ExternalLinksStatus = 'lnk_device_externaldevice.status_id',
  ChartFields = 'chart_fields_dsc',
  AdapterSessionStatusId = 'adapter_session_status_id',
}
