import { notification } from 'antd';
import { SafeParseReturnType, ZodObject } from 'zod';

import { IS_DEV } from '../../constants/constants';
import NOTICE from '../../constants/notificationConstants';
import { sendError } from '../../helpers/sendError';
import rootStore from '../../stores/rootStore/rootStore';
import { MapObject } from '../../ts/enums/enums';
import { WEB_DEVELOPER_CODE } from '../../ts/enums/userData';
import { BasicMapDataZod } from '../../ts/models/mapObject.model';
import { CameraSchema } from '../mapObjects/cameras/cameras.zod';
import { DetectorSchema } from '../mapObjects/detectors/detectors.zod';
import { MeteoSchema } from '../mapObjects/meteo/meteo.zod';
import { PointUdsSchema } from '../mapObjects/pointsUds/pointsUds.zod';
import { TLSchema } from '../mapObjects/trafficLights/zod/tls.zod';

import { getValidate } from './getValidate';

export interface IData {
  id: number;
  idMgpAPI?: number;
}

export enum ObjType {
  tl = 'светофорах',
  cm = 'камерах',
  dt = 'детекторах',
  mt = 'метеостанциях',
  uds = 'точках УДС',
}

const APP_PATH = 'api.helpers.validateData';

export const validateData = <T extends BasicMapDataZod>(
  data: U<T[]>,
  Schema: ZodObject<any>,
  objType = 'объектах'
): U<T[]> => {
  if (!Array.isArray(data)) {
    notification.error(NOTICE.INCORRECT_DATA_TYPES);

    return;
  }

  if (!data.length) return data;

  const { isZodLog } = rootStore.uiStore;
  const { hasAccess } = rootStore.userDataStore;

  const errorsArray: any = [];
  const warningsArray: any = [];

  const isLog = hasAccess(WEB_DEVELOPER_CODE) && isZodLog;

  const errorIds = data.reduce((errorsIds: string, el) => {
    const validation: SafeParseReturnType<any, any> = getValidate<T>(
      Schema,
      objType,
      el
    );
    const validationNewKeys = Schema.strict().safeParse(el);

    if (validation.success && validationNewKeys.success) return errorsIds;

    if (!validation.success) {
      const id =
        objType === ObjType.tl ? el?.deviceInfo.externalDeviceId : el?.id;

      // eslint-disable-next-line no-param-reassign
      errorsIds += id ? `${id}, ` : `без номера, `;
      isLog && console.error(el?.id, validation.error.issues);
      errorsArray.push({
        id: el?.id,
        errors: validation.error.issues,
        addInfo: el.addinfo,
        header: el.header,
      });
    }

    if (!validationNewKeys.success) {
      validationNewKeys.error.issues.forEach((el) => {
        // заменяем одинарные кавычки на двойные, для исправления проблемы парсинга в БД
        el.message = el.message.replaceAll("'", '"');
      });

      isLog &&
        console.warn(`(FRONT): ${el?.id}`, validationNewKeys.error.issues);
      warningsArray.push({
        id: el?.id,
        errors: validationNewKeys.error.issues,
        addInfo: el.addinfo,
        header: el.header,
      });
    }

    return errorsIds;
  }, '');

  errorIds &&
    !IS_DEV &&
    sendError(
      NOTICE.INCORRECT_TYPES_FLEX.description,
      {
        dsc: `Ошибка в ${objType}: ${errorIds.slice(0, -2)}.`,
        issues: errorsArray,
      },
      APP_PATH
    );

  warningsArray &&
    !IS_DEV &&
    sendError(
      NOTICE.NEW_KEY.description,
      {
        dsc: `Предупреждение в ${objType}: ${warningsArray.slice(0, -2)}.`,
        issues: warningsArray,
      },
      APP_PATH
    );

  return data;
};

export const validateMapObj = <T extends BasicMapDataZod>(
  type: MapObject,
  data: U<T[]>
) => {
  switch (type) {
    case MapObject.cm:
      return validateData<T>(data, CameraSchema, ObjType.cm);
    case MapObject.tl:
      return validateData<T>(data, TLSchema, ObjType.tl);
    case MapObject.dt:
      return validateData<T>(data, DetectorSchema, ObjType.dt);
    case MapObject.mt:
      return validateData<T>(data, MeteoSchema, ObjType.mt);
    case MapObject.uds:
      return validateData<T>(data, PointUdsSchema, ObjType.uds);
    default:
      console.error(`Валидация данных типа '${type}' не произведена.`);

      return;
  }
};
