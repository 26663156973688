import { LonLat } from '../../../../../../stores/mapDataStore/mapDataStore.model';
import rootStore from '../../../../../../stores/rootStore/rootStore';

const DEFAULT_COORD_TEXT = 'Допустимый диапазон значений:';
const { Latitude, Longitude } = LonLat;

const getCoordInfo = (key: string) => {
  const { selectedRegionData } = rootStore.adminPanelStore;

  const isLatitude = key.includes(Latitude);
  const isLongitude = key.includes(Longitude);

  if ((!isLatitude && !isLongitude) || !selectedRegionData)
    return 'Введите координаты';

  const { mapCenterCoords } = selectedRegionData;

  const [lon, lat] = mapCenterCoords;

  if (isLongitude) return lon;

  return lat;
};

export const getCoordPlaceholder = (key: string) => {
  return getCoordInfo(key).toString().replace('.', ',');
};

export const getCoordTemplate = (
  min: number,
  max: number,
  defaultText?: string
) =>
  `${DEFAULT_COORD_TEXT} ${`${min} - ${max}`.replaceAll('.', ',')}. ${
    defaultText ?? ''
  }`;

export const getCoordsTooltip = (pointUdsName: N<string>, key: string) => {
  const { selectedRegionData } = rootStore.adminPanelStore;

  const isLongitude = key.includes(Longitude);

  const defaultText = pointUdsName
    ? `Устройство привязано к точке УДС: ${pointUdsName}. Рекомендовано редактировать координаты через модуль - Конструктор`
    : undefined;

  if (!selectedRegionData) return defaultText;

  const { extent } = selectedRegionData;

  const [xMin, yMin, xMax, yMax] = extent;

  if (isLongitude) return getCoordTemplate(xMin, xMax, defaultText);

  return getCoordTemplate(yMin, yMax, defaultText);
};
