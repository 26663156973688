import type { SelectProps } from 'antd/lib';
import { isNumber } from 'lodash';

import { findById } from '../../../../../../../helpers/findBy';
import { getIsDisabledControl } from '../../../../../../../stores/mapDataStore/helpers/getIsDisabledControl';
import rootStore from '../../../../../../../stores/rootStore/rootStore';
import { SelectedFeatures } from '../../../../../../../stores/scriptsControlStore/scriptsControlStore.model';
import { System } from '../../../../../../../ts/enums/enums';
import { TL } from '../../../../../../../ts/models/tl.model';
import { getDeviceStatusColor } from '../../../../../../InfoPanel/SystemsInfo/LinkedsInfo/LinkedDeviceItem/StatusColor/helpers/getColorDevice';
import { getTypeDisabledDevice } from '../../../../../MainPanelTabs/AllSystemsTab/helpers';
import { getTLWarnings } from '../../DipatchGroupDetailed/ControlCard/helpers/helpers';
import LabelItem from '../LabelItem/LabelItem';

export const SEPARATE = '#';

export const getTLItemByKey = (key: string, tls: TL[]) => {
  const id = Number(key.split(SEPARATE).at(0));

  const tl = findById(tls, id);

  const status = tl?.deviceInfo?.deviceStatusId;

  if (Number.isNaN(id) || !isNumber(status)) return;

  const color = getDeviceStatusColor(status);

  return { id, color };
};

const getFormattedKey = (id: number, caption: string) => {
  return `${id}${SEPARATE}${caption}`;
};

enum ControlledType {
  Uncontrolled = 'uncontrolled',
  Controlled = 'controlled',
  OnlyRead = 'onlyRead',
}

const { Controlled, Uncontrolled, OnlyRead } = ControlledType;

type SelectedOptions = Record<
  ControlledType,
  NonNullable<SelectProps['options']>
>;

export const getSelectOptions = (): SelectProps['options'] => {
  const { tls } = rootStore.mapDataStore;

  const { controlled, uncontrolled, onlyRead } = tls.reduce(
    (acc: SelectedOptions, item) => {
      const disabledType = getTypeDisabledDevice(item, System.Lights, {
        isGroupDispatch: true,
      });

      const {
        deviceInfo: { controlAccess, deviceStatusId },
      } = item;

      const isIncorrectControl = getIsDisabledControl(deviceStatusId);
      const { isAccessMode } = getTLWarnings(controlAccess);

      let controlledStatus = ControlledType.Controlled;

      if (!isAccessMode) controlledStatus = ControlledType.OnlyRead;

      if (isIncorrectControl) controlledStatus = ControlledType.Uncontrolled;

      acc[controlledStatus].push({
        value: getFormattedKey(item.id, item.deviceInfo.caption),
        label: (
          <LabelItem key={item.id} tl={item} disabledType={disabledType} />
        ),
        disabled: isIncorrectControl,
      });

      return acc;
    },
    { [Uncontrolled]: [], [Controlled]: [], [OnlyRead]: [] }
  );

  const items = [
    {
      label: <span>Доступные</span>,
      title: 'controlled',
      options: controlled,
    },
    {
      label: <span>Только просмотр</span>,
      title: 'onlyRead',
      options: onlyRead,
    },
    {
      label: <span>Недоступные</span>,
      title: 'uncontrolled',
      options: uncontrolled,
    },
  ].filter(({ options }) => options.length);

  return items;
};

export const getIsFilterOption = (input: N<string>, value: Nullish<SN>) => {
  const filterValue = (input?.trim() ?? '').toLowerCase();

  if (!value) return false;

  const optionValue = value.toString().toLowerCase();

  const [id, caption] = optionValue.split(SEPARATE);

  return id.includes(filterValue) || caption.includes(filterValue);
};

export const getSelectedValues = (
  tls: TL[],
  selectedFeatures: SelectedFeatures[]
) => {
  return selectedFeatures.reduce((acc: string[], { id }) => {
    const tl = findById(tls, id);

    if (tl) {
      const {
        deviceInfo: { caption },
      } = tl;

      acc.push(getFormattedKey(id, caption));
    }

    return acc;
  }, []);
};
