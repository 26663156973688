import { Spin } from 'antd';
import { AxiosError } from 'axios';
import classNames from 'classnames';
import { FC, useEffect, useState } from 'react';

import api from '../../../api';
import { VideoSourceItem } from '../models/VideoCamera.model';
import VideoCamera, { VideoCameraProps } from '../VideoCamera';

import styles from './VideoCameraWithPreloader.module.scss';
import VideoCameraStyles from '../VideoCamera.module.scss';

type VideoCameraWithPreloaderProps = Omit<VideoCameraProps, 'videoSources'>;

const VideoCameraWithPreloader: FC<VideoCameraWithPreloaderProps> = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<N<string>>(null);

  const [videoSources, setVideoSources] = useState<VideoSourceItem[]>([]);

  const fetchVideoSources = async () => {
    try {
      setIsLoading(true);

      if (!props.cameraData?.deviceId)
        throw new Error(
          'Ошибка: невозможно получить список потоков камеры, т.к. не найден идентификатор устройства камеры'
        );

      const videoSources =
        await api.mapObjects.cameras.camerasApi.getVideoSourcesByDeviceId(
          props.cameraData?.deviceId
        );

      if (!videoSources)
        throw new Error('Ошибка: не удалось получить список потоков камеры');

      if (!videoSources.length)
        throw new Error('Ошибка: Список потоков камеры пуст');

      setVideoSources(videoSources);
      setError(null);
    } catch (e: any) {
      let errorMes: string = e.message;

      if (e instanceof AxiosError) {
        errorMes = e?.response?.data?.message || errorMes;
      }

      setError(errorMes);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchVideoSources();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.cameraData?.deviceId]);

  const wrapperClass = classNames([VideoCameraStyles.wrapper, styles.wrapper]);

  if (isLoading)
    return (
      <div className={wrapperClass} data-testid="spin-loader-wrapper">
        <Spin spinning={isLoading} size="large" />
      </div>
    );

  if (error || !videoSources)
    return (
      <div className={wrapperClass} data-testid="error-wrapper">
        <p className={styles.errorText}>{error}</p>
      </div>
    );

  const videoCameraProps = {
    ...props,
    videoSources,
  };

  return <VideoCamera {...videoCameraProps} />;
};

export default VideoCameraWithPreloader;
