import { observer } from 'mobx-react-lite';

import rootStore from '../../../../../stores/rootStore/rootStore';
import { SOURCE_FORM_CONTENT } from '../../constants/constants';
import SourceContainer from '../SourceContainer/SourceContainer';

const SourceSwitcher = () => {
  const { sourceDisplayType } = rootStore.adminPanelStore;

  if (!sourceDisplayType) return <SourceContainer />;

  const SourceContent = SOURCE_FORM_CONTENT[sourceDisplayType];

  return <SourceContent />;
};

export default observer(SourceSwitcher);
