import dict from './dict';
import externalLinks from './externalLinks';
import info from './info';
import manage from './manage';

export default {
  externalLinks,
  manage,
  info,
  dict,
};
