import { VideoSourceItem } from '../../../components/VideoCamera/models/VideoCamera.model';
import { VERSION } from '../../../constants/versions';
import { MapObject } from '../../../ts/enums/enums';
import http from '../../http';
import mapObjectsApi from '../mapObjectsApi';

const ENDPOINT = `${VERSION}/objects`;

const camerasApi = {
  fetchAll: async (regionId: number) =>
    await mapObjectsApi.fetchFullObject(MapObject.cm, regionId),

  fetchItem: async (id: number) =>
    await mapObjectsApi.fetchObjectItem(MapObject.cm, id),

  getVideoSourcesByDeviceId: async (cameraDeviceId: number) => {
    const res = await http.get<VideoSourceItem[]>(
      `${ENDPOINT}/cm/${cameraDeviceId}/video-sources`
    );

    return res.data;
  },
};

export default camerasApi;
