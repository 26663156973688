import { observer } from 'mobx-react-lite';

import { findById } from '../../../../helpers/findBy';
import rootStore from '../../../../stores/rootStore/rootStore';
import VideoPreview from '../../../TrafficLightDetailed/TlDetectors/DetectorsListCard/DetectorsWrapper/DtVideoPreview/VideoPreview/VideoPreview';

import CamerasDetailedWrapper from './CamerasDetailedWrapper/CamerasDetailedWrapper';

const CamerasDetailed = () => {
  const { sortedCameras, view } = rootStore.constructorStore;
  const { cameras } = rootStore.mapDataStore;

  const isAnyEnabled = sortedCameras.some(({ isEnabled }) => isEnabled);

  if (!view.isCameras || !isAnyEnabled) {
    return <h3>Не выбраны камеры для отображения</h3>;
  }

  return (
    <>
      {sortedCameras.map(({ id, caption, isEnabled }) => {
        if (!isEnabled) return null;

        const camera = findById(cameras, id);
        const wrapperProps = { id, caption };

        const dtId = camera?.dai_jdt83?.linkedDetectorId;

        if (!dtId) {
          return (
            <CamerasDetailedWrapper key={id} {...wrapperProps}>
              <p>Камера не найдена</p>
            </CamerasDetailedWrapper>
          );
        }

        return (
          <CamerasDetailedWrapper key={id} {...wrapperProps}>
            <VideoPreview id={dtId} isShowOnlyZones />
          </CamerasDetailedWrapper>
        );
      })}
    </>
  );
};

export default observer(CamerasDetailed);
