import { type DescriptionsProps } from 'antd/lib';

import { LibraryKeys } from '../../../../../../../api/libraries/enums/enums';
import rootStore from '../../../../../../../stores/rootStore/rootStore';
import { ChartBDKeys } from '../../../../../../InfoPanel/SystemsInfo/PopupDetector/Chart/constants/constants';
import { GraphTypes } from '../../../../../../TrafficLightDetailed/TlDetectors/constants/constants';

const { ChartFields } = LibraryKeys;
const { CandlesChart } = GraphTypes;

const getNormativeItems = (
  chartType: ChartBDKeys
): DescriptionsProps['items'] => {
  const { graphType } = rootStore.detailedStore;
  const chartFields = rootStore.uiStore.dictionaries[ChartFields];

  if (graphType === CandlesChart) return [];

  return chartFields.reduce(
    (
      acc: NonNullable<DescriptionsProps['items']>,
      { name, dsc, id, mnemocode }
    ) => {
      const isCurrentType = mnemocode?.includes(chartType);

      if (name && dsc && isCurrentType) {
        acc.push({ key: id, label: name, children: dsc });
      }

      return acc;
    },
    []
  );
};

export default getNormativeItems;
