/* eslint-disable camelcase */
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import utc from 'dayjs/plugin/utc';
import { isUndefined } from 'lodash';

import {
  AdapterData,
  FormattedAdapter,
  FormattedStatistic,
  isAdapterGo,
  isInvalidAdapter,
} from '../../../../../api/adminObjects/systems/adapter/model/adapter.model';
import { UNKNOWN_STATUS } from '../../../../../constants/adminConstants';
import { COLORS } from '../../../../../constants/colorsConstants';
import { SECOND } from '../../../../../constants/constants';
import convertDBdate from '../../../../../helpers/dateHelpers/convertDBdate';
import {
  ADAPTER_LABELS,
  ENABLED_STATUS_DICT,
  STATUS_ADAPTER,
} from '../constants/monitoringConstants';
import {
  AdapterInfo,
  AdditionalAdapterKeys,
} from '../models/monitoring.models';

import getAdapterStatusDict, { UNDEFINED_STATUS } from './getAdapterStatusDict';

const { WARNING, SUCCESS, NOT_ALLOWED, DARK_PINK, DANGEROUS, GREY100 } = COLORS;

dayjs.extend(duration);
dayjs.extend(utc);

type StatusAdapter = keyof typeof STATUS_ADAPTER;

export const STATUS_COLORS: Record<
  StatusAdapter | typeof UNKNOWN_STATUS,
  string
> = {
  OK: SUCCESS,
  WARNING,
  ERROR: DANGEROUS,
  TERMINATED: DARK_PINK,
  SIGTERM: GREY100,
  UNDEFINED: NOT_ALLOWED,
};

const getWorkHours = (sec: N<number>) => {
  if (!sec) return { workTime: '0 мин.', hours: 0 };

  const duration = dayjs.duration(sec * SECOND);

  const years = Math.floor(duration.asYears());
  const days = Math.floor(duration.asDays());
  const hours = duration.hours();

  const floorHours = Math.floor(hours);

  const date = duration.format(
    `${years ? 'Y л.' : ''} ${days ? 'D д.' : ''} ${
      floorHours ? 'H ч.' : ''
    } m мин.`
  );

  return { workTime: date, hours: floorHours };
};

export const isAdapterStatus = (
  status: Nullish<string>
): status is StatusAdapter => {
  return status ? status in STATUS_COLORS : false;
};

const CATEGORY_REGULAR = /(?=(\d{3})+(?!\d))/g;

const getCorrectNumberByCategory = (number: Nullish<number>) =>
  number?.toString()?.replace(CATEGORY_REGULAR, ' ');

const getAdapterGoParams = (
  adapterObj: AdapterData['add_info']['adapter']
): Partial<Record<AdditionalAdapterKeys, AdapterInfo>> => {
  const statistic = adapterObj?.statistics;

  const dtFrom10MinValue = getCorrectNumberByCategory(
    statistic?.received_passes_from_last_10min
  );

  const dtFrom10Min = Number(dtFrom10MinValue);

  const tlFrom10MinValue = getCorrectNumberByCategory(
    statistic?.events_from_last_10min
  );
  const tlFrom10Min = Number(tlFrom10MinValue);

  const agentTasks = getCorrectNumberByCategory(
    statistic?.started_jobs_from_last_10min
  );
  const agentFrom10Min = Number(agentTasks);

  return {
    hostName: {
      label: ADAPTER_LABELS.hostName,
      value: adapterObj?.host_id,
    },
    sessionUid: {
      label: ADAPTER_LABELS.sessionUid,
      value: adapterObj?.session_uuid,
    },
    passesDtFromStart: {
      label: ADAPTER_LABELS.passesDtFromStart,
      value: getCorrectNumberByCategory(statistic?.received_passes_from_start),
      labelColor: !statistic?.received_passes_from_start
        ? DARK_PINK
        : undefined,
    },
    passesDtFrom10Min: {
      label: ADAPTER_LABELS.passesDtFrom10Min,
      value: dtFrom10MinValue,
      labelColor: !isNaN(dtFrom10Min) && !dtFrom10Min ? DARK_PINK : undefined,
    },
    passesTLFromStart: {
      label: ADAPTER_LABELS.passesTLFromStart,
      value: getCorrectNumberByCategory(statistic?.events_from_start),
      labelColor: !statistic?.events_from_start ? DARK_PINK : undefined,
    },
    passesTLFrom10Min: {
      label: ADAPTER_LABELS.passesTLFrom10Min,
      value: tlFrom10MinValue,
      labelColor: !isNaN(tlFrom10Min) && !tlFrom10Min ? DARK_PINK : undefined,
    },
    lastTime: {
      label: ADAPTER_LABELS.lastTime,
      value: convertDBdate(adapterObj?.lastTimeTZ),
    },
    startedJobsFromStart: {
      label: ADAPTER_LABELS.startedJobsFromStart,
      value: getCorrectNumberByCategory(statistic?.started_jobs_from_start),
      labelColor: !statistic?.started_jobs_from_start ? DARK_PINK : undefined,
    },
    startedJobsFromLast10Min: {
      label: ADAPTER_LABELS.startedJobsFromLast10Min,
      value: agentTasks,
      labelColor:
        !isNaN(agentFrom10Min) && !agentFrom10Min ? DARK_PINK : undefined,
    },
    latestPassageTimeTZ: {
      label: ADAPTER_LABELS.latestPassageTimeTZ,
      value: convertDBdate(statistic?.latest_passage_timeTZ),
    },
    latestEventTimeTZ: {
      label: ADAPTER_LABELS.latestEventTimeTZ,
      value: convertDBdate(statistic?.latest_event_timeTZ),
    },
    latestStartedJobTimeTZ: {
      label: ADAPTER_LABELS.latestStartedJobTimeTZ,
      value: convertDBdate(statistic?.latest_started_job_timeTZ),
    },
  };
};

export const getAdapterStatistic = (
  adapter: FormattedStatistic
): FormattedAdapter => {
  const baseAdapter = {
    systemId: { label: ADAPTER_LABELS.systemId, value: adapter?.sc_system_id },
    status: {
      label: ADAPTER_LABELS.status,
      value: getAdapterStatusDict(adapter?.status)?.name ?? adapter?.status,
      color: isAdapterStatus(adapter?.status)
        ? STATUS_COLORS[adapter?.status]
        : NOT_ALLOWED,
    },
    systemName: {
      label: ADAPTER_LABELS.systemName,
      value: adapter?.systemName,
    },
    regions: {
      label: ADAPTER_LABELS.regions,
      value: adapter?.regions,
    },
  };

  if (isInvalidAdapter(adapter)) {
    return {
      ...baseAdapter,
      error: {
        label: 'Ошибка валидации структуры на сервере',
        value: adapter.errorInvalid,
        isJson: true,
      },
    };
  }

  const { is_enabled, error, status, uptime_sec, adapterType } = adapter;

  const { workTime, hours } = getWorkHours(uptime_sec);
  const errorValue = error && Object.keys(error).length ? error : null;

  const enabledStatusInfo = !isUndefined(is_enabled)
    ? ENABLED_STATUS_DICT[is_enabled]
    : null;

  const statusValue = getAdapterStatusDict(status)?.name ?? status;

  const info = {
    ...baseAdapter,
    workTime: {
      label: ADAPTER_LABELS.workTime,
      value: workTime,
      labelColor: !hours ? DARK_PINK : undefined,
    },
    status: {
      label: ADAPTER_LABELS.status,
      value: statusValue,
      color: isAdapterStatus(status) ? STATUS_COLORS[status] : NOT_ALLOWED,
      tooltip: getAdapterStatusDict(statusValue)?.dsc ?? UNDEFINED_STATUS.dsc,
    },
    adapterWork: {
      label: ADAPTER_LABELS.adapterWork,
      value: enabledStatusInfo?.name ?? null,
      color: enabledStatusInfo?.color,
    },
  };

  const errorParam = {
    label: ADAPTER_LABELS.error,
    value: errorValue,
    isJson: true,
  };

  if (isAdapterGo(adapter, adapterType)) {
    return {
      ...info,
      ...getAdapterGoParams(adapter.add_info.adapter),
      error: errorParam,
    };
  }

  return { ...info, error: errorParam };
};
