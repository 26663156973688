/* eslint-disable camelcase */
import dayjs from 'dayjs';

import { DATE_FORMAT } from '../../../constants/constants';
import {
  INITIAL_MARKERS,
  INITIAL_SETTINGS,
} from '../../../constants/initialSettingsConstants';
import rootStore from '../../../stores/rootStore/rootStore';
import { VALUE_TYPES } from '../../../ts/models/table.model';
import { getTimeWithTimeZone } from '../../TrafficLightDetailed/TlDetectors/IntancyGraph/TitleBtns/TimeLocalCard/helpers/getTimeWithTimeZone';
import { getParams } from '../AdminSystemObject/ASOSettings/ASOSetting/ASOSettingForm/helpers';
import { IBreadCrumbsItem } from '../BreadCrumbs/BreadCrumbs';
import { TGroupEntry } from '../models/adminTypes.model';

export const convertDate = (timestampInSeconds: number) => {
  const date = new Date(timestampInSeconds * 1000);

  const { dateFormatted } = getTimeWithTimeZone({
    dateJS: dayjs(date),
  });

  return dateFormatted;
};

const getGroupIds = (group: string[], groupDict: TGroupEntry[]) =>
  group.reduce((acc: number[], nameGroup) => {
    const groupItemId = Number(
      groupDict.find(([id, name]) => name === nameGroup)?.at(0)
    );

    !Number.isNaN(groupItemId) && acc.push(groupItemId);

    return acc;
  }, []);

export const createNewUserInfo = (
  values: any,
  webGroupsEntries: TGroupEntry[],
  njsGroupsEntries: TGroupEntry[]
) => {
  const newUserInfo = {
    login: values.login,
    password: values.password,
    name: values.name,
    description: values.dsc,
    email: values.email,
    groups: [
      ...getGroupIds(values.njs, njsGroupsEntries),
      ...getGroupIds(values.web, webGroupsEntries),
    ],
    isDelete: values.isDelete,
    isChange: values.isChange,
    isActive: values.isActive,
    regionsId: values.regions_id,
    start_access: values.start_access?.format(DATE_FORMAT) ?? null,
    end_access: values.end_access?.format(DATE_FORMAT) ?? null,
  };

  if (!newUserInfo.password) delete newUserInfo.password;

  return newUserInfo;
};

export const parsingNavigationPath = (
  key: string,
  menu: any,
  newBreadCrumbs: IBreadCrumbsItem[]
) => {
  menu.forEach((item: any) => {
    if (item.key !== key) {
      if (item.children) {
        return parsingNavigationPath(key, item.children, newBreadCrumbs);
      }

      return;
    }

    newBreadCrumbs.push({
      label: item.label,
      key: item.key,
    });
  });
};

export const createInitialValue = (user: any) => {
  if (!user.jprofile) return { ...INITIAL_SETTINGS, ...INITIAL_MARKERS };

  const initialValue = {} as any;

  for (const setting in user.jprofile) {
    initialValue[setting as keyof typeof INITIAL_MARKERS] =
      user.jprofile[setting];
  }

  initialValue['mapIconsSize'] = INITIAL_SETTINGS.mapIconsSize;
  initialValue['isClusters'] = INITIAL_SETTINGS.isClusters;
  initialValue['isWidgetLoad'] = INITIAL_SETTINGS.isWidgetLoad;

  return initialValue;
};

export const parseItemTitle = (title: string) => {
  let newTitle = '';

  if (title === 'Редактируемый') {
    newTitle = 'Возможность редактировать';

    return newTitle;
  }

  if (title === 'Удаляемый') {
    newTitle = 'Возможность удалять';

    return newTitle;
  }

  if (title === 'Статус') {
    newTitle = 'Статус пользователя';

    return newTitle;
  }
};

export const updateParams = (
  param: any,
  data: any,
  key: any,
  field: Record<string, any>
) => {
  const { validationParams } = rootStore.adminPanelStore;

  const val = param.value;

  const newValueKey = Object.keys(field)?.at(0);
  const newValue = Object.values(field)?.at(0);

  const inputType =
    validationParams && newValueKey
      ? getParams(validationParams, newValueKey)?.inputType
      : null;

  const currentValue =
    inputType === VALUE_TYPES.number ? Number(newValue) || null : newValue;

  if (!newValueKey) return null;

  const jsonbParam = {
    ...val.value,
    [key]: {
      ...data,
      ...val.value[key],
      [newValueKey]: currentValue,
    },
  };

  const getNewParam = (value: any) => ({
    ...param,
    value: {
      ...val,
      value: value,
    },
  });

  if (key === 'regions_id') {
    const newParam = getNewParam({ ...data });

    return newParam;
  }

  if (val.type === 'jsonb') {
    const newParam = getNewParam(jsonbParam);

    return newParam;
  }

  const newParam = getNewParam(data[key]);

  return newParam;
};
