import {
  IAdminModalType,
  IGroupsSelectConfig,
  IUserGroupsName,
  ITableConfigSettings,
} from '../components/Admin/models/adminTypes.model';

export const ADMIN_USERS_ACTION_BTNS_STYLE = {
  borderRadius: '6px',
  width: '40px',
  heigth: '40px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

export const ADMIN_USERS_TAG_STYLE = {
  border: 'none',
  display: 'flex',
  gap: '10px',
};

export const GROUPS_FILTERS = [
  {
    text: 'Операторы',
    value: 20,
  },
  {
    text: 'Администраторы',
    value: 17,
  },
  {
    text: 'В разработке',
    value: 0,
  },
];

export const GROUPS_SELECT_CONFIG = {
  mode: 'multiple',
  disabled: true,
  style: { width: '100%' },
  placeholder: 'Please select',
} as IGroupsSelectConfig;

export const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 9,
      offset: 5,
    },
  },
};

export const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

export const formItemLayoutASO = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 4 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 14 },
  },
};

export const UserFormItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 10 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 14 },
  },
};

export const WEB_GROUP_ENTRIES: [number, string][] = [
  [17, 'Администраторы web'],
  [21, 'Диспетчеры web'],
  [20, 'Операторы web'],
  [58, 'Инженеры web'],
  [89, 'Операторы web'],
  [90, 'Администраторы web'],
  [91, 'Диспетчеры web'],
];

export const NJS_GROUP_ENTRIES: [number, string][] = [
  [23, 'Администраторы njs'],
  [24, 'Диспетчеры njs'],
  [22, 'Операторы njs'],
  [25, 'Инженеры njs'],
];

export const ADMINS_GROUP_ID = NJS_GROUP_ENTRIES[0][0];
export const NJS_GROUPS_ID = 58;
export const WEB_GROUPS_ID = 20;

export const TEST_GROUP_NAME: [number, string][] = [
  [0, 'В разработке'],
  [16, 'В разработке'],
  [15, 'В разработке'],
  [12, 'В разработке'],
  [4, 'В разработке'],
  [3, 'В разработке'],
];

export const NOT_DELETE_GROUP = [];
export const USERS_GROUPS_NAME: IUserGroupsName = {
  ...Object.fromEntries(WEB_GROUP_ENTRIES),
  ...Object.fromEntries(NJS_GROUP_ENTRIES),
  ...Object.fromEntries(TEST_GROUP_NAME),
};

export const ADMIN_MODAL_WIDTH: IAdminModalType = {
  registerForm: '40%',
  userCard: '95%',
  delete: undefined,
  default: undefined,
  menuEdit: '50%',
  video: '70%',
};

export const CELLS_WIDTH = {
  xs: 100,
  s: 200,
  m: 250,
  l: 500,
};

export enum AdminTitleKeys {
  AccessNJSGroups = 'accessNJSGroups',
  AccessWebGroups = 'accessWebGroups',
  AccessWebUsers = 'accessWebUsers',
  DevControl = 'devControl',
  InfoSystem = 'infoSystem',
  InfoVersions = 'infoVersions',
  NjsInfo = 'njsInfo',
  NjsSettings = 'njsSettings',
  Organizations = 'organizations',
  Equipment = 'equipment',
  EputsSystem = 'eputsSystem',
  Region = 'region',
  TrafficLight = 'trafficLight',
  Video = 'video',
  Detector = 'detector',
  Meteo = 'meteo',
  System = 'system',
  Gis = 'gis',
}

export const NOT_DEVICES_TAB = [AdminTitleKeys.Gis];

export const ADMIN_TITLE: Record<AdminTitleKeys, string> = {
  accessNJSGroups: 'Настройки групп NJS',
  accessWebGroups: 'Настройки групп Web',
  accessWebUsers: 'Настройки пользователей',
  infoSystem: 'Информация о системе',
  infoVersions: 'Информация о версиях',
  njsInfo: 'Информация о сервере',
  njsSettings: 'Настройки сервера',
  organizations: 'Организации',
  equipment: 'Оборудование',
  eputsSystem: 'Все объекты систем ЕПУТС',
  region: 'Объекты региона в системе ЕПУТС',
  trafficLight: 'Светофорные объекты региона в системе ЕПУТС',
  video: 'Камеры видеонаблюдения региона в системе ЕПУТС',
  detector: 'Детекторы транспорта региона в системе ЕПУТС',
  gis: 'ГИС региона в системе ЕПУТС',
  meteo: 'Метеостанции региона в системе ЕПУТС',
  system: 'Управление системой',
  devControl: 'Администрирование устройств',
};

export enum AdminTypes {
  Versions = 'versions',
  Systems = 'system',
  NjsGroups = 'njsGroups',
  WebGroups = 'webGroups',
}

export const SELECT_OPTIONS = [
  {
    label: 'GET_CAMERAS_LIST',
    value: 'GET_CAMERAS_LIST',
  },
  {
    label: 'GET_CAMERAS_INFO',
    value: 'GET_CAMERAS_INFO',
  },
  {
    label: 'GET_VIOLATIONS',
    value: 'GET_VIOLATIONS',
  },
];

export const TABLE_CONFIG_SETTINGS: ITableConfigSettings = {
  height: {
    large: 1080,
    middle: 703,
  },
  users: {
    large: 10,
    middle: 7,
  },
  groups: {
    large: 4,
    middle: 3,
  },
  settings: {
    large: 7,
    middle: 5,
  },
};

export const VIDEO_SYSTEM_FORM_SETTINGS = [
  {
    label: 'Описание',
    name: 'dcs',
    rules: [{ required: true, message: 'Заполните описание' }],
  },
  {
    label: 'Широта',
    name: 'latitude',
    rules: [
      { required: true, message: 'Заполните широту' },
      () => ({
        validator(_: any, value: string) {
          const newReg = new RegExp(
            /^[-]?(([0-8]?[0-9])\.(\d+))|(90(\.0+)?)$/,
            'g'
          );

          if (!value || newReg.test(value)) {
            return Promise.resolve();
          }

          return Promise.reject('Не корректная широта');
        },
      }),
    ],
  },
  {
    label: 'Долгота',
    name: 'longitude',
    rules: [
      { required: true, message: 'Заполните долготу' },
      () => ({
        validator(_: any, value: string) {
          const newReg = new RegExp(
            /^[-]?((((1[0-7][0-9])|([0-9]?[0-9]))\.(\d+))|180(\.0+)?)$/,
            'g'
          );

          if (!value || newReg.test(value)) {
            return Promise.resolve();
          }

          return Promise.reject('Не корректная долгота');
        },
      }),
    ],
  },
];

export const UNKNOWN_STATUS = 'UNDEFINED';
