import { Card } from 'antd';
import { observer } from 'mobx-react-lite';
import type { CanvasFilterOptions } from 'ol-ext/filter/CanvasFilter';
import { FC, useEffect, useState } from 'react';
import { HiMap } from 'react-icons/hi';

import { OSM_ITEM } from '../../../apiGIS/constants/basemaps/sources';
import { MAP_BUTTONS_NAMES } from '../../../constants/uiComponentConstants';
import { IBaseMapItem } from '../../../stores/gisDataStore/gisDataStore.model';
import rootStore from '../../../stores/rootStore/rootStore';
import { MapButtonsCode } from '../../../ts/enums/userData';
import ButtonIco from '../../ui-kit/ButtonIco/ButtonIco';

import { BaseMapButton } from './BaseMapButton';
import BasemapPanelTitle from './BaseMapPanelTitle';
import BaseMapSettings from './BaseMapSettings';
import { BASEMAPS_CONTENT, LOCAL_STYLES, PLACEMENT_ITEM } from './constants';
import {
  applyBasemapFilter,
  getInitialBasemapSettings,
  getSubtitle,
} from './helpers';
import LoadingBaseMapButton from './LoadingBaseMapButton';

import styles from './BasemapsButton.module.scss';

const BasemapsButton: FC = () => {
  const { uiStore } = rootStore;
  const { mapProxy } = rootStore.mapDataStore;

  const { activeMapButton, mapButtonsTipsDelay, setKeyValue, isBasemapBtnGis } =
    uiStore;

  const { setGisValue, getBasemaps, basemaps } = rootStore.gisDataStore;
  const { hasAccess } = rootStore.userDataStore;

  const [isShowPanel, setShowPanel] = useState(false);
  const [viewMode, setViewMode] = useState(true);
  const [items, setItems] = useState<IBaseMapItem[]>([]);
  const [currentBasemap, setCurrentBasemap] = useState('OSM');
  const [basemapSettings, setBasemapSettings] = useState<CanvasFilterOptions>();

  const popOverProps = {
    placement: PLACEMENT_ITEM,
    tipsDelay: mapButtonsTipsDelay,
  } as const;

  const togglePanel = () => {
    const serverBasemaps = getBasemaps();

    const items = [OSM_ITEM].concat(serverBasemaps);

    setItems(items);
    setShowPanel(!isShowPanel);
  };

  useEffect(() => {
    if (activeMapButton !== MAP_BUTTONS_NAMES.BASEMAPS) {
      setShowPanel(false);
    }
  }, [activeMapButton]);

  useEffect(() => {
    if (isShowPanel) {
      setKeyValue('activeMapButton', MAP_BUTTONS_NAMES.BASEMAPS);
    }
  }, [isShowPanel, setKeyValue]);

  useEffect(() => {
    setItems(basemaps);
  }, [basemaps]);

  useEffect(() => {
    applyBasemapFilter(mapProxy, currentBasemap, basemapSettings);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [basemapSettings]);

  const selectBasemap = (item: IBaseMapItem) => {
    setGisValue('basemap', item);
    setCurrentBasemap(item.id || 'OSM');
    setBasemapSettings(getInitialBasemapSettings());
  };

  const toggleMode = () => {
    setViewMode(!viewMode);
  };

  const updateBasemapSettings = (data: CanvasFilterOptions) => {
    setBasemapSettings(data);
  };

  const isAccess = hasAccess(MapButtonsCode.BasemapsButton);

  if (!isAccess || !isBasemapBtnGis) return null;

  return (
    <>
      <ButtonIco
        isActive={isShowPanel}
        onClick={() => {
          togglePanel();
        }}
        popoverProps={{ ...popOverProps, content: BASEMAPS_CONTENT.content }}
      >
        <HiMap />
      </ButtonIco>

      {isShowPanel ? (
        <>
          <Card
            bordered={false}
            className={styles.baseMapsPanel}
            styles={{ body: LOCAL_STYLES.panelBodyStyle }}
          >
            <BasemapPanelTitle mode={viewMode} onClick={toggleMode} />

            <div className={styles.baseMapsContainer}>
              {!items.length ? (
                <LoadingBaseMapButton popOverItemProps={popOverProps} />
              ) : (
                items.map((item) => (
                  <BaseMapButton
                    key={item.id}
                    item={item}
                    subtitle={getSubtitle(item)}
                    currentBasemap={currentBasemap}
                    popOverItemProps={popOverProps}
                    onClick={() => {
                      selectBasemap(item);
                    }}
                  />
                ))
              )}
            </div>
            {!viewMode ? (
              <BaseMapSettings
                onSettingsChange={updateBasemapSettings}
                settings={basemapSettings}
              />
            ) : null}
          </Card>
        </>
      ) : null}
    </>
  );
};

export default observer(BasemapsButton);
