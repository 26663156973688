import { Collapse, type CollapseProps } from 'antd';
import { FC } from 'react';

import { UNAVAILABLE } from '../../constants/constants';

import styles from './InfoMain.module.scss';

interface UnavailableProps {
  items: CollapseProps['items'];
}

const UnavailableInfo: FC<UnavailableProps> = ({ items }) => (
  <div className={styles.wrapper}>
    <p className={styles.unavailable}>{UNAVAILABLE}</p>
    <Collapse items={items} />
  </div>
);

export default UnavailableInfo;
