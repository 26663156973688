import { observer } from 'mobx-react-lite';

import rootStore from '../../../../../../stores/rootStore/rootStore';
import FilterItem from '../FilterItem/FilterItem';

import styles from './FiltersBlock.module.scss';

const FiltersBlock = () => {
  const { statusFilteredMonitoring } = rootStore.monitoringPanelStore;

  if (!statusFilteredMonitoring.length) return <p>Нет примененных фильтров</p>;

  return (
    <div className={styles.filterBlock}>
      {statusFilteredMonitoring.map(({ status, len }) => (
        <FilterItem key={status} status={status} len={len} />
      ))}
    </div>
  );
};

export default observer(FiltersBlock);
