import { LoadProperties } from '../models/VideoCamera.model';

export const INITIAL_PROPERTIES: LoadProperties = {
  isLoad: true,
  textError: null,
};

export const ERROR_TEXT = 'Видеопоток отсутствует';
export const ERROR_CAMERA = 'Отсутствует привязка к камере';
export const ERROR_URL = 'Неккоректная ссылка на поток камеры';

export enum SceneErrorsType {
  NotLinkedAllZones = 'notLinkedAllZones',
  NotLinkedSomeZones = 'notLinkedSomeZones',
}

export const SCENE_ERRORS_CONTENT: Record<SceneErrorsType, string> = {
  notLinkedAllZones:
    'Привязка полос загруженности отсутствует. Поэтому будут отображаться зоны детекции (привязка полос осуществляется в модуле "Конструктор"). Для корректной работы обратитесь к администратору.',
  notLinkedSomeZones:
    'Некоторые полосы загруженности не привязаны к зонам детекции (привязка полос осуществляется в модуле "Конструктор"). Для корректной работы обратитесь к администратору.',
};
