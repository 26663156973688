/* eslint-disable jsx-a11y/no-autofocus */
import {
  Form,
  Button,
  Checkbox,
  notification,
  Input,
  type FormItemProps,
} from 'antd';
import { InputProps } from 'antd/lib';
import classNames from 'classnames';
import { FC, useState } from 'react';

import { ReactComponent as Lock } from '../../../assets/icons/ic_lock_2.svg';
import { ReactComponent as Profile } from '../../../assets/icons/ic_profile.svg';
import {
  LOGIN_RULES,
  MAIN_PASSWORD_RULES,
} from '../../../constants/formsConstants/formsRules';
import NOTICE from '../../../constants/notificationConstants';
import useValidateForm from '../../../hooks/formHooks/useValidateForm';
import useAuth from '../../../hooks/useAuth';
import { LoginErrorStatus } from '../constants/loginConstants';

import styles from './LoginForm.module.scss';

const BASE_INPUT_PROPS: InputProps = {
  allowClear: true,
  className: styles.input,
};

interface LoginFormFields {
  login: string;
  password: string;
  remember: boolean;
}

const LoginForm: FC = () => {
  const [form] = Form.useForm<LoginFormFields>();
  const { loginHandler, checkAuthHandler } = useAuth();
  const [errorStatus, setErrorStatus] = useState<N<LoginErrorStatus>>(null);
  const [authPending, setAuthPending] = useState(false);

  const { isReadySubmit } = useValidateForm(form);

  const onChange = () => setErrorStatus(null);

  const handleLogin = async () => {
    const { login, password, remember } = form.getFieldsValue();

    setAuthPending(true);

    const errorStatus = await loginHandler(
      login.trim(),
      password.trim(),
      remember
    );

    if (errorStatus) {
      setAuthPending(false);

      return setErrorStatus(errorStatus);
    }

    const isAccessCorrect = await checkAuthHandler();

    isAccessCorrect && notification.success(NOTICE.LOGIN_SUCCESSFUL);
    setAuthPending(false);
    setErrorStatus(null);
  };

  const isSubmitButtonDisabled = authPending || !isReadySubmit;
  const isAuthError = errorStatus === LoginErrorStatus.AuthError;

  const submitButtonStyle = classNames({
    [styles.btn]: true,
    [styles.disabled]: isSubmitButtonDisabled,
  });

  const inputFormItemProps: FormItemProps = {
    help: isAuthError ? 'Неверное имя пользователя или пароль' : undefined,
    validateStatus: isAuthError ? 'error' : (undefined as U<'error'>),
    className: styles.inputContainer,
  };

  return (
    <section className={styles.container}>
      <Form
        form={form}
        layout="vertical"
        className={styles.wrapper}
        disabled={authPending}
        onFieldsChange={onChange}
        onFinish={handleLogin}
      >
        <Form.Item
          label="Логин"
          name="login"
          {...inputFormItemProps}
          rules={LOGIN_RULES}
        >
          <Input
            {...BASE_INPUT_PROPS}
            disabled={authPending}
            prefix={<Profile className={styles.icon} />}
            autoFocus={true}
          />
        </Form.Item>
        <Form.Item
          label="Пароль"
          name="password"
          {...inputFormItemProps}
          rules={MAIN_PASSWORD_RULES}
        >
          <Input.Password
            {...BASE_INPUT_PROPS}
            prefix={<Lock className={styles.icon} />}
          />
        </Form.Item>
        <Form.Item name="remember" valuePropName="checked" initialValue={true}>
          <Checkbox defaultChecked>Запомнить меня</Checkbox>
        </Form.Item>
        <Button
          className={submitButtonStyle}
          type="primary"
          htmlType="submit"
          loading={authPending}
          disabled={isSubmitButtonDisabled}
        >
          Войти в систему
        </Button>
      </Form>
    </section>
  );
};

export default LoginForm;
