import { AdminTitleKeys } from '../../../constants/adminConstants';

const { TrafficLight, Detector, Meteo, Video, Gis } = AdminTitleKeys;

const SUB_SYSTEMS = [TrafficLight, Detector, Meteo, Video, Gis];

const getIsSubSystem = (adminTableType: N<string>) => {
  return SUB_SYSTEMS.some((el) => el === adminTableType);
};

export default getIsSubSystem;
