import { Tag } from 'antd';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import { ExternalLink } from '../../../../../../../api/devices/model/devices.model';
import rootStore from '../../../../../../../stores/rootStore/rootStore';
import Popover from '../../../../../../ui-kit/Popover/Popover';

import getStatusLinksInfo from './helpers/getStatusLinksInfo';

import styles from './SourceStatus.module.scss';

interface SourceStatusProps {
  status_id: ExternalLink['status_id'];
}

const SourceStatus: FC<SourceStatusProps> = ({ status_id }) => {
  const { interfaceTipsDelay } = rootStore.uiStore;

  const { name, tooltip, color } = getStatusLinksInfo(status_id);

  return (
    <span className={styles.tag}>
      <Popover content={tooltip} tipsDelay={interfaceTipsDelay}>
        <Tag color={color}>{name}</Tag>
      </Popover>
    </span>
  );
};

export default observer(SourceStatus);
