import { observer } from 'mobx-react-lite';
import { FC, RefObject } from 'react';

import rootStore from '../../../stores/rootStore/rootStore';
import { getTimeZones } from '../../TrafficLightDetailed/TlDetectors/IntancyGraph/TitleBtns/TimeLocalCard/helpers/getTimeZone';
import useBreakpoint, {
  BreakPoints,
} from '../../ui-kit/Camera/hooks/useBreakpoint';
import { VideoCameraProps } from '../models/VideoCamera.model';

import TimeInfo from './TimeInfo/TimeInfo';

import styles from './CameraTitle.module.scss';

const FONT_SIZES: Record<BreakPoints, number> = {
  XXS: 3,
  XS: 5,
  S: 7,
  M: 9,
  L: 10,
};

export interface ICameraTitle {
  camera: U<VideoCameraProps>;
  isFullTitle?: boolean;
  timeZone?: N<number>;
  streamName?: N<string>;
  cameraWrapperRef: RefObject<HTMLDivElement>;
}
const CameraTitle: FC<ICameraTitle> = ({
  camera,
  isFullTitle = true,
  timeZone = getTimeZones().timeZoneRegion,
  streamName,
  cameraWrapperRef,
}) => {
  const { isPanel } = rootStore.uiStore;

  const { breakPoint } = useBreakpoint({
    cameraWrapRef: cameraWrapperRef,
    isPanel,
  });

  if (!camera || !breakPoint || !camera?.deviceId) return null;

  const { caption, dsc, deviceId } = camera;

  return (
    <div
      id="camera-title"
      data-testid="wrapper"
      className={styles.wrapper}
      style={{ fontSize: `${FONT_SIZES[breakPoint]}px` }}
    >
      <div className={styles.mainContent}>
        <p>{caption}</p>
        {isFullTitle && (
          <>
            <p>{dsc}</p>
            <p>Внутренний ID: {deviceId}</p>
            <TimeInfo timeZone={timeZone} />
          </>
        )}
      </div>
      {streamName ? (
        <div className={styles.additionalContent} data-testid="thread-name">
          <p>Поток: {streamName}</p>
        </div>
      ) : null}
    </div>
  );
};

export default observer(CameraTitle);
