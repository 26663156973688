import { Form } from 'antd';
import { type FormInstance } from 'antd/lib';
import { useEffect, useState } from 'react';

const useValidateForm = (form: FormInstance) => {
  const [isReadySubmit, setIsReadySubmit] = useState(false);

  const values = Form.useWatch([], form);

  useEffect(() => {
    form.validateFields({ validateOnly: true }).then(
      () => {
        setIsReadySubmit(true);
      },
      () => {
        setIsReadySubmit(false);
      }
    );
  }, [form, values]);

  return { isReadySubmit, values };
};

export default useValidateForm;
