import { useEffect } from 'react';

import {
  DeviceFormType,
  SourceDisplayType,
} from '../../../../../stores/adminPanelStore/constants/constants';
import rootStore from '../../../../../stores/rootStore/rootStore';

const { EditForm } = DeviceFormType;
const { SourceEditForm } = SourceDisplayType;

const useDeviceAdminCard = () => {
  const {
    isAdminDetailed,
    selectedDevice,
    selectedSource,
    isEditForm,
    deviceFormType,
    setAdminsData,
    clearDeviceForm,
  } = rootStore.adminPanelStore;

  //clear all
  useEffect(() => {
    return () => {
      clearDeviceForm();
    };
  }, [isAdminDetailed, clearDeviceForm]);

  // set edit form if choose device
  useEffect(() => {
    if (!selectedDevice) return;

    setAdminsData('deviceFormType', EditForm);
  }, [selectedDevice, setAdminsData]);

  // set edit source form if choose source
  useEffect(() => {
    selectedSource && setAdminsData('sourceDisplayType', SourceEditForm);
  }, [selectedSource, setAdminsData]);

  const handleClick = () => {
    setAdminsData('deviceFormType', null);

    isEditForm && setAdminsData('selectedDevice', null);
  };

  const isShowArrow = deviceFormType !== null;

  return { handleClick, isShowArrow };
};

export default useDeviceAdminCard;
