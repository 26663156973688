import { Descriptions } from 'antd';
import { observer } from 'mobx-react-lite';

import rootStore from '../../../../stores/rootStore/rootStore';
import { System } from '../../../../ts/enums/enums';
import DefaultChecked from '../../../ui-kit/DefaultChecked/DefaultChecked';

import useLinkedTL from './hooks/useLinkedTL';

import styles from './GeneralDtInfo.module.scss';

const APP_PATH = '.TrafficLightDetailed.TlDetectors.GeneralDtInfo';

const GeneralDtInfo = () => {
  const { detectors, tls } = rootStore.mapDataStore;
  const { infoData } = rootStore.uiStore;
  const { activeDtId } = rootStore.detailedStore;

  const isTL = infoData?.system === System.Lights;
  const dtId = isTL ? activeDtId : infoData?.id ?? null;

  const { dtInfo } = useLinkedTL({
    detectors,
    dtId,
    tls,
    appPath: APP_PATH,
  });

  if (!infoData) return null;

  return (
    <div className={styles.container}>
      <DefaultChecked
        isEmptyData={!dtInfo.length}
        noDataProps={{ textError: 'Информация не найдена' }}
      >
        <Descriptions
          items={dtInfo}
          column={2}
          layout="vertical"
          className={styles.description}
        />
      </DefaultChecked>
    </div>
  );
};

export default observer(GeneralDtInfo);
