import { ExternalLink } from '../../../../../../../../api/devices/model/devices.model';
import { LibraryKeys } from '../../../../../../../../api/libraries/enums/enums';
import { findById } from '../../../../../../../../helpers/findBy';
import { getObjectEntries } from '../../../../../../../../helpers/getObjectEntries';
import rootStore from '../../../../../../../../stores/rootStore/rootStore';
import { STATUS_COLORS } from '../../../../../constants/constants';

const { ExternalLinksStatus } = LibraryKeys;

const DEFAULT_STATUS = {
  name: 'Статус неизвестен',
  tooltip: 'Нет информации по текущему статусу',
  color: undefined,
};

const getStatusLinksInfo = (statusId: ExternalLink['status_id']) => {
  const externalLinksDict = rootStore.uiStore.dictionaries[ExternalLinksStatus];

  const statusItem = findById(externalLinksDict, statusId);

  if (!statusItem) return DEFAULT_STATUS;

  const { name, dsc, mnemocode } = statusItem;

  const color =
    getObjectEntries(STATUS_COLORS)
      .find(([key]) => key === mnemocode)
      ?.at(1) ?? DEFAULT_STATUS.color;

  return {
    name: name ?? DEFAULT_STATUS.name,
    tooltip: dsc ?? DEFAULT_STATUS.tooltip,
    color: color,
  };
};

export default getStatusLinksInfo;
