import { Form } from 'antd';
import { useEffect } from 'react';

import { AddFormFields } from '../../../../models/DeviceAdmin.model';
import { FormKeys } from '../../constants/constants';
import { getCoordsTooltip } from '../../helpers/coordHelpers';
import getWarningNotify from '../../helpers/getWarningNotify';

interface UseDeviceAddCardProps {
  initialProps?: AddFormFields;
}

const useDeviceAddCard = ({ initialProps }: UseDeviceAddCardProps) => {
  const [form] = Form.useForm<AddFormFields>();

  const warningNotify = getWarningNotify();

  const tooltipCoordLongitude = getCoordsTooltip(null, FormKeys.Longitude);
  const tooltipCoordLatitude = getCoordsTooltip(null, FormKeys.Latitude);

  useEffect(() => {
    initialProps && form.setFieldsValue(initialProps);
  }, [form, initialProps]);

  return { form, warningNotify, tooltipCoordLongitude, tooltipCoordLatitude };
};

export default useDeviceAddCard;
