import classNames from 'classnames';
import { observer } from 'mobx-react-lite';

import rootStore from '../../../../../stores/rootStore/rootStore';
import FiltersClear from '../DisplayBtns/FiltersClear/FiltersClear';
import FilterBlock from '../FilterBlock/FilterBlock';

import FiltersBlock from './FiltersBlock/FiltersBlock';

import styles from './FiltersPanel.module.scss';

const FiltersPanel = () => {
  const { statusFilteredMonitoring } = rootStore.monitoringPanelStore;

  const isEmptyData = !statusFilteredMonitoring.length;

  const containerStyle = classNames({
    [styles.container]: true,
    [styles.empty]: isEmptyData,
  });

  return (
    <div className={containerStyle}>
      <FilterBlock />
      <FiltersClear />
      <FiltersBlock />
    </div>
  );
};

export default observer(FiltersPanel);
