import DeviceAdminCard from './DeviceAdminCard/DeviceAdminCard';
import SourceEdit from './SourceEdit/SourceEdit';

import styles from './DeviceAdmin.module.scss';

const DeviceAdmin = () => {
  return (
    <div className={styles.container}>
      <DeviceAdminCard />
      <SourceEdit />
    </div>
  );
};

export default DeviceAdmin;
