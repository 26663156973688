import classNames from 'classnames';
import { uniqueId } from 'lodash';
import { observer } from 'mobx-react-lite';

import DefaultChecked from '../../../../ui-kit/DefaultChecked/DefaultChecked';
import { ERROR_TEXT, LIST_SIZE } from '../../constants/constants';

import useSourceInfo from './hooks/useSourceInfo';
import SourceItem from './SourceItem/SourceItem';

import styles from './SourceContainer.module.scss';

const SourceContainer = () => {
  const { sources, isError, isLoading } = useSourceInfo();

  const textError = isError ? ERROR_TEXT.ERROR : ERROR_TEXT.NO_DATA;

  const containerStyle = classNames({
    [styles.container]: true,
    [styles.bottomBlock]: sources && sources.length > LIST_SIZE,
  });

  return (
    <DefaultChecked
      isEmptyData={!sources?.length || isError}
      isLoading={isLoading}
      noDataProps={{ textError }}
    >
      <div className={containerStyle}>
        {sources?.map((el) => (
          <SourceItem item={el} key={uniqueId('card_')} />
        )) ?? null}
      </div>
    </DefaultChecked>
  );
};

export default observer(SourceContainer);
