import { Form } from 'antd';
import { FormInstance } from 'antd/lib';
import { isEqual } from 'lodash';
import { useEffect, useState } from 'react';

import api from '../../../../../../../api';
import eventBus from '../../../../../../../eventBus';
import rootStore from '../../../../../../../stores/rootStore/rootStore';
import notification from '../../../../../../ui-kit/Notification/Notification';
import {
  isExternalCreateProps,
  SourceFormFields,
} from '../../../../models/DeviceAdmin.model';
import preparedDataToConfirm from '../helpers/preparedDataToConfirm';

const { createSource, updateSource } =
  api.devices.externalLinks.externalLinksApi;

const APP_PATH =
  'Admin.DeviceAdmin.SourceEdit.SourceForm.ConfirmButtons.hooks.useConfirm';

const useConfirm = (form: FormInstance, initialProps: U<SourceFormFields>) => {
  const { selectedDevice, selectedSource, setAdminsData } =
    rootStore.adminPanelStore;

  const [isLoading, setIsLoading] = useState(false);
  const [isReadySubmit, setIsReadySubmit] = useState(false);
  const [isChangeForm, setIsChangeForm] = useState(false);

  const values = Form.useWatch([], form);

  const isEdit = !!initialProps;

  const handleSubmit = () => {
    form
      .validateFields()
      .then(async (values) => {
        if (!selectedDevice) return;

        setIsLoading(true);

        const data = preparedDataToConfirm(values, isEdit);

        if (!data) {
          notification.error('FORM_ERROR');

          return console.error('Ошибка обработки полей формы');
        }

        const res = isExternalCreateProps(data)
          ? await createSource(selectedDevice.id, data, APP_PATH)
          : await updateSource(
              selectedDevice.id,
              selectedSource,
              data,
              APP_PATH
            );

        if (!res) return;

        notification.success(
          !isEdit ? 'EXTERNAL_SOURCE_ADD' : 'EXTERNAL_SOURCE_UPDATE'
        );
        form.resetFields();
        setIsChangeForm(false);

        if (isEdit) {
          eventBus.updateSourceItem.broadcast();
          setAdminsData('selectedSource', res.data.id);

          return;
        }

        setAdminsData('sourceDisplayType', null);

        setTimeout(() => {
          eventBus.updateSourcesList.broadcast();
        }, 100);
      })
      .catch(() => {
        notification.error('ERROR');
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    form.validateFields({ validateOnly: true }).then(
      () => {
        setIsReadySubmit(true);
      },
      () => {
        setIsReadySubmit(false);
      }
    );
  }, [form, values]);

  useEffect(() => {
    const isEdit = !!initialProps;

    values &&
      setIsChangeForm(
        !isEqual(
          preparedDataToConfirm(initialProps, isEdit),
          preparedDataToConfirm(values, isEdit)
        )
      );
  }, [values, initialProps]);

  const isNoChanges = isEdit && !isChangeForm;

  const isDisabledConfirm = !isReadySubmit || isNoChanges;

  return { isLoading, isDisabledConfirm, isEdit, handleSubmit };
};

export default useConfirm;
