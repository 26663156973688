import { Tag } from 'antd';
import { FC } from 'react';

import { getTimeWithTimeZone } from '../../TrafficLightDetailed/TlDetectors/IntancyGraph/TitleBtns/TimeLocalCard/helpers/getTimeWithTimeZone';
import { getTimeZones } from '../../TrafficLightDetailed/TlDetectors/IntancyGraph/TitleBtns/TimeLocalCard/helpers/getTimeZone';
import NoData from '../../ui-kit/Errors/NoData/NoData';
import {
  CameraProps,
  VideoCameraProps,
  VideoSourceItem,
} from '../models/VideoCamera.model';

import styles from './CameraInfo.module.scss';

interface ICameraInfo {
  camera: U<VideoCameraProps>;
  timeZone?: N<number>;
  activeStream: N<VideoSourceItem>;
}

type Keys = keyof CameraProps;

type InfoObj = Record<Keys, string>;

const INFO_KEYS: InfoObj = {
  dsc: 'Комментарий: ',
  caption: 'Название: ',
  deviceId: 'Внутренний ID: ',
  longitude: 'Долгота: ',
  latitude: 'Широта: ',
};

const CameraInfo: FC<ICameraInfo> = ({
  camera,
  timeZone = getTimeZones().timeZoneRegion,
  activeStream,
}) => {
  if (!camera || !activeStream) return <NoData isNeedBackground={false} />;

  const isCorrectTimeZone = timeZone !== null;
  const time = isCorrectTimeZone
    ? getTimeWithTimeZone({
        timeZone: timeZone,
      })
    : null;

  return (
    <div className={styles.wrapper} data-testid="cameraInfo">
      {time !== null && isCorrectTimeZone ? (
        <p data-testid="time">
          <span>Местное время:</span> {time.dateFormatted} GMT
          {timeZone > 0 ? '+' + timeZone : '-' + timeZone}
        </p>
      ) : null}
      {(Object.keys(INFO_KEYS) as Keys[]).map((key, i) => (
        <p key={i}>
          <span>{INFO_KEYS[key]}</span>
          {camera[key]}
        </p>
      ))}
      <p>
        <span>Поток:</span> {activeStream.name}
      </p>
      <p>
        <span>URL:</span>{' '}
        {activeStream.url || <Tag color="error"> Неккоректный маршрут </Tag>}
      </p>
    </div>
  );
};

export default CameraInfo;
