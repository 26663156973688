import { Form, Select } from 'antd';
import { type Rule } from 'antd/es/form';
import { FormInstance } from 'antd/lib';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import { SourceFormFields } from '../../../models/DeviceAdmin.model';
import { FormSourceKeys } from '../constants/constants';

import useExtDevices from './hooks/useExtDevices';

import styles from './ExternalDevicesSelect.module.scss';

const { DeviceInfo } = FormSourceKeys;

interface ExternalDevicesSelectProps {
  form: FormInstance<SourceFormFields>;
  getSourceRules: (value: FormSourceKeys) => U<Rule[]>;
  isShow: boolean;
}

const ExternalDevicesSelect: FC<ExternalDevicesSelectProps> = ({
  form,
  getSourceRules,
  isShow,
}) => {
  const values = Form.useWatch([], form);

  const { options, isLoading } = useExtDevices(values?.systemId);

  if (!isShow) return null;

  return (
    <Form.Item
      label="Внешнее устройство"
      name={DeviceInfo}
      rules={getSourceRules(DeviceInfo)}
    >
      <Select
        placeholder="Выберите внешнее устройство"
        loading={isLoading}
        options={options ?? undefined}
        allowClear
        disabled={!values?.systemTypeId || !values?.systemId}
        popupClassName={styles.dropdown}
      />
    </Form.Item>
  );
};

export default observer(ExternalDevicesSelect);
