export const FLASH_FEATURE_TIMEOUT = 1500;

export const MEASUREMENTS_CONTENT = {
  content: 'Измерения',
  title: 'Измерения',
  pointMeasureTooltip: 'Измерение координат [широта, долгота]',
  polylineMeasureTooltip: 'Измерение расстояния [км]',
  polygonMeasureTooltip: 'Измерение площади [км2]',
  clearMeasurements: 'Удаление всех измерений с карты',
  measurementsResults: 'Выполненные измерения',
  noMeasurements: 'Измерения отсутствуют ...',
};
