import { observer } from 'mobx-react-lite';

import rootStore from '../../../../stores/rootStore/rootStore';
import DefaultChecked from '../../../ui-kit/DefaultChecked/DefaultChecked';
import { EMPTY_SOURCE_TITLE } from '../constants/constants';
import Header from '../Header/Header';

import useSourceEdit from './hooks/useSourceEdit';
import SourceSwitcher from './SourceSwitcher/SourceSwitcher';

import styles from './SourceEdit.module.scss';

const SourceEdit = () => {
  const { selectedDevice } = rootStore.adminPanelStore;

  const { isShowArrow, handleClick } = useSourceEdit();

  return (
    <div className={styles.block}>
      <Header
        title="Источники"
        handleClick={handleClick}
        isShowArrow={isShowArrow}
      />
      <DefaultChecked
        isEmptyData={!selectedDevice}
        noDataProps={{ textError: EMPTY_SOURCE_TITLE }}
      >
        <SourceSwitcher />
      </DefaultChecked>
    </div>
  );
};

export default observer(SourceEdit);
