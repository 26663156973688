import { Button } from 'antd';
import { observer } from 'mobx-react-lite';

import rootStore from '../../../../../../../stores/rootStore/rootStore';

import useDeleteDevice from './useDeleteDevice/useDeleteDevice';

import styles from './DeleteButton.module.scss';

const DeleteButton = () => {
  const { selectedDevice } = rootStore.adminPanelStore;

  const { handleDeleteDevice } = useDeleteDevice();

  return (
    <Button
      type="primary"
      danger
      className={styles.delete}
      onClick={handleDeleteDevice}
      disabled={!selectedDevice}
    >
      Удалить
    </Button>
  );
};

export default observer(DeleteButton);
