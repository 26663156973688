import { AddFormFields } from '../../../components/Admin/DeviceAdmin/models/DeviceAdmin.model';
import rootStore from '../../../stores/rootStore/rootStore';
import { Path } from '../../api.model';
import { getAppPathHeaders } from '../../helpers/getAppPathHeaders';
import http from '../../http';
import tryCatchWrapper from '../../tryCatchWrapper';
import { DEVICES } from '../constants/constants';
import { Device } from '../model/devices.model';

const manageDeviceApi = {
  createDevice: (props: AddFormFields, appPath: Path) =>
    tryCatchWrapper(async () => {
      const { regionData } = rootStore.uiStore;

      if (!regionData) return;

      const res = await http.post<Device>(
        DEVICES,
        {
          ...props,
          region_id: regionData.id,
        },
        {
          headers: getAppPathHeaders(appPath),
        }
      );

      return res;
    }),

  updateDevice: (id: number, props: AddFormFields, appPath: Path) =>
    tryCatchWrapper(async () => {
      const { regionData } = rootStore.uiStore;

      if (!regionData) return;

      const res = await http.put<Device>(
        `${DEVICES}/${id}`,
        {
          ...props,
          region_id: regionData.id,
        },
        {
          headers: getAppPathHeaders(appPath),
        }
      );

      return res;
    }),

  deleteDevice: (id: number, appPath: Path) =>
    tryCatchWrapper(
      async () => {
        const res = await http.delete(`${DEVICES}/${id}`, {
          headers: getAppPathHeaders(appPath),
        });

        return res;
      },
      {
        errorMessage: 'DELETE_DEVICE_ERROR',
      }
    ),
};

export default manageDeviceApi;
