export enum Role {
  admin = 'admin',
  dispatcher = 'dispatcher',
  operator = 'operator',
  engineer = 'engineer',
  admin_root = 'admin_root',
}

export enum SidebarCode {
  Cartography = '1210000000-20000',
  Reports = '1220000000-20000',
  ModulesSubsystems = '1230000000-20000',
  Constructor = '1240000000-20000',
  Admin = '1260000000-20000',
  Profile = '1270000000-20000',
  Theme = '1280000000-20000',
  Logo = '1290000000-20000',
  Video = '1300000000-20000',
  Testing = '1211000000-20000',
  CoordControl = '1310000000-20000',
  GroupDispatchControl = '1291000000-20000',
  Weather = '1500000000-20000',
}

export enum VideoPanelCode {
  WebRTC = '1301000000-20000',
  Mjpeg = '1302000000-20000',
}

export enum TLDetailedCode {
  isDetailed = '1421000000-20000',
  MainInfo = '1411000000-20000',
  Passport = '1412000000-20000',
  Detectors = '1413000000-20000',
  SignalProgramSettings = '1414000000-20000',
  Cameras = '1415000000-20000',
  PhaseScheme = '1416000000-20000',
  Management = '1417000000-20000',
  Planning = '1410200000-20000',
  ManagementPlan = '1418000000-20000',
  StatusJournal = '1419000000-20000',
  ServiceJournal = '1410300000-20000',
  ManageJournal = '1410100000-20000',
  TrafficModel = '1419200000-20000',
}

export const DocumentsCode = '1419100000-20000';

export enum TLDocumentsCode {
  uploadBtn = '1419100001-20000',
  deleteBtn = '1419100002-20000',
  editBtn = '1419100003-20000',
}

export const MapCode = '301000000-20000';

export enum MapButtonsCode {
  TrafficLightsButton = '301000000-20000',
  DetectorsButton = '302000000-20000',
  VideocamsButton = '303000000-20000',
  SpecialTransportButton = '304000000-20000',
  PublicTransportButton = '305000000-20000',
  RoadCongestionButton = '306000000-20000',
  СarAccidentButton = '307000000-20000',
  RoadWorksButton = '308000000-20000',
  MeteoStationsButton = '309000000-20000',
  LayersButton = '309100000-20000',
  TrafficButton = '309200000-20000',
  MeasurementsButton = '309400000-20000',
  BasemapsButton = '309300000-20000',
  SearchButton = '309600000-20000',
  MeteoDataButton = '309700000-20000',
  TrafficGraphButton = '309800000-20000',
}

export enum CartographyTabsCode {
  MapTab = '1210100000-20000',
  RoadTab = '1210200000-20000',
  SubsystemsTab = '1210300000-20000',
  ParamsTab = '1210400000-20000',
  EventsTab = '1210500000-20000',
}

export enum ExternalServicesCode {
  ITSEffectiveControl = '1231000000-20000',
  VideoAnalytics = '1232000000-20000',
  EcoMonitoring = '1233000000-20000',
}

export enum UserRoleScales {
  admin_root = 10,
  admin = 4,
  engineer = 3,
  dispatcher = 2,
  operator = 1,
}

export const WEB_DEVELOPER_CODE = '1601000000-20000';

export type AccessCode =
  | SidebarCode
  | TLDetailedCode
  | TLDocumentsCode
  | MapButtonsCode
  | CartographyTabsCode
  | VideoPanelCode
  | ExternalServicesCode
  | typeof DocumentsCode
  | typeof WEB_DEVELOPER_CODE;
