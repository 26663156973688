import { Descriptions } from 'antd';
import { DescriptionsItemType } from 'antd/lib/descriptions';
import { useMemo } from 'react';

import rootStore from '../../../../../stores/rootStore/rootStore';
import { WEB_DEVELOPER_CODE } from '../../../../../ts/enums/userData';
import { TDeviceInfo } from '../../../../../ts/models/mapObject.model';
import PointUdsName from '../../PointUdsName/PointUdsName';

const usePointCollapseItem = (deviceInfo: TDeviceInfo) => {
  const { hasAccess } = rootStore.userDataStore;
  const { pointsUds } = rootStore.mapDataStore;

  const isDeveloper = useMemo(() => hasAccess(WEB_DEVELOPER_CODE), [hasAccess]);

  const pointItems = useMemo(() => {
    const { pointUdsName, pointUdsUid } = deviceInfo;

    const point = isDeveloper
      ? pointsUds.find(
          ({ deviceInfo }) => deviceInfo.pointUdsUid === pointUdsUid
        )
      : undefined;

    const items: DescriptionsItemType[] = [
      {
        key: '1',
        label: 'Имя',
        children: <PointUdsName pointUdsName={pointUdsName} point={point} />,
      },
      {
        key: '2',
        label: 'УИ',
        children: pointUdsUid,
      },
    ];

    const devItem = {
      key: '3',
      label: point ? 'Динамический УИ' : 'Для разработчика',
      children: point
        ? point.deviceInfo?.deviceId ?? 'Отсутствует'
        : 'Точка УДС не существует, хотя указана в устройстве!',
    };

    isDeveloper && items.push(devItem);

    return items;
  }, [isDeveloper, pointsUds, deviceInfo]);

  return {
    label: 'Точка УДС',
    children: <Descriptions items={pointItems} column={1} />,
  };
};

export default usePointCollapseItem;
