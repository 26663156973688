import { isUndefined } from 'lodash';

import { ExternalLink } from '../../../../../../api/devices/model/devices.model';
import createArray from '../../../../../../helpers/createArray';
import { LIST_SIZE } from '../../../constants/constants';
import { CardsContent } from '../../../models/DeviceAdmin.model';

const transformSourcesData = (data: U<ExternalLink[]>): CardsContent => {
  if (!data?.length) return createArray(LIST_SIZE, { isEmpty: true });

  const arraySize = Math.ceil(data.length / 6) * LIST_SIZE;

  const newData = createArray(arraySize, { isEmpty: true });

  return newData.map((el, i) => {
    const isEmpty = isUndefined(data.at(i));

    return isEmpty ? el : data[i];
  });
};

export default transformSourcesData;
