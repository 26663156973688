import { AddFormFields } from '../../../../../models/DeviceAdmin.model';

const transformValues = (values: AddFormFields): AddFormFields => {
  return {
    ...values,
    longitude: Number(values.longitude),
    latitude: Number(values.latitude),
  };
};

export default transformValues;
