import { TDeviceInfo } from '../../../ts/models/mapObject.model';
import { IDefaultChecked } from '../../ui-kit/DefaultChecked/DefaultChecked';

export type DefaultCheckedProps = Omit<IDefaultChecked, 'children'>;

export type CameraProps = Partial<
  Pick<TDeviceInfo, 'dsc' | 'caption' | 'longitude' | 'latitude' | 'deviceId'>
>;

export enum StreamType {
  Iframe = 'iframe',
  Mjpeg = 'mjpeg',
  Jpeg = 'jpeg',
  Rtsp = 'rtsp',
  Ptz = 'ptz',
}

export interface VideoSourceItem {
  name: string;
  url: string;
  streamType: StreamType;
  defaultView: boolean;
}
export interface VideoCameraProps extends CameraProps {
  isMapAction: boolean;
}

export interface IVideoCamera {
  cameraData: VideoCameraProps;
  videoSources: VideoSourceItem[];
  timeZone?: U<number>;
}

export interface LoadProperties {
  isLoad: boolean;
  textError: N<string>;
}

export interface VideoActions {
  isUpdateBtn: boolean;
  isPinBtn: boolean;
  isInfoBtn: boolean;
  isFullScreen: boolean;
  isDtLanesBtn: boolean;
  isThreadsBtn: boolean;
  isMenuBtn: boolean;
}

export type HandleSetStream = (
  stream: U<VideoSourceItem>,
  isCorrectCam: boolean
) => void;
