import { useQuery } from '@tanstack/react-query';
import { type DefaultOptionType } from 'antd/es/select';
import { useEffect } from 'react';

import api from '../../../../../../../api';
import { TDevicesIds } from '../../../../../../../constants/constants';
import {
  DEFAULT_STALE_TIME,
  DEFAULT_UPDATE_DEVICE_ADMIN,
  QUERY_KEY,
} from '../../../../../../../constants/queryConstants';
import eventBus from '../../../../../../../eventBus';
import rootStore from '../../../../../../../stores/rootStore/rootStore';
import { isActualDeviceType } from '../../../../../../../ts/typeGuards/mapObjectsTG';
import { SEPARATE } from '../../../../../../Panel/ScriptsControl/SelectModule/DispatchGroupControl/SelectItem/helpers/helpers';
import { getOptionsSelect } from '../../helpers/optionHelpers';

const { DEVICES_INFO } = QUERY_KEY;
const { fetchDeviceListInfoByRegion } = api.devices.info.deviceInfoApi;

const APP_PATH =
  'Admin.DeviceAdmin.DeviceAdminCard.SelectDevice.SelectComponent.hooks.useSelectComponent';

const useSelectComponent = () => {
  const { regionData } = rootStore.uiStore;
  const { selectedDevice, setAdminsData } = rootStore.adminPanelStore;

  const { data, isLoading, isError, refetch } = useQuery({
    queryKey: [DEVICES_INFO, regionData, selectedDevice],
    queryFn: async () => {
      if (!regionData) return null;

      const res = await fetchDeviceListInfoByRegion(regionData.id, APP_PATH);

      return { options: getOptionsSelect(res), data: res };
    },
    refetchInterval: DEFAULT_UPDATE_DEVICE_ADMIN,
    staleTime: DEFAULT_STALE_TIME,
    enabled: !!regionData,
  });

  useEffect(() => {
    const unsubscribe = eventBus.updateFormDevice.subscribe(refetch);

    return () => {
      unsubscribe();
    };
  }, [refetch]);

  const onChange = (value: N<string>, { id: itemInfo }: DefaultOptionType) => {
    if (!value) return setAdminsData('selectedDevice', null);

    const [id, deviceTypeId] = itemInfo.split(SEPARATE);

    const idNum = Number(id);
    const typeId = Number(deviceTypeId) as TDevicesIds;

    if (isNaN(idNum) || !isActualDeviceType(typeId)) {
      console.error(
        `Неккоректные данные по устройству id = ${id}, deviceTypeId = ${typeId}`
      );

      return;
    }

    setAdminsData('selectedSource', null);
    setAdminsData('selectedDevice', {
      id: idNum,
      deviceTypeId: typeId,
    });
  };

  const options = data?.options;

  return { options, isLoading, isError: !options || isError, onChange };
};

export default useSelectComponent;
