/* eslint-disable camelcase */
import { isNumber } from 'lodash';

import { LibraryKeys } from '../../../../../api/libraries/enums/enums';
import { NormilizeData } from '../../../../../api/mapObjects/detectors/data/model/detectors.model';
import { findBy } from '../../../../../helpers/findBy';
import { neverTypeCheck } from '../../../../../helpers/neverTypeCheck';
import rootStore from '../../../../../stores/rootStore/rootStore';
import { Detector } from '../../../../../ts/models/mapObject.model';
import { LaneParams } from '../../../../../ts/models/MapObjects/detectors.model';
import { DirectionsTypes } from '../../../../InfoPanel/SystemsInfo/PopupDetector/PopupDetector.model';
import {
  FilterData,
  FilterNestedItem,
} from '../../../../ui-kit/Chart/models/chart.model';
import {
  DIRECTIONS_ICONS,
  FILTERED_INFO,
  FilteredTypes,
  GraphTypes,
  NormativeIds,
  VEHICLES_ICONS,
} from '../../constants/constants';
import NormativeIco from '../NormativeIco/NormativeIco';

const { OnCamera, OutCamera, Undefined } = DirectionsTypes;
const { ChartFields } = LibraryKeys;

const getLanesData = (
  laneParams: U<LaneParams[]>,
  parentFilter: FilteredTypes
): U<FilterNestedItem[]> => {
  const lanesData = laneParams?.reduce((acc: FilterNestedItem[], { id }) => {
    const isNumberValid = isNumber(id);

    if (isNumberValid) {
      acc.push({
        value: <p>{id}</p>,
        type: id,
        name: `Полоса № ${id}`,
        parentFilter,
        filterType: FilteredTypes.Lanes,
      });
    }

    return acc;
  }, []);

  return lanesData;
};

const getDirData = (dai_jdt83: Detector['dai_jdt83']): FilterData[] => {
  if (!dai_jdt83) return DIRECTIONS_ICONS;

  const { laneParams, fromCamera, toCamera } = dai_jdt83;

  const laneParamsObj: Record<DirectionsTypes, U<LaneParams[]>> = {
    [OnCamera]: toCamera?.laneParams,
    [OutCamera]: fromCamera?.laneParams,
    [Undefined]: laneParams,
  };

  const isDefault = !toCamera && !fromCamera;

  return DIRECTIONS_ICONS.map((el) => {
    const laneParamFormatted = laneParamsObj[el.type];

    const isOnlyOnCamera = isDefault && el.type !== OnCamera;

    if ((!laneParamFormatted && !isDefault) || isOnlyOnCamera) return el;

    return {
      ...el,
      nestedValues: getLanesData(
        isDefault ? laneParams : laneParamFormatted,
        FilteredTypes.Directions
      ),
    };
  });
};

const getNormativeDocuments = (
  normativeDict: U<NormilizeData['normativeDict']>,
  graphType: GraphTypes
) => {
  const chartFields = rootStore.uiStore.dictionaries[ChartFields];

  if (graphType === GraphTypes.CandlesChart) return [];

  const normativeDocs: FilterData[] =
    normativeDict?.map(({ id, name }) => {
      const dictValueName = findBy(chartFields, name, 'mnemocode')?.name;

      return {
        value: <NormativeIco id={id} />,
        type: id,
        name: dictValueName ?? `Нормативный документ ${name}`,
      };
    }) ?? [];

  return normativeDocs;
};

const checkDisabledVehicles = (normativeData: FilterData[]) => {
  const {
    filterDtData: { normative },
  } = rootStore.detailedStore;

  if (!normativeData.length || !normative?.length) return false;

  const [key] = normative;

  const isStandard = key === NormativeIds.Standard;

  return !isStandard;
};

export const getFilteredInfo = (
  activeDetector: Detector,
  normativeDict: U<NormilizeData['normativeDict']>,
  graphType: GraphTypes
) => {
  const { dai_jdt83 } = activeDetector;

  const dirData = getDirData(dai_jdt83);
  const normativeData = getNormativeDocuments(normativeDict, graphType);

  const isDisabledVehicles = checkDisabledVehicles(normativeData);

  const res = FILTERED_INFO.map((filterItem) => {
    let data: FilterData[] = [];

    switch (filterItem.filterType) {
      case FilteredTypes.Directions:
        data = dirData;
        break;
      case FilteredTypes.Lanes:
        break;
      case FilteredTypes.VehicleTypes:
        data = VEHICLES_ICONS.map((el) => ({
          ...el,
          disabled: isDisabledVehicles,
        }));
        break;
      case FilteredTypes.Normative:
        data = normativeData;
        break;
      default:
        neverTypeCheck(filterItem.filterType);
        break;
    }

    return { ...filterItem, data };
  });

  return res;
};
