import { useEffect } from 'react';

import { SourceDisplayType } from '../../../../../stores/adminPanelStore/constants/constants';
import rootStore from '../../../../../stores/rootStore/rootStore';

const useSourceEdit = () => {
  const { selectedDevice, sourceDisplayType, setAdminsData } =
    rootStore.adminPanelStore;

  //change source display if change selectedDevice
  useEffect(() => {
    setAdminsData('sourceDisplayType', null);
  }, [selectedDevice, setAdminsData]);

  const handleClick = () => {
    setAdminsData('sourceDisplayType', null);

    if (sourceDisplayType === SourceDisplayType.SourceEditForm) {
      setAdminsData('selectedSource', null);

      return;
    }
  };

  const isShowArrow = sourceDisplayType !== null;

  return { handleClick, isShowArrow };
};

export default useSourceEdit;
