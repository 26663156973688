import type { CanvasFilterOptions } from 'ol-ext/filter/CanvasFilter';

import {
  addFilter,
  restoreDefaultFilter,
} from '../../../apiGIS/utils/basemaps';
import { IBaseMapItem } from '../../../stores/gisDataStore/gisDataStore.model';
import { TMap } from '../../../stores/mapStore/mapStore.model';

import { MAX_SUBTITLE_LENGTH } from './constants';

export const getSubtitle = (item: IBaseMapItem) => {
  const { text } = item;

  return text && text.length > MAX_SUBTITLE_LENGTH
    ? text.slice(0, MAX_SUBTITLE_LENGTH - 2) + '...'
    : text;
};

export const applyBasemapFilter = (
  map: TMap,
  id: string,
  settings?: CanvasFilterOptions
) => {
  if (!map || !id || !settings) {
    return;
  }

  const layer = map.getAllLayers().find((layer) => layer.get('id') === id);

  if (!layer) {
    return;
  }

  restoreDefaultFilter(layer);

  addFilter(layer, settings);
};

export const getInitialBasemapSettings = (): CanvasFilterOptions => {
  return {
    blur: 0,
    brightness: 100,
    contrast: 100,
    grayscale: 0,
    hueRotate: 0,
    invert: 0,
    saturate: 100,
    sepia: 0,
    shadow: undefined,
    shadowBlur: 0,
    shadowColor: 0,
    url: '',
  };
};
