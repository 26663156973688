import { SelectProps } from 'antd/lib';
import { isNumber } from 'lodash';

import { SystemsList } from '../../../../../../../api/adminObjects/systems/models/systems.model';
import { findBy } from '../../../../../../../helpers/findBy';
import sortByStr from '../../../../../../../helpers/sortByStr';
import { SEPARATE } from '../../../../../../Panel/ScriptsControl/SelectModule/DispatchGroupControl/SelectItem/helpers/helpers';
import { SelectedDeviceType } from '../../../../../models/adminTypes.model';
import SystemItem from '../SystemItem/SystemItem';

type SelectionProps = NonNullable<SelectProps['options']>;

const getFormattedKey = (id: number, subSystemId: number) => {
  return `${id}${SEPARATE}${subSystemId}`;
};

const transformSystemsToOptions = (
  systemsList: N<SystemsList[]>,
  selectedDevice: SelectedDeviceType
) => {
  if (!systemsList) return null;

  const options = systemsList.reduce(
    (acc: SelectionProps, { deviceTypes, id, name, description }) => {
      const subSystemId = findBy(
        deviceTypes,
        selectedDevice.deviceTypeId,
        'deviceTypeId'
      )?.subSystemId;

      if (isNumber(subSystemId)) {
        acc.push({
          value: getFormattedKey(id, subSystemId),
          label: <SystemItem id={id} name={name} description={description} />,
        });
      }

      return acc;
    },
    []
  );

  return sortByStr(options, 'value');
};

export default transformSystemsToOptions;
