import { type DefaultOptionType } from 'antd/es/select';
import { isNumber, isString } from 'lodash';

import { SystemTypesDict } from '../../../../../../../api/devices/model/devices.model';
import sortByStr from '../../../../../../../helpers/sortByStr';

const getSystemTypesOptions = (
  supportedSystems: N<SystemTypesDict[]>
): N<DefaultOptionType[]> => {
  if (!supportedSystems) return null;

  const options = supportedSystems
    .map(({ id, name }) => ({
      value: id,
      label: name,
    }))
    .filter((el) => isNumber(el.value) && isString(el.label));

  return sortByStr(options, 'value');
};

export default getSystemTypesOptions;
