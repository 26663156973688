import { useQueryClient } from '@tanstack/react-query';
import { App } from 'antd';

import api from '../../../../../../../../api';
import eventBus from '../../../../../../../../eventBus';
import rootStore from '../../../../../../../../stores/rootStore/rootStore';
import notification from '../../../../../../../ui-kit/Notification/Notification';
import getDeviceInfo from '../../../../../DeviceAdminCard/DeviceFormContent/DeviceEditCard/helpers/getDeviceInfo';

const TITLE = 'Вы точно хотите удалить источник?';
const APP_PATH =
  'Admin.DeviceAdmin.SourceEdit.SourceContainer.SourceContent.SourceButtons.hooks.useSourceButtons';

const { deleteSource } = api.devices.externalLinks.externalLinksApi;

const useSourceButtons = (extSourceId: number, name: N<string>) => {
  const { selectedDevice, setAdminsData } = rootStore.adminPanelStore;

  const { modal } = App.useApp();
  const queryClient = useQueryClient();

  const handleEditSource = () => {
    setAdminsData('selectedSource', extSourceId);
  };

  const confirmDeleteSource = async () => {
    if (!selectedDevice) return;

    const { id } = selectedDevice;

    const res = await deleteSource(id, extSourceId, APP_PATH);

    if (!res) {
      console.error(`Ошибка удаления источника для ${id} устройства`);
    }

    notification.success('EXTERNAL_SOURCE_DELETE');
    eventBus.updateSourcesList.broadcast();
  };

  const handleDeleteSource = () => {
    if (!selectedDevice) return;

    const { id } = selectedDevice;

    const caption = getDeviceInfo(queryClient, id)?.sname;

    modal.confirm({
      title: TITLE,
      content: `Источник ${extSourceId} | ${name} для устройства ${id} | ${caption} будет удален!`,
      onOk: confirmDeleteSource,
      okText: 'Удалить',
      okButtonProps: { danger: true },
      centered: true,
    });
  };

  return { handleEditSource, handleDeleteSource };
};

export default useSourceButtons;
