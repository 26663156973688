import { FC } from 'react';

import styles from './PopoverContent.module.scss';

interface PopoverContentProps {
  title: string;
  errorText: N<string>;
}

const PopoverContent: FC<PopoverContentProps> = ({ title, errorText }) => {
  return (
    <div className={styles.container}>
      <h4>{title}</h4>
      {errorText ? <p className={styles.error}>{errorText}</p> : null}
    </div>
  );
};

export default PopoverContent;
