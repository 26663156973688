import { Dayjs } from 'dayjs';
import { makeAutoObservable } from 'mobx';
import type { Coordinate } from 'ol/coordinate';

import { IRegionData } from '../../api/server/web/web.zod';
import {
  IAdminModalContent,
  ISystemData,
  CamerasData,
  StatusSystems,
  SelectedOption,
  EnabledSystems,
  TypeDeviceForm,
  SelectedDeviceType,
} from '../../components/Admin/models/adminTypes.model';
import { ALL_SYSTEM_TITLE } from '../../components/DetailedStatistics/constants/constants';
import { getSystemAdminType } from '../../components/Panel/AdminPanel/helpers/getSystemAdminType';
import { IAdminsPanelTree } from '../../components/Panel/AdminPanel/helpers/helpers';
import { SEPARATOR_KEY } from '../../components/Panel/ReportsPanel/ReportTree/helpers/helpersTree';
import { AdminTitleKeys } from '../../constants/adminConstants';
import { PanelType } from '../../ts/enums/enums';
import RootStore from '../rootStore/rootStore';

import { DeviceFormType, SourceDisplayType } from './constants/constants';
import getActiveStatus from './helpers/getActiveStatus';
import getIsSubSystem from './helpers/getIsSubSystem';
import { getParsedMenuKeyData } from './helpers/getParsedMenuKeyData/getParsedMenuKeyData';

class AdminPanelStore {
  rootStore;
  adminTableType: N<string> = null;
  isModalOpen = false;
  modalContent: N<IAdminModalContent> = null;
  isModalLoading = false;
  groupId: N<number> = null;
  adminPanelKeys: N<string[]> = null;
  isUserUpdate = false;
  adminModalType = 'default';
  systemData: N<ISystemData> = null;
  initSystemData: N<ISystemData> = null;
  displayHeight = 1028;
  systemMenu: N<IAdminsPanelTree[]> = null;
  isFormChange = false;
  subId: N<string> = null;
  isAddSystem = false;
  isDeleteSystem = false;
  isSettingsUpdate = false;
  isMapLoading = true;
  regionId: N<number> = null;
  selectedRegionData: N<IRegionData> = null;
  isEditFormSystems = false;
  isChangeFormSystems = false;
  isTriggerRestoreForm = false;
  editFormCoord: N<Coordinate> = null;
  cameraRegionsData: N<CamerasData> = null;
  updateTimeMonitoring: N<Dayjs> = null;
  selectedSubSystem: SelectedOption = ALL_SYSTEM_TITLE;
  selectedMonitoringRegion: SelectedOption = ALL_SYSTEM_TITLE;
  activeStatusFilter: string[] = [];
  searchedFilter: N<string> = null;
  statusSystems: StatusSystems = null;
  enabledSystems: EnabledSystems = null;
  statisticUpdateTime: N<Dayjs> = null;
  isStatusPointLoading = true;
  deviceFormType: TypeDeviceForm = null;
  selectedDevice: N<SelectedDeviceType> = null;
  sourceDisplayType: N<SourceDisplayType> = null;
  selectedSource: N<number> = null;

  constructor(rootStore: typeof RootStore) {
    makeAutoObservable(this, {
      rootStore: false,
    });
    this.rootStore = rootStore;
  }

  setAdminsData = <K extends keyof this, T extends this[K]>(
    key: K,
    value: T
  ) => {
    this[key] = value;
  };

  setActiveStatus = (status: N<string>) => {
    this.activeStatusFilter = getActiveStatus(status, this.activeStatusFilter);
  };

  clearFormValues = () => {
    this.initSystemData = null;
    this.isEditFormSystems = false;
    this.isChangeFormSystems = false;
    this.isTriggerRestoreForm = false;
  };

  clearDeviceForm = () => {
    const { setAdminsData } = this;

    setAdminsData('deviceFormType', null);
    setAdminsData('selectedDevice', null);
    setAdminsData('sourceDisplayType', null);
    setAdminsData('selectedSource', null);
  };

  get isAdminLoad() {
    return !!this.adminTableType;
  }

  get selectedAdminItems() {
    if (this.adminTableType) {
      return [this.adminTableType];
    }

    return [];
  }

  get adminSystemsId() {
    if (this.adminTableType) {
      const key = getParsedMenuKeyData(this.adminTableType).systemId;

      return key ? key.toString() : null;
    }

    return null;
  }

  get isEditForm() {
    return this.deviceFormType === DeviceFormType.EditForm;
  }

  get isSubSystemOpen() {
    const panelKeys = this.adminPanelKeys;

    if (!panelKeys) return false;

    const isSubSystem = panelKeys.some((key) => {
      const adminNameType = getSystemAdminType(key);

      return (
        getIsSubSystem(adminNameType) || adminNameType === AdminTitleKeys.System
      );
    });

    return isSubSystem;
  }

  get validationParams() {
    if (this.systemData) {
      return this.systemData.pvalid;
    }

    return null;
  }

  get regionTreeId() {
    const { regionId } = getParsedMenuKeyData(this.adminTableType || '');

    return regionId ?? undefined;
  }

  get isBreadCrumbs() {
    const { adminTableType } = this;

    return Boolean(
      adminTableType === AdminTitleKeys.EputsSystem ||
        adminTableType?.includes(SEPARATOR_KEY)
    );
  }

  get isAdminPanel() {
    const { panelType } = this.rootStore.uiStore;

    return panelType === PanelType.Admins;
  }

  get isAdminDetailed() {
    return this.isAdminPanel && Boolean(this.adminTableType);
  }
}
export default AdminPanelStore;
