import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';

import api from '../../../../../../../api';
import {
  DEFAULT_UPDATE_DEVICE_ADMIN,
  QUERY_KEY,
} from '../../../../../../../constants/queryConstants';
import rootStore from '../../../../../../../stores/rootStore/rootStore';
import { SEPARATE } from '../../../../../../Panel/ScriptsControl/SelectModule/DispatchGroupControl/SelectItem/helpers/helpers';
import { SourceFormFields } from '../../../../models/DeviceAdmin.model';
import transformDevicesToOptions from '../helpers/transformDevicesToOptions';

const { SOURCE_EXT_DEVICES } = QUERY_KEY;
const { getExternalSystemDevicesList } = api.adminObjects.systems.devices;

const APP_PATH =
  'Admin.DeviceAdmin.SourceEdit.SourceForm.ExternalDevicesSelect.hooks.useExtDevices';

const useExtDevices = (systemInfo: SourceFormFields['systemId']) => {
  const { regionData } = rootStore.uiStore;

  const {
    data: options,
    isFetching: isLoading,
    refetch,
  } = useQuery({
    queryKey: [SOURCE_EXT_DEVICES, regionData, systemInfo],
    queryFn: async () => {
      if (!systemInfo || !regionData) return null;
      const [systemId, subsystemId] = systemInfo.split(SEPARATE).map(Number);

      const devicesList =
        (await getExternalSystemDevicesList({
          appPath: APP_PATH,
          regionId: regionData.id,
          systemId,
          subsystemId,
        })) ?? null;

      return transformDevicesToOptions(devicesList);
    },
    refetchInterval: DEFAULT_UPDATE_DEVICE_ADMIN,
    enabled: !!(regionData && systemInfo),
  });

  useEffect(() => {
    refetch();
  }, [systemInfo, refetch]);

  return { options, isLoading };
};

export default useExtDevices;
