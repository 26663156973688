import { observer } from 'mobx-react-lite';
import { lazy, Suspense } from 'react';

import { IS_DEV } from '../../constants/constants';
import rootStore from '../../stores/rootStore/rootStore';
import { WEB_DEVELOPER_CODE } from '../../ts/enums/userData';

import queryClient from './queryClient';

const ReactQueryDevtoolsProduction = lazy(() =>
  import('@tanstack/react-query-devtools/build/modern/production.js').then(
    (d) => ({
      default: d.ReactQueryDevtools,
    })
  )
);

const ReactQueryDevtools = lazy(() => import('./ReactQueryDevTools'));

const QueryDevTools = () => {
  const { isQueryDevTools, queryToolsBtnPosition } = rootStore.uiStore;
  const { hasAccess } = rootStore.userDataStore;

  if (!hasAccess(WEB_DEVELOPER_CODE) || !isQueryDevTools) return null;

  return (
    <Suspense fallback={null}>
      {IS_DEV ? (
        <ReactQueryDevtools />
      ) : (
        <ReactQueryDevtoolsProduction
          client={queryClient}
          buttonPosition={queryToolsBtnPosition}
        />
      )}
    </Suspense>
  );
};

export default observer(QueryDevTools);
