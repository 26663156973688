import { Path } from '../../api.model';
import { getAppPathHeaders } from '../../helpers/getAppPathHeaders';
import http from '../../http';
import tryCatchWrapper from '../../tryCatchWrapper';
import { DEVICES } from '../constants/constants';
import { Device } from '../model/devices.model';

const deviceInfoApi = {
  fetchDeviceInfoById: (id: number, appPath: Path) =>
    tryCatchWrapper(
      async () => {
        const res = await http.get<Device>(`${DEVICES}/${id}`, {
          headers: getAppPathHeaders(appPath),
        });

        return res.data;
      },
      {
        errorMessage: 'DEVICE_INFO_ERROR',
      }
    ),
  fetchDeviceListInfoByRegion: (regionId: number, appPath: Path) =>
    tryCatchWrapper(
      async () => {
        const res = await http.get<Device[]>(DEVICES, {
          headers: getAppPathHeaders(appPath),
          params: {
            regionId,
          },
        });

        return res.data;
      },
      {
        errorMessage: 'LIST_DEVICE_ERROR',
      }
    ),
};

export default deviceInfoApi;
