import { isNull, isNumber } from 'lodash';

import { LibraryKeys } from '../../../../../../../api/libraries/enums/enums';
import sortByStr from '../../../../../../../helpers/sortByStr';
import rootStore from '../../../../../../../stores/rootStore/rootStore';
import StatusSelectItem from '../StatusSelectItem/StatusSelectItem';

const { ExternalLinksStatus } = LibraryKeys;

const getStatusOptions = () => {
  const externalLinksStatusDict =
    rootStore.uiStore.dictionaries[ExternalLinksStatus];

  const options = externalLinksStatusDict
    .map(({ id, name, dsc }) => ({
      value: id,
      label: name ? (
        <StatusSelectItem key={id} name={name} dsc={dsc ?? null} />
      ) : null,
    }))
    .filter((el) => isNumber(el.value) && !isNull(el.label));

  return sortByStr(options, 'value');
};

export default getStatusOptions;
