import { Form } from 'antd';
import { Dayjs } from 'dayjs';
import { useEffect } from 'react';

import { getValidationRules } from '../../../DeviceAdminCard/DeviceFormContent/helpers/validationHelpers';
import {
  HandleChangeDate,
  HandleChangeSelect,
  SourceFormFields,
} from '../../../models/DeviceAdmin.model';
import { FORM_RULES_SOURCES, FormSourceKeys } from '../constants/constants';

const { LifetimeFrom, LifetimeTo, SystemTypeId, SystemId, DeviceInfo } =
  FormSourceKeys;

const useSourceForm = (initialProps: U<SourceFormFields>) => {
  const [form] = Form.useForm<SourceFormFields>();

  useEffect(() => {
    initialProps && form.setFieldsValue(initialProps);
  }, [form, initialProps]);

  const getSourceRules = (value: FormSourceKeys) =>
    getValidationRules(value, FORM_RULES_SOURCES);

  const handleChangeDate: HandleChangeDate = (value, key) => {
    if (key === LifetimeFrom) {
      const endDate: U<Dayjs> = form.getFieldValue(LifetimeTo);

      endDate &&
        value &&
        value.diff(endDate) >= 0 &&
        form.setFieldValue(LifetimeTo, null);
    }

    form.setFieldValue(key, value);
  };

  //clear dependency select systemId and deviceId
  const handleChangeSelect: HandleChangeSelect = (key, value) => {
    if (key === SystemTypeId) {
      form.setFieldsValue({
        systemId: undefined,
        deviceInfo: undefined,
      });
    }

    if (key === SystemId) {
      form.setFieldValue(DeviceInfo, undefined);
    }

    form.setFieldValue(key, value);
  };

  return {
    form,
    isEditForm: !!initialProps,
    getSourceRules,
    handleChangeDate,
    handleChangeSelect,
  };
};

export default useSourceForm;
