import Popover from '../../ui-kit/Popover/Popover';

import { COLORS, tooltipProps } from './constants';

import styles from './TrafficButton.module.scss';

const getTitle = (value: number) => {
  const { text } = COLORS[value];

  return text;
};

const TrafficLegend = () => {
  return (
    <>
      <div className={styles.legendPanel}>
        <div className={styles.legendItemContainer}>
          {COLORS.map((item, index) => (
            <Popover key={index} content={getTitle(index)} {...tooltipProps}>
              <div
                style={{ backgroundColor: item.color }}
                className={styles.legendItem}
              >
                <div className={styles.legendItemText}>{index + 1}</div>
              </div>
            </Popover>
          ))}
        </div>
      </div>
    </>
  );
};

export default TrafficLegend;
