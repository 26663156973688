import { Button } from 'antd';
import { FC } from 'react';
import { IoArrowBack } from 'react-icons/io5';

import styles from './BackArrow.module.scss';

interface BackArrowProps {
  isShow?: boolean;
  handleClick: () => void;
}

const BackArrow: FC<BackArrowProps> = ({ isShow = true, handleClick }) => {
  if (!isShow) return null;

  return (
    <Button className={styles.btn} onClick={handleClick}>
      <div className={styles.block}>
        <IoArrowBack />
        <p>Назад</p>
      </div>
    </Button>
  );
};

export default BackArrow;
