import { observer } from 'mobx-react-lite';

import rootStore from '../../../../../stores/rootStore/rootStore';
import { DEVICE_FORM_CONTENT } from '../../constants/constants';
import SelectDevice from '../SelectDevice/SelectDevice';

const DeviceFormContent = () => {
  const { deviceFormType } = rootStore.adminPanelStore;

  if (!deviceFormType) return <SelectDevice />;

  const FormContent = DEVICE_FORM_CONTENT[deviceFormType];

  return <FormContent />;
};

export default observer(DeviceFormContent);
