import { Path } from '../../api.model';
import { getAppPathHeaders } from '../../helpers/getAppPathHeaders';
import http from '../../http';
import tryCatchWrapper from '../../tryCatchWrapper';
import { DEVICES } from '../constants/constants';
import {
  ExternalLink,
  ExternalLinkCreateProps,
  ExternalLinkUpdateProps,
} from '../model/devices.model';

const EXTERNAL_LINKS = 'external-links';

const externalLinksApi = {
  fetchExternalLinksByDeviceId: (deviceId: number, appPath: Path) =>
    tryCatchWrapper(
      async () => {
        const res = await http.get<ExternalLink[]>(
          `${DEVICES}/${deviceId}/${EXTERNAL_LINKS}`,
          {
            headers: getAppPathHeaders(appPath),
          }
        );

        return res.data;
      },
      {
        errorMessage: 'EXTERNAL_LINKS_ERROR',
      }
    ),
  fetchExternalLinkBySourceId: (
    deviceId: number,
    sourceId: number,
    appPath: Path
  ) =>
    tryCatchWrapper(
      async () => {
        const res = await http.get<ExternalLink>(
          `${DEVICES}/${deviceId}/${EXTERNAL_LINKS}/${sourceId}`,
          {
            headers: getAppPathHeaders(appPath),
          }
        );

        return res.data;
      },
      {
        errorMessage: 'EXTERNAL_LINK_ERROR',
      }
    ),
  createSource: (
    deviceId: number,
    body: ExternalLinkCreateProps,
    appPath: Path
  ) =>
    tryCatchWrapper(async () => {
      const res = await http.post<ExternalLink>(
        `${DEVICES}/${deviceId}/${EXTERNAL_LINKS}`,
        body,
        {
          headers: getAppPathHeaders(appPath),
        }
      );

      return res;
    }),
  updateSource: (
    deviceId: number,
    sourceId: N<number>,
    body: ExternalLinkUpdateProps,
    appPath: Path
  ) =>
    tryCatchWrapper(async () => {
      if (!sourceId) {
        console.error('Не указан id источника');

        return null;
      }

      const res = await http.put<ExternalLink>(
        `${DEVICES}/${deviceId}/${EXTERNAL_LINKS}/${sourceId}`,
        body,
        {
          headers: getAppPathHeaders(appPath),
        }
      );

      return res;
    }),
  deleteSource: (deviceId: number, sourceId: number, appPath: Path) =>
    tryCatchWrapper(
      async () => {
        const res = await http.delete(
          `${DEVICES}/${deviceId}/${EXTERNAL_LINKS}/${sourceId}`,
          {
            headers: getAppPathHeaders(appPath),
          }
        );

        return res;
      },
      {
        errorMessage: 'EXTERNAL_SOURCE_DELETE_ERROR',
      }
    ),
};

export default externalLinksApi;
