import type { RuleRender, Rule } from 'antd/es/form';
import { FormInstance } from 'antd/lib';
import { isNil } from 'lodash';
import type { Coordinate } from 'ol/coordinate';

import rootStore from '../../../../../../stores/rootStore/rootStore';
import {
  DISABLED_POPOVER_TEXT,
  DisabledTypes,
} from '../../../../../Panel/MainPanelTabs/AllSystemsTab/constants/constants';
import {
  isFormKey,
  isFormSourceKey,
  RulesItems,
} from '../../../models/DeviceAdmin.model';
import { FormSourceKeys } from '../../../SourceEdit/SourceForm/constants/constants';
import { FORM_RULES, FormKeys } from '../constants/constants';

const DEFAULT_ERROR = 'Некорректно заполненноe поле';
const DEFAULT_COORD_TEXT = 'Допустимый диапазон значений:';

const getValidationFnc = (regExp: string, message: string) => {
  return () => ({
    validator(_: any, value: string) {
      const newReg = new RegExp(regExp);

      if (!value || newReg.test(value)) {
        return Promise.resolve();
      }

      return Promise.reject(message);
    },
  });
};

export const getValidationRules = <T extends FormKeys | FormSourceKeys>(
  key: string,
  formRules: Record<T, RulesItems>
) => {
  const formItem =
    isFormKey(key) || isFormSourceKey(key) ? formRules[key as T] : null;

  if (!formItem) return;

  const rules: Rule[] = [];

  const { regExp, required } = formItem;

  if (required) {
    const rule = { required: true, message: 'Обязательное поле' };

    rules.push(rule);
  }

  if (regExp) {
    const rule = getValidationFnc(regExp, DEFAULT_ERROR);

    rules.push(rule);
  }

  return rules.length ? rules : undefined;
};

const getFeatureCoord = (form: FormInstance | Parameters<RuleRender>[0]) => {
  const long = form.getFieldValue(FormKeys.Longitude);
  const lat = form.getFieldValue(FormKeys.Latitude);

  if (!isNil(long) && !isNil(lat)) {
    return [long, lat];
  }

  return null;
};

const getIsCorrectCoords = (coord: Coordinate, key: string) => {
  const { regionData } = rootStore.uiStore;

  const [long, lat] = coord;

  if (!regionData) return true;

  const { extent } = regionData;

  const [xMin, yMin, xMax, yMax] = extent;

  if (key === FormKeys.Longitude) return xMin <= long && xMax >= long;

  return yMin <= lat && yMax >= lat;
};

export const getCoordTemplate = (
  min: number,
  max: number,
  defaultText?: string
) =>
  `${DEFAULT_COORD_TEXT} ${`${min} - ${max}`.replaceAll('.', ',')}. ${
    defaultText ?? ''
  }`;

export const getCoordSpecialRules = (
  key: string,
  formInstance: FormInstance | Parameters<RuleRender>[0]
) => {
  const { regionData } = rootStore.uiStore;

  const coords = getFeatureCoord(formInstance);

  return {
    validator() {
      if (!coords) return Promise.resolve();

      const isDisabled = !getIsCorrectCoords(coords, key);

      if (!isDisabled || !regionData) {
        return Promise.resolve();
      }

      const { extent } = regionData;

      const [xMin, yMin, xMax, yMax] = extent;

      let errorText = DISABLED_POPOVER_TEXT[DisabledTypes.UnknownCoordinates];

      if (key === FormKeys.Longitude) errorText = getCoordTemplate(xMin, xMax);

      if (key === FormKeys.Latitude) errorText = getCoordTemplate(yMin, yMax);

      return Promise.reject(new Error(errorText));
    },
  };
};

export const getCoordFormRules = (key: string): U<Rule[]> => {
  const standardRules = getValidationRules(key, FORM_RULES);

  return [
    ...(standardRules ?? []),
    //temporary
    // (formInstance) => getCoordSpecialRules(key, formInstance),
  ];
};
