import { TObjMap } from '../../stores/mapDataStore/mapDataStore.model';
import { System } from '../../ts/enums/enums';
import { ICamera } from '../../ts/models/camera.model';
import { PointUds } from '../../ts/models/pointUds.model';

export interface InfoMainProps {
  currentObject: TObjMap;
  system: System;
  id: number;
  isTLValid: boolean;
}

export interface PointUdsNameProps {
  pointUdsName: N<string>;
  point: U<PointUds>;
}

export const isCamera = (mapObj: TObjMap): mapObj is ICamera =>
  Object.hasOwn(mapObj, 'srcId');
