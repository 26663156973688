import { TDevicesIds } from '../../../constants/constants';
import { LibraryValues } from '../../../stores/uiStore/uiStore.model';
import { Path } from '../../api.model';
import { getAppPathHeaders } from '../../helpers/getAppPathHeaders';
import http from '../../http';
import tryCatchWrapper from '../../tryCatchWrapper';
import { DEVICES, DictDeviceKeys } from '../constants/constants';
import { SystemTypesDict } from '../model/devices.model';

const SUPPORTED_SYSTEM = 'supported-system-types';

const devicesDictApi = {
  fetchDeviceLibraryByKeyword: (key: DictDeviceKeys, appPath: Path) =>
    tryCatchWrapper(async () => {
      const res = await http.get<LibraryValues[]>(`${DEVICES}/${key}`, {
        headers: getAppPathHeaders(appPath),
      });

      return res.data;
    }),

  fetchSupportedSystemTypesLibrary: (
    deviceTypeId: TDevicesIds,
    appPath: Path
  ) =>
    tryCatchWrapper(
      async () => {
        const res = await http.get<SystemTypesDict[]>(
          `${DEVICES}/${SUPPORTED_SYSTEM}`,
          {
            headers: getAppPathHeaders(appPath),
            params: {
              deviceTypeId,
            },
          }
        );

        return res.data;
      },
      {
        errorMessage: 'SUBSYSTEMS_LIST_ERROR',
      }
    ),
};

export default devicesDictApi;
