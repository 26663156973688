import { FC } from 'react';

import BackArrow from '../../../ui-kit/BackArrow/BackArrow';

import styles from './Header.module.scss';

interface HeaderProps {
  title: string;
  isShowArrow?: boolean;
  handleClick: () => void;
}

const Header: FC<HeaderProps> = ({
  title,
  isShowArrow = true,
  handleClick,
}) => {
  return (
    <div className={styles.title}>
      <h3>{title}:</h3>
      <BackArrow isShow={isShowArrow} handleClick={handleClick} />
    </div>
  );
};

export default Header;
