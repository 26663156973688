import cameraIco from '../assets/icons/map/ic_camera.svg';
import detectorIco from '../assets/icons/map/ic_detectors.svg';
import meteoIco from '../assets/icons/map/ic_meteo.svg';
import publicTransportIco from '../assets/icons/map/ic_public_transport.svg';
import specialTransportIco from '../assets/icons/map/ic_special_transport.svg';
import lightsIco from '../assets/icons/map/ic_tr_light.svg';
import { System } from '../ts/enums/enums';

export const ZOOM = {
  INITIAL: 10,
  MAX: 22,
  MIN: 9,
  TILES_BORDER: 19,
  CROSSROAD_BORDER: 16,
  DURATION: 500,
  STEP: 1,
  STEP_CONSTRUCTOR: 0.25,
  WHEEL_DURATION: 300,
  LESS_THEN_WHEEL_STEP: 0.99,
};

export const MAP_INITIAL_OPTIONS = {
  layers: [],
  controls: [],
  overlays: [],
};

export const CENTERING = {
  ANIMATION_DURATION: 500,
};

export const CURSOR_MAP = {
  CURSOR: 'default',
  POINTER: 'pointer',
  GRAB: 'grab',
  GRABBING: 'grabbing',
  DISABLED: 'not-allowed',
  EMPTY: '',
  CROSS: 'crosshair',
};

export const TL_NAME_STORE = 'tls';

export const CLUSTERS = 'кластеры';

export const GIS_LAYER = 'gis';

export const GIS_TRAFFIC = 'traffic';

export const GIS_VECTOR_LAYERS_IDENTIFICATION = [GIS_TRAFFIC];

export const DEFAULT_RENDERER = {
  renderer: {
    type: 'simple',
    symbol: {
      color: [45, 0, 128, 161],
      outline: {
        color: [190, 250, 190, 105],
        width: 0.5,
        type: 'esriSLS',
        style: 'esriSLSSolid',
      },
      size: 7.5,
      type: 'esriSMS',
      style: 'esriSMSCircle',
    },
  },
  labelingInfo: [
    {
      labelExpression: '[route]',
      labelExpressionInfo: {
        value: 'route',
      },
      useCodedValues: false,
      maxScale: 0,
      minScale: 10000,
      where: '',
      labelPlacement: 'esriServerLinePlacementCenterAlong',
      symbol: {
        color: [51, 51, 51, 255],
        type: 'esriTS',
        horizontalAlignment: 'center',
        rightToLeft: false,
        angle: 0,
        xoffset: 0,
        yoffset: 0,
        text: '',
        rotated: false,
        kerning: true,
        font: {
          size: 12,
          style: 'normal',
          decoration: 'none',
          weight: 'bold',
          family: 'Arial',
        },
      },
    },
  ],
};

export const MAP_ICONS: Record<System, string> = {
  [System.Lights]: lightsIco,
  [System.Cameras]: cameraIco,
  [System.Detectors]: detectorIco,
  [System.Meteo]: meteoIco,
  [System.PublicTransport]: publicTransportIco,
  [System.SpecialTransport]: specialTransportIco,
};

export const PHASE_CIRCLE = {
  MIN_DIAMETER: 133,
};
