export const COLORS = [
  {
    color: '#60cd4e',
    text: 'Пробки отсутствуют, ТС на участке отсутствуют или их количество минимально',
  },
  { color: '#8aca43', text: 'Минимальный уровень затруднения при движении' },
  { color: '#d4c739', text: 'Низкий уровень загруженности' },
  {
    color: '#ffbc35',
    text: 'Низкий уровень загруженности, локальные скопления машин',
  },
  { color: '#ff982f', text: 'Средний уровень пробок' },
  {
    color: '#fb672a',
    text: 'Высокий уровень пробок, скорость снижена до минимальной ',
  },
  { color: '#e53922', text: 'Высокий уровень пробок, движение затруднено' },
  {
    color: '#c92017',
    text: 'Очень высокий уровень пробок, движение сильно затруднено',
  },
  {
    color: '#ac100c',
    text: 'Критический уровень пробок, движение почти невозможно',
  },
  {
    color: '#8e0101',
    text: 'Дорожный затор, движение полностью остановлено или перекрыто',
  },
];

export const tooltipProps = {
  placement: 'bottom',
} as const;
