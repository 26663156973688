import { FC } from 'react';

import {
  AuthMethod,
  DISABLED_AUTH_METHODS,
  LOGIN_METHOD_ICONS,
  LOGIN_METHODS,
} from '../constants/loginConstants';

import styles from './LoginChoice.module.scss';

interface LoginChoiceProps {
  setAuthMethod: SetState<N<AuthMethod>>;
}

const LoginChoice: FC<LoginChoiceProps> = ({ setAuthMethod }) => {
  const handleAuthMethod = (key: AuthMethod) => {
    const isDisabled = DISABLED_AUTH_METHODS.some((el) => el === key);

    !isDisabled && setAuthMethod(key);
  };

  return (
    <section className={styles.choice}>
      <h1> Выберите вариант для входа в систему: </h1>
      <ul>
        {LOGIN_METHODS.map((item) => (
          <li className={styles.item} key={item.type}>
            <button onClick={() => handleAuthMethod(item.type)}>
              {LOGIN_METHOD_ICONS[item.type]}
              <div className={styles.row}>
                <h4 className={styles.rowTitle}>{item.title}</h4>
                <p className={styles.rowInfo}>{item.info}</p>
              </div>
            </button>
          </li>
        ))}
      </ul>
    </section>
  );
};

export default LoginChoice;
