/* eslint-disable jsx-a11y/no-static-element-interactions */
import { FC, ReactNode } from 'react';

import styles from './SourceCard.module.scss';

interface SourceCardProps {
  children: ReactNode;
  handleClick: () => void;
}

const SourceCard: FC<SourceCardProps> = ({ handleClick, children }) => {
  return (
    <div className={styles.card} onClick={handleClick}>
      {children}
    </div>
  );
};

export default SourceCard;
