import { uniqueId } from 'lodash';
import type { Overlay } from 'ol';
import { useEffect, useRef, useState } from 'react';

import { TMap } from '../../../../../../stores/mapStore/mapStore.model';
import createOverlay from '../../../../../Mapper/helpers/createOverlay';

const CROSSROAD_OVERLAY_ID = uniqueId('crossroad_overlay_');

interface UseCrossroadOverlayProps {
  map: TMap;
}

const useCrossroadOverlay = ({ map }: UseCrossroadOverlayProps) => {
  const crossroadDetailedRef = useRef<N<HTMLDivElement>>(null);
  const [crossroadOverlay, setCrossroadOverlay] = useState<N<Overlay>>(null);

  useEffect(() => {
    if (!map || !crossroadDetailedRef.current) return;

    const overlay = createOverlay({
      element: crossroadDetailedRef,
      autoPan: true,
      id: CROSSROAD_OVERLAY_ID,
    });

    setCrossroadOverlay(overlay);
    map.addOverlay(overlay);

    return () => {
      map?.removeOverlay(overlay);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, crossroadDetailedRef.current]);

  const mainProps = {
    crossroadOverlay,
    map,
  };

  return { mainProps, crossroadDetailedRef };
};

export default useCrossroadOverlay;
