import { ItemType } from 'antd/lib/menu/hooks/useItems';

import { VideoSourceItem } from '../../../../models/VideoCamera.model';

export const getStreamItems = (
  videoSources: VideoSourceItem[],
  activeStream: VideoSourceItem,
  labelStyle: string
): ItemType[] => {
  const { name } = activeStream;

  return videoSources.map((videoSource) => {
    const isActive = videoSource.name === name;

    return {
      key: videoSource.name,
      label: videoSource.name,
      className: isActive ? labelStyle : undefined,
      disabled: isActive,
    };
  });
};
