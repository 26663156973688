import { PlusOutlined } from '@ant-design/icons';
import { Descriptions } from 'antd';
import { FC } from 'react';

import { CardsContent, isEmptyCard } from '../../../models/DeviceAdmin.model';

import SourceButtons from './SourceButtons/SourceButtons';
import SourceStatus from './SourceStatus/SourceStatus';

import styles from './SourceContent.module.scss';

type SourceContentProps = CardsContent[number];

const SourceContent: FC<SourceContentProps> = (props) => {
  if (isEmptyCard(props)) return <PlusOutlined />;

  const { status_id, sname, dsc, id } = props;

  return (
    <div className={styles.container}>
      <span className={styles.id}>{id}</span>
      <div className={styles.title}>
        <h4>{sname}</h4>
      </div>
      <div className={styles.main}>
        <Descriptions
          layout="horizontal"
          items={[{ label: 'Описание', children: dsc || 'отсутствует' }]}
        />
      </div>
      <SourceButtons extSourceId={id} name={sname} />
      <SourceStatus status_id={status_id} />
    </div>
  );
};

export default SourceContent;
