import { useEffect } from 'react';

import api from '../../api';
import { DictDeviceKeys } from '../../api/devices/constants/constants';
import { LibraryKeys } from '../../api/libraries/enums/enums';
import { DictPredictKeys } from '../../api/services/dtStatistics/constants/constants';
import rootStore from '../../stores/rootStore/rootStore';

import fetchDictValue from './helpers/fetchDictValue';

const { Predict } = DictPredictKeys;
const { TypesList } = DictDeviceKeys;

const { fetchLibraryByKeyword } = api.services.dtStatistics.dict.dictApi;
const { fetchDeviceLibraryByKeyword } = api.devices.dict.devicesDictApi;

const useLibraries = () => {
  const { isAuth } = rootStore.userDataStore;

  useEffect(() => {
    if (!isAuth) return;

    Object.values(LibraryKeys).forEach((key) => fetchDictValue(key));

    fetchDictValue(Predict, fetchLibraryByKeyword);
    fetchDictValue(TypesList, fetchDeviceLibraryByKeyword);
  }, [isAuth]);
};

export default useLibraries;
