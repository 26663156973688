import clickedControlBtn from './events/clickedControlBtn';
import incorrectTimeChange from './events/incorrectTimeChange';
import phaseHoldChange from './events/phaseHoldChange';
import tablePaginationChange from './events/tablePaginationChange';
import tokensUpdateComplete from './events/tokensUpdateComplete';
import updateDeviceItem from './events/updateDeviceItem';
import updateFeatures from './events/updateFeatures';
import updateFormDevice from './events/updateFormDevice';
import updateSourceItem from './events/updateSourceItem';
import updateSourcesList from './events/updateSourcesList';
import userDataUpdate from './events/userDataUpdate';
import videoListUpdate from './events/videoListUpdate';

export default {
  userDataUpdate,
  tokensUpdateComplete,
  tablePaginationChange,
  videoListUpdate,
  incorrectTimeChange,
  phaseHoldChange,
  updateFeatures,
  clickedControlBtn,
  updateFormDevice,
  updateSourcesList,
  updateDeviceItem,
  updateSourceItem,
};
