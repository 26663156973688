import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';

import api from '../../../../../../api';
import {
  DEFAULT_UPDATE_DEVICE_ADMIN,
  QUERY_KEY,
} from '../../../../../../constants/queryConstants';
import eventBus from '../../../../../../eventBus';
import rootStore from '../../../../../../stores/rootStore/rootStore';
import transformSourceData from '../helpers/transformSourceData';

const { EXTERNAL_LINK_INFO } = QUERY_KEY;
const { fetchExternalLinkBySourceId } =
  api.devices.externalLinks.externalLinksApi;

const APP_PATH =
  'Admin.DeviceAdmin.SourceEdit.SourceEditForm.hooks.useSourceInfo';

const useSourceInfo = () => {
  const { selectedDevice, selectedSource } = rootStore.adminPanelStore;

  const {
    data: sourceInfo,
    isLoading,
    isError,
    refetch,
  } = useQuery({
    queryKey: [EXTERNAL_LINK_INFO, selectedDevice, selectedSource],
    queryFn: async () => {
      if (!selectedDevice || !selectedSource) return null;

      const res = await fetchExternalLinkBySourceId(
        selectedDevice.id,
        selectedSource,
        APP_PATH
      );

      return transformSourceData(res);
    },
    refetchInterval: DEFAULT_UPDATE_DEVICE_ADMIN,
    enabled: !!(selectedDevice && selectedSource),
  });

  useEffect(() => {
    refetch();
  }, [selectedDevice, refetch]);

  useEffect(() => {
    const unsubscribe = eventBus.updateSourceItem.subscribe(refetch);

    return () => {
      unsubscribe();
    };
  }, [refetch]);

  return {
    source: sourceInfo?.source,
    isLoading,
    isError,
    sourceId: sourceInfo?.sourceId,
  };
};

export default useSourceInfo;
