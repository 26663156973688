import { WEB_DEVELOPER_CODE } from '../../../ts/enums/userData';
import rootStore from '../../rootStore/rootStore';

const setDataLog = (key: string, data: unknown) => {
  const { isStaticDataLog } = rootStore.uiStore;
  const { hasAccess } = rootStore.userDataStore;

  const isAccess = hasAccess(WEB_DEVELOPER_CODE) && isStaticDataLog;

  isAccess && console.log(key, data);
};

export default setDataLog;
