import { observer } from 'mobx-react-lite';

import LoginBlock from './LoginBlock/LoginBlock';
import LoginLogo from './LoginLogo/LoginLogo';

import styles from './Login.module.scss';

const Login = () => {
  return (
    <main className={styles.container}>
      <LoginLogo />
      <LoginBlock />
    </main>
  );
};

export default observer(Login);
