import { Descriptions } from 'antd';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import { DictPredictKeys } from '../../../../../../api/services/dtStatistics/constants/constants';
import rootStore from '../../../../../../stores/rootStore/rootStore';
import { GraphTypes } from '../../../../../TrafficLightDetailed/TlDetectors/constants/constants';
import { InfoButtonProps } from '../InfoButton';

import DictDesc from './DictDesc/DictDesc';
import getMainItems from './helpers/getMainItems';
import getNormativeItems from './helpers/getNormativeItems';

import styles from './PopoverInfoContent.module.scss';

const { Predict } = DictPredictKeys;

const PopoverInfoContent: FC<InfoButtonProps> = ({
  graphInfo,
  timeUpdate,
  chartType,
}) => {
  const { graphType } = rootStore.detailedStore;
  const predictDict = rootStore.uiStore.dictionaries[Predict];

  const mainItems = getMainItems(graphInfo, timeUpdate);
  const normativeItems = getNormativeItems(chartType);

  const containerStyle = classNames(styles.container, {
    [styles.candle]: Boolean(
      graphType === GraphTypes.CandlesChart && predictDict.length
    ),
  });

  return (
    <div className={containerStyle}>
      <div className={styles.block}>
        <Descriptions
          items={mainItems}
          layout="vertical"
          title="Основная информация"
          className={styles.description}
        />
        {normativeItems?.length ? (
          <Descriptions
            items={normativeItems}
            layout="vertical"
            title="Нормативные документы"
            column={1}
            className={styles.description}
          />
        ) : null}
      </div>
      <DictDesc descStyle={styles.description} />
    </div>
  );
};

export default observer(PopoverInfoContent);
