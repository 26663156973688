import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import { TDeviceStatus } from '../../../../../../constants/devicesConstants';
import { sliceTitleBy } from '../../../../../../helpers/sliceTitle';
import rootStore from '../../../../../../stores/rootStore/rootStore';
import { System } from '../../../../../../ts/enums/enums';
import IcoDevice from '../../../../../IcoDevice/IcoDevice';
import Popover, {
  PopoverWithoutChildren,
} from '../../../../../ui-kit/Popover/Popover';

import PopoverContent from './PopoverContent/PopoverContent';

import styles from './SelectItem.module.scss';

interface SelectItemProps {
  system: System;
  id: number;
  caption: string;
  deviceStatusId: TDeviceStatus;
}

const MAX_LENGTH = 40;

const SelectItem: FC<SelectItemProps> = ({
  system,
  id,
  caption,
  deviceStatusId,
}) => {
  const { interfaceTipsDelay } = rootStore.uiStore;
  const { selectedDevice } = rootStore.adminPanelStore;

  const popoverProps: PopoverWithoutChildren = {
    content: <PopoverContent caption={caption} statusDevice={deviceStatusId} />,
    tipsDelay: interfaceTipsDelay,
    placement: 'right',
  };

  return (
    <Popover {...popoverProps}>
      <div className={styles.container}>
        <span
          className={classNames({
            [styles.id]: true,
            [styles.active]: selectedDevice?.id === id,
          })}
        >
          {id}
        </span>
        <p>{sliceTitleBy(caption, MAX_LENGTH)}</p>
        <div className={styles.status}>
          <IcoDevice
            system={system}
            iconStyle={styles.icon}
            statusDevice={deviceStatusId}
          />
        </div>
      </div>
    </Popover>
  );
};

export default observer(SelectItem);
