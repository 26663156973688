import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import { AiOutlineEdit } from 'react-icons/ai';
import { MdDeleteOutline } from 'react-icons/md';

import ButtonOnlyIcon from '../../../../../../ui-kit/ButtonOnlyIcon/ButtonOnlyIcon';

import useSourceButtons from './hooks/useSourceButtons';

import styles from './SourceButtons.module.scss';

interface SourceButtonsProps {
  extSourceId: number;
  name: N<string>;
}

const SourceButtons: FC<SourceButtonsProps> = ({ extSourceId, name }) => {
  const { handleEditSource, handleDeleteSource } = useSourceButtons(
    extSourceId,
    name
  );

  return (
    <div className={styles.buttons}>
      <ButtonOnlyIcon onClick={handleEditSource} isSmall>
        <AiOutlineEdit />
      </ButtonOnlyIcon>
      <ButtonOnlyIcon onClick={handleDeleteSource} isDanger isSmall>
        <MdDeleteOutline />
      </ButtonOnlyIcon>
    </div>
  );
};

export default observer(SourceButtons);
